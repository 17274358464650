import admin from './admin';
import bartender from './bartender';
import checkout from './checkout';
import donation from './donation';
import event from './event';
import organizer from './organizer';
import reports from './reports';
import storage from './storage';

export default {
  admin,
  bartender,
  checkout,
  donation,
  event,
  organizer,
  reports,
  storage,
};
