import type { FC } from 'react';
import { useCallback, useRef } from 'react';

import type { BoxProps } from '@mui/material';
import { Box, Button, Stack } from '@mui/material';
import QRCode from 'react-qr-code';

import CopyButton from './CopyButton';

interface Props {
  value: string;
  size: number;
  boxProps?: BoxProps;
}

const QRCodeDownload: FC<Props> = ({ value, size, boxProps }) => {
  const codeRef = useRef<QRCode & SVGElement>(null);

  const onDownload = useCallback(() => {
    if (!codeRef.current) {
      return;
    }
    const svgData = new XMLSerializer().serializeToString(codeRef.current);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      console.error('ctx blank');

      return;
    }

    const img = new Image();
    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');

      const downloadLink = document.createElement('a');
      downloadLink.download = 'qrcode';
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };

    img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
  }, []);

  return (
    <Box {...boxProps}>
      <Box display='flex' justifyContent='center'>
        <Box p={2} bgcolor='white'>
          <QRCode ref={codeRef} value={value} size={size} />
        </Box>
      </Box>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={2}
        justifyContent='center'
      >
        <Button size='small' onClick={onDownload}>
          Download QR code
        </Button>
        <CopyButton size='small' value={value} startIcon={null}>
          Copy link
        </CopyButton>
      </Stack>
    </Box>
  );
};

export default QRCodeDownload;
