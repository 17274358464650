import type { ChangeEventHandler, FC } from 'react';
import { useMemo, useState } from 'react';

import PercentIcon from '@mui/icons-material/Percent';
import type { TextFieldProps } from '@mui/material';
import { InputAdornment } from '@mui/material';

import TextField from './TextField';

type Props = Omit<TextFieldProps, 'value' | 'onChange' | 'type'> & {
  value: number | undefined;
  onChange: (value: number) => void;
  places?: number;
};

const PercentField: FC<Props> = ({ value, onChange, places = 1, ...props }) => {
  const [isEmpty, setIsEmpty] = useState(value === undefined);

  const step = useMemo(() => Number(`10e-${places + 1}`), [places]);

  const percent = useMemo(() => {
    if (value === undefined) {
      return '';
    }

    return +(value * 100).toFixed(places);
  }, [value, places]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const raw = e.target.value;
    const asPercent = Number(raw).toFixed(places);
    const decimal = +(Number(asPercent) / 100).toFixed(places + 2);
    onChange(decimal);
    setIsEmpty(raw === '');
  };

  return (
    <TextField
      type='number'
      value={isEmpty ? '' : percent}
      onChange={handleChange}
      inputProps={{
        min: 0,
        max: 100,
        step,
        ...props.inputProps,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <PercentIcon fontSize='small' />
          </InputAdornment>
        ),
        ...props.InputProps,
      }}
      {...props}
    />
  );
};

export default PercentField;
