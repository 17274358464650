import { Money } from '@gb/common';
import type { Item } from 'firebase/analytics';

const PLATFORM_ITEM_ID = 'platformRate';

export default function getDonationAnalyticsData({
  eventId,
  eventName,
  amountCents,
  platformRateCents,
}: {
  eventId: string;
  eventName: string;
  amountCents: number;
  platformRateCents: number;
}): { currency: 'USD'; value: number; items: Item[] } {
  const amountDollars = new Money(amountCents).getDollars();
  const platformDollars = new Money(platformRateCents).getDollars();

  const items: Item[] = [
    {
      item_id: eventId,
      item_name: 'Donation',
      affiliation: eventName,
      price: amountDollars,
      quantity: 1,
    },
  ];

  if (platformRateCents) {
    items.push({
      item_id: PLATFORM_ITEM_ID,
      item_name: 'Platform Rate',
      affiliation: eventName,
      price: platformDollars,
      quantity: 1,
    });
  }

  return {
    currency: 'USD',
    value: amountDollars + platformDollars,
    items,
  };
}
