import type { FC } from 'react';

import { Money } from '@gb/common';
import { ListItem, ListItemText, Typography } from '@mui/material';

interface Props {
  label: string;
  value?: string;
  cents?: number;
  isBold?: boolean;
  isNegative?: boolean;
}

const FinancialListItem: FC<Props> = ({
  label,
  value = '',
  cents,
  isBold = false,
  isNegative = false,
}) => {
  const fontWeight = isBold ? '600' : 'normal';
  const bgcolor = isBold ? 'grey.100' : 'inherit';

  const prefix = isNegative ? `($` : '$';
  const suffix = isNegative ? ')' : '';

  const formattedMoney =
    cents !== undefined ? prefix + new Money(cents).toString() + suffix : '';

  return (
    <ListItem divider sx={{ bgcolor }}>
      <ListItemText primary={label} primaryTypographyProps={{ fontWeight }} />
      <Typography variant='body1' sx={{ fontWeight }}>
        {formattedMoney}
        {value}
      </Typography>
    </ListItem>
  );
};

export default FinancialListItem;
