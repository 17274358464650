import { useEffect, useState } from 'react';

import type { Event } from '@gb/common';

import api from '../api';

interface Hook {
  event: Event | undefined;
  isLoading: boolean;
  hasError: boolean;
}

export default function useGetEvent(eventId: string | undefined): Hook {
  const [event, setEvent] = useState<Event | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (!eventId) {
      setEvent(undefined);
      setHasError(true);
      setIsLoading(false);

      return;
    }
    setIsLoading(true);
    api.event
      .get(eventId)
      .then((data) => {
        setEvent(data);
        setHasError(!data);
      })
      .catch((err) => {
        console.error(err);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [eventId]);

  return { event, isLoading, hasError };
}
