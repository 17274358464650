import type { AdminUser } from '@gb/common';
import { getRefPath } from '@gb/common';
import type {
  CollectionReference,
  DocumentReference,
} from 'firebase/firestore';
import { collection, doc } from 'firebase/firestore';

import { db } from '../firebase';

export function getAdminCollectionRef(): CollectionReference<AdminUser> {
  const ref = collection(db, getRefPath.adminsCollection());

  return ref as CollectionReference<AdminUser>;
}

export function getAdminRef(uid: string): DocumentReference<AdminUser> {
  const ref = doc(db, getRefPath.admin({ uid }));

  return ref as DocumentReference<AdminUser>;
}
