import type { Bartender } from '@gb/common';
import type { FirestoreError } from 'firebase/firestore';
import { limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';

import { getBartenderCollectionRef } from './refs';

const LIMIT = 250;

export default function subscribeList(
  eventId: string,
  onUpdate: (data: Bartender[]) => void,
  onError: (err: FirestoreError) => void = console.error,
): () => void {
  const q = query(
    getBartenderCollectionRef(),
    orderBy('createdAt'),
    where('eventId', '==', eventId),
    limit(LIMIT),
  );

  const unsubscribe = onSnapshot(
    q,
    (snap) => onUpdate(snap.docs.map((doc) => doc.data())),
    onError,
  );

  return unsubscribe;
}
