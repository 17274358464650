import { useMemo } from 'react';

import type { Event, Totals } from '@gb/common';

type Leader = {
  name: string;
  bartenderId: string;
  totalCents: number;
  place: number;
};

interface Hook {
  leaders: Leader[];
}

export default function useLeaderboard(
  event: Event | undefined,
  totals: Totals | undefined,
): Hook {
  const leaders = useMemo((): Leader[] => {
    if (!event?.bartenders) {
      return [];
    }

    const places = Object.values(totals?.bartenderTotals || {})
      .concat([0])
      .filter(onlyUnique)
      .sort((a, b) => b - a);

    return Object.entries(event.bartenders)
      .map(([bartenderId, name]) => {
        const totalCents = totals?.bartenderTotals?.[bartenderId] || 0;

        return {
          name,
          bartenderId,
          totalCents,
          place: places.indexOf(totalCents),
        };
      })
      .sort((a, b) => b.totalCents - a.totalCents);
  }, [event, totals]);

  return { leaders };
}

function onlyUnique<T>(value: T, index: number, self: T[]) {
  return self.indexOf(value) === index;
}
