import { httpsCallable } from 'firebase/functions';

import api from '..';
import { cloudFunctions } from '../firebase';

const callable = httpsCallable<{ eventId: string }, string>(
  cloudFunctions,
  'reports-getDonorReport',
);

/**
 * Get a CSV export list of donors
 * @param eventId id of the event
 * @returns url to the exported file
 * @throws if callable fails
 */
export default async function getDonorReport(eventId: string): Promise<string> {
  const result = await callable({ eventId });

  const remotePath = result.data;
  const publicUrl = await api.storage.getPublicUrl(remotePath);

  return publicUrl;
}
