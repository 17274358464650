import type { AdminFinanceItem } from '@gb/common';

import donation from '../donation';
import event from '../event';

export default async function getAdminFinanceReport(
  docLimit: number,
): Promise<AdminFinanceItem[]> {
  const items: AdminFinanceItem[] = [];

  const events = await event.listActive({ docLimit });
  for (const { eventId, name: eventName, platformRate } of events) {
    try {
      const totals = await donation.getTotals(eventId);
      const {
        totalCents = 0,
        platformCents = 0,
        donorCount = 0,
        offlineCents = 0,
      } = totals || {};
      items.push({
        eventId,
        eventName,
        platformRate,
        totalCents,
        platformCents,
        donorCount,
        offlineCents,
      });
    } catch (err) {
      console.error(err);
      continue;
    }
  }

  return items;
}
