import type { FC } from 'react';

import { Button, Card, CardContent, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

interface Props {
  donateUrl: string;
  donateListUrl: string;
  onLeaderboard: () => void;
  canDonate: boolean;
}

const DonateSidebar: FC<Props> = ({
  donateUrl,
  donateListUrl,
  onLeaderboard,
  canDonate,
}) => {
  return (
    <Card variant='outlined' sx={{ mt: 1 }}>
      <CardContent>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={1}
          justifyContent='center'
        >
          <Button
            component={Link}
            to={donateListUrl}
            variant='outlined'
            size='small'
            fullWidth
          >
            See all
          </Button>
          <Button
            onClick={onLeaderboard}
            variant='outlined'
            size='small'
            fullWidth
            color='secondary'
            sx={{ color: 'secondary.dark' }}
          >
            Leaderboard
          </Button>
        </Stack>
        {canDonate && (
          <>
            <Typography
              variant='h6'
              fontWeight='normal'
              textAlign='center'
              color='primary'
              sx={{ py: 2 }}
              gutterBottom
            >
              Make a difference. <br />
              Support this cause today!
            </Typography>

            <Button
              component={Link}
              to={donateUrl}
              variant='contained'
              size='medium'
              color='secondary'
              fullWidth
              sx={{ mb: 1 }}
            >
              Donate now
            </Button>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default DonateSidebar;
