import type { FC, FormEventHandler } from 'react';
import { useRef } from 'react';

import type { Event } from '@gb/common';
import { Money } from '@gb/common';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
} from '@mui/material';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';

import MoneyField from '../base/MoneyField';
import SelectBartender from '../base/SelectBartender';
import TextField from '../base/TextField';

const amounts = [2500, 5000, 10000, 25000];
const MIN_DONATION = 5;

interface Props {
  bartenders: Event['bartenders'] | undefined;
  eventName: string | undefined;
  initialBartenderId: string | null;
  bartenderId: string;
  setBartenderId: (v: string) => void;
  donorName: string;
  setDonorName: (v: string) => void;
  isAnonymous: boolean;
  setIsAnonymous: (v: boolean) => void;
  amountCents: number;
  setAmountCents: (v: number) => void;
  hasPlatformCost: boolean;
  setHasPlatformCost: (v: boolean) => void;
  onSubmit: () => void;
  isLoading: boolean;
}

const DonateForm: FC<Props> = ({
  bartenders,
  eventName = '',
  initialBartenderId,
  bartenderId,
  setBartenderId,
  donorName,
  setDonorName,
  isAnonymous,
  setIsAnonymous,
  amountCents,
  setAmountCents,
  hasPlatformCost,
  setHasPlatformCost,
  onSubmit,
  isLoading,
}) => {
  const formRef = useRef<HTMLFormElement>(null);

  function handleSubmit() {
    const isValid = formRef.current?.reportValidity();
    if (!isValid) {
      return;
    }
    onSubmit();
  }

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  return (
    <Box component='form' onSubmit={handleFormSubmit} ref={formRef}>
      <DialogContentText textAlign={{ xs: 'center', md: 'left' }}>
        Your donation supports {eventName}
      </DialogContentText>
      <FormGroup sx={{ mb: 3 }}>
        <SelectBartender
          bartenders={bartenders}
          bartenderId={bartenderId}
          setBartenderId={setBartenderId}
          label='Donate for'
          helperText='Donate on behalf of'
          isLocked={!!initialBartenderId}
        />
        <TextField
          name='donorName'
          label='Your name'
          value={donorName}
          onChangeText={setDonorName}
          required={!isAnonymous}
        />
        <FormControl margin='dense'>
          <FormControlLabel
            control={<Switch />}
            label='Make my donation anonymous'
            value={isAnonymous}
            onChange={(_e, isChecked) => setIsAnonymous(isChecked)}
          />
        </FormControl>
      </FormGroup>
      <FormGroup sx={{ mb: 3 }}>
        <DialogContentText>How much would you like to give?</DialogContentText>
        <FormControl margin='normal'>
          <Box sx={{ mx: -1, mb: 1 }}>
            {amounts.map((value) => (
              <Button
                key={`button-${value}`}
                onClick={() => setAmountCents(value)}
                variant={amountCents === value ? 'contained' : 'outlined'}
                sx={{ mx: 1, aspectRatio: '1' }}
              >
                ${new Money(value).toString()}
              </Button>
            ))}
          </Box>
        </FormControl>
        <MoneyField
          name='customAmount'
          label='Amount'
          sx={{ alignSelf: 'start' }}
          value={amountCents}
          onChange={setAmountCents}
          required
          inputProps={{
            min: MIN_DONATION,
            step: 0.01,
          }}
        />
        <FormControl fullWidth margin='dense'>
          <FormControlLabel
            control={<Switch defaultChecked />}
            label={`Yes! I'd like to cover the platform costs`}
            value={hasPlatformCost}
            onChange={(_e, isChecked) => setHasPlatformCost(isChecked)}
          />
        </FormControl>
      </FormGroup>

      <Button
        type='submit'
        variant='contained'
        color='secondary'
        fullWidth
        sx={{ mb: 3 }}
        disabled={isLoading}
      >
        {isLoading ? 'Loading...' : 'Continue'}
      </Button>
    </Box>
  );
};

export default DonateForm;
