import type { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { CreateEventSection } from '.';
import { useCreateEventContext } from '../../context/CreateEventContext';
import CauseForm from '../../forms/CauseForm';
import getPath from '../../utils/getPath';

const CreateCause: FC = () => {
  const { event, set } = useCreateEventContext();

  const navigate = useNavigate();

  function onSubmit() {
    navigate(getPath.eventCreate({ page: 'general' }));
  }

  function onBack() {
    navigate(getPath.eventCreate({ page: 'details' }));
  }

  return (
    <CreateEventSection title='About the Cause'>
      <CauseForm
        name={event.name}
        setName={(v: string) => set('name', v)}
        tagline={event.tagline}
        setTagline={(v: string) => set('tagline', v)}
        description={event.description}
        setDescription={(v: string) => set('description', v)}
        causeName={event.causeName}
        setCauseName={(v: string) => set('causeName', v)}
        causeUrl={event.causeUrl || ''}
        setCauseUrl={(v: string) => set('causeUrl', v)}
        causeTaxId={event.causeTaxId || ''}
        setCauseTaxId={(v: string) => set('causeTaxId', v)}
        onSubmit={onSubmit}
        submitLabel='Next'
        onCancel={onBack}
        cancelLabel='Back'
      />
    </CreateEventSection>
  );
};

export default CreateCause;
