import { Timestamp } from 'firebase/firestore';

/**
 * Get a Timestamp n days in the future
 * @param date initial date, defaults to now
 * @param days how many days in the future
 * @returns Timestamp
 */
export function getFutureDate(date = new Date(), days = 30): Timestamp {
  date.setDate(date.getDate() + days);

  return Timestamp.fromDate(date);
}

/**
 * Get a Timestamp n days in the past
 * @param date initial date, defaults to now
 * @param days how many days in the past
 * @returns Timestamp
 */
export function getPastDate(date = new Date(), days = 7): Timestamp {
  return getFutureDate(date, -days);
}
