import type { FC, ReactNode } from 'react';

import type { SvgIconComponent } from '@mui/icons-material';
import type { ListItemProps, SvgIconProps } from '@mui/material';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import type { To } from 'react-router-dom';
import { Link } from 'react-router-dom';

import useIsActivePath from '../hooks/useIsActivePath';

interface Props {
  label: string;
  Icon?: SvgIconComponent;
  iconFontSize?: SvgIconProps['fontSize'];
  onClick?: () => void;
  to?: To;
  listItemProps?: ListItemProps;
  children?: ReactNode;
}

const DrawerItem: FC<Props> = ({
  label,
  Icon,
  iconFontSize = 'small',
  to,
  onClick,
  listItemProps = {},
  children,
}) => {
  const isActive = useIsActivePath(to);

  return (
    <ListItem disablePadding {...listItemProps}>
      <ListItemButton
        component={to ? Link : 'button'}
        to={to}
        onClick={onClick}
        sx={{ bgcolor: isActive ? 'action.disabled' : 'none' }}
      >
        {!!Icon && (
          <ListItemIcon sx={{ minWidth: 40, color: 'inherit' }}>
            <Icon fontSize={iconFontSize} />
          </ListItemIcon>
        )}
        <ListItemText primary={label} />
        {children}
      </ListItemButton>
    </ListItem>
  );
};

export default DrawerItem;
