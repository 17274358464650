import type { FC } from 'react';

import type { Donation } from '@gb/common';
import { Money } from '@gb/common';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import TimeAgo from 'react-timeago';

interface Props {
  donation: Donation;
}

const DonationListItem: FC<Props> = ({ donation }) => {
  const { donorName, isAnonymous, bartenderName, amountCents, createdAt } =
    donation;

  const toName = bartenderName || 'Everyone';
  const fromName = donorName && !isAnonymous ? donorName : 'Anonymous';
  const formattedAmount = '$' + new Money(amountCents).toString();

  return (
    <ListItem disableGutters divider>
      <ListItemIcon>
        <VolunteerActivismIcon />
      </ListItemIcon>
      <ListItemText
        primary={fromName}
        secondary={
          <span>
            <TimeAgo date={createdAt.toDate()} formatter={formatTimeAgo} />{' '}
            {'\u2022'} {toName}
          </span>
        }
      />
      <Typography
        variant='body1'
        sx={{ pl: 1, color: 'secondary.main', fontWeight: 'bold' }}
      >
        {formattedAmount}
      </Typography>
    </ListItem>
  );
};

export default DonationListItem;

type TimeAgoUnit =
  | 'second'
  | 'minute'
  | 'hour'
  | 'day'
  | 'week'
  | 'month'
  | 'year';

function formatTimeAgo(value: string, unit: TimeAgoUnit): string {
  return `${value}${unit[0] || ''}`;
}
