import type { ChangeEventHandler, FC } from 'react';
import { useMemo, useState } from 'react';

import { Money } from '@gb/common';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import type { TextFieldProps } from '@mui/material';
import { InputAdornment } from '@mui/material';

import TextField from './TextField';

type Props = Omit<TextFieldProps, 'value' | 'onChange' | 'type'> & {
  value: number | undefined;
  onChange: (value: number) => void;
};

const MoneyField: FC<Props> = ({
  value,
  onChange,
  inputProps,
  InputProps,
  ...props
}) => {
  const [isEmpty, setIsEmpty] = useState(value === undefined);

  const dollars = useMemo(() => {
    if (value === undefined) {
      return '';
    }

    return new Money(value).getDollars();
  }, [value]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const raw = e.target.value;
    const cents = Money.fromString(raw).getCents();
    onChange(cents);
    setIsEmpty(raw === '');
  };

  return (
    <TextField
      type='number'
      value={isEmpty ? '' : dollars}
      onChange={handleChange}
      inputProps={{
        min: 0,
        step: 0.01,
        ...inputProps,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <AttachMoneyIcon fontSize='small' />
          </InputAdornment>
        ),
        ...InputProps,
      }}
      {...props}
    />
  );
};

export default MoneyField;
