import type { Donation } from '@gb/common';
import { getRefPath } from '@gb/common';
import type { DatabaseReference } from 'firebase/database';
import { child, ref } from 'firebase/database';
import type { CollectionReference } from 'firebase/firestore';
import { collection } from 'firebase/firestore';

import { db, rtdb } from '../firebase';

export function getDonationCollectionRef(): CollectionReference<Donation> {
  const collectionRef = collection(db, getRefPath.donationsCollection());

  return collectionRef as CollectionReference<Donation>;
}

export function getTotalsRef(eventId: string): DatabaseReference {
  return ref(rtdb, getRefPath.totals({ eventId }));
}

export function getOfflineTotalRef(eventId: string): DatabaseReference {
  return child(getTotalsRef(eventId), 'offlineCents');
}
