import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useState } from 'react';

import api from '../api';

interface Hook {
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  createOrganizer: (onSuccess?: (organizerId: string) => void) => void;
  isLoading: boolean;
}

export default function useCreateOrganizer(eventId: string): Hook {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const createOrganizer = useCallback(
    (onSuccess?: (organizerId: string) => void) => {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      api.organizer
        .create({ eventId, name: name.trim(), email })
        .then(onSuccess)
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    },
    [isLoading, eventId, name, email],
  );

  return { name, setName, email, setEmail, createOrganizer, isLoading };
}
