import type { FC } from 'react';
import { memo } from 'react';

import type { Event } from '@gb/common';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

import FlatList from '../../base/FlatList';
import PageTitle from '../../base/PageTitle';
import MainAppBar from '../../components/common/MainAppBar';
import ManageFooter from '../../components/manage/ManageFooter';
import { useAuthContext } from '../../context/AuthContext';
import useListMyEvents from '../../hooks/useListMyEvents';
import getPath from '../../utils/getPath';

interface Props {
  isPast?: boolean;
}

const ListMyEvents: FC<Props> = ({ isPast = false }) => {
  const { events, isLoading, loadMore } = useListMyEvents({ isPast });
  const { isAdmin } = useAuthContext();
  const title = !isPast ? 'My Events' : 'My Past Events';

  return (
    <Box display='flex' flexDirection='column' minHeight='100vh'>
      <MainAppBar hasDrawer />
      <PageTitle
        title={title}
        right={
          !!isAdmin && (
            <Button
              component={Link}
              to={getPath.eventCreate()}
              variant='contained'
              color='secondary'
            >
              Create Event
            </Button>
          )
        }
      />
      <Container maxWidth='sm' sx={{ mb: 3 }}>
        <Alert severity='info'>
          <AlertTitle>Welcome to the Guest Bartender Program</AlertTitle>
          Events you can manage will appear below.
        </Alert>
        <Container
          maxWidth='lg'
          sx={{
            py: 3,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Button
            component={Link}
            to={getPath.manageList({ isPast: !isPast })}
            variant='outlined'
          >
            {isPast ? 'View upcoming events' : 'View past events'}
          </Button>
        </Container>
        <FlatList
          data={events}
          Item={ItemMemo}
          extractKey={extractKey}
          onEndReached={loadMore}
          ListEmptyComponent={
            !isLoading ? (
              <Typography variant='subtitle1' textAlign='center'>
                There are no events to manage
              </Typography>
            ) : null
          }
        />
      </Container>
      {isLoading && (
        <Box display='flex' justifyContent='center' mb={3}>
          <CircularProgress variant='indeterminate' />
        </Box>
      )}
      <ManageFooter />
    </Box>
  );
};

export default ListMyEvents;

const Item: FC<{ item: Event }> = ({ item: event }) => {
  const { eventId, name, startsAt, isActive } = event;
  const date = startsAt.toDate().toLocaleString(undefined, {
    dateStyle: 'long',
    timeStyle: 'short',
  });

  const eventUrl = getPath.manage({ eventId });

  return (
    <ListItem
      divider
      disablePadding
      secondaryAction={
        <IconButton component={Link} to={eventUrl}>
          <ChevronRightIcon />
        </IconButton>
      }
    >
      <ListItemButton component={Link} to={eventUrl}>
        {!isActive && (
          <ListItemIcon>
            <VisibilityOffIcon />
          </ListItemIcon>
        )}
        <ListItemText primary={name} secondary={date} />
      </ListItemButton>
    </ListItem>
  );
};

const ItemMemo = memo(
  Item,
  (prev, next) => prev.item.eventId === next.item.eventId,
);

const extractKey = (item: Event) => `event-${item.eventId}`;
