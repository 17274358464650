import type { FC } from 'react';

import CelebrationIcon from '@mui/icons-material/Celebration';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';

const phrases = [
  {
    Icon: VolunteerActivismIcon,
    text: `Events support local and national charities, teams, schools, animal shelters and more.`,
  },
  {
    Icon: EmojiPeopleIcon,
    text: `Community members volunteer behind the bar as guest bartenders at
    the event.`,
  },
  {
    Icon: CelebrationIcon,
    text: `It is a fun and easy way to raise money while celebrating with your friends.`,
  },
];

const EventPhrases: FC = () => {
  return (
    <Box sx={{ py: 3, bgcolor: 'grey.50' }}>
      <Container maxWidth='lg'>
        <Grid
          container
          spacing={2}
          justifyContent='center'
          sx={{ breakInside: 'avoid', pageBreakInside: 'avoid' }}
        >
          {phrases.map(({ Icon, text }, i) => (
            <Grid key={`phrase-${i}`} item xs={12} sm={10} md={4}>
              <Stack spacing={1} direction='row'>
                <Icon fontSize='large' color='primary' sx={{ pt: 1 }} />
                <Typography variant='subtitle1'>{text}</Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default EventPhrases;
