import type { FC } from 'react';

import type { Event, Totals } from '@gb/common';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';

import useIsDesktop from '../../hooks/useIsDesktop';
import Leaderboard from '../common/Leaderboard';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  event: Event | undefined;
  totals: Totals | undefined;
}

const LeaderboardDialog: FC<Props> = ({ isOpen, onClose, event, totals }) => {
  const isMobile = !useIsDesktop();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth='sm'
      fullWidth
      fullScreen={isMobile}
    >
      <DialogTitle>
        Fundraising Leaderboard
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Leaderboard event={event} totals={totals} />
      </DialogContent>
    </Dialog>
  );
};

export default LeaderboardDialog;
