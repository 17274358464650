import type { FC } from 'react';

import type { AdminUser } from '@gb/common';
import { Dialog } from '@mui/material';

import PersonForm from '../../forms/PersonForm';
import useRemoveAdmin from '../../hooks/admin/useRemoveAdmin';
import useIsDesktop from '../../hooks/useIsDesktop';

interface Props {
  activeAdmin: AdminUser | undefined;
  isOpen: boolean;
  onClose: () => void;
}

const UpdateAdminDialog: FC<Props> = ({ activeAdmin, isOpen, onClose }) => {
  const isMobile = !useIsDesktop();

  const { isLoading, removeAdmin } = useRemoveAdmin(activeAdmin?.uid);

  const onRemove = () => removeAdmin(onClose);

  if (!activeAdmin) {
    return null;
  }
  const { name, email } = activeAdmin;

  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen={isMobile}>
      <PersonForm
        title='Site admin'
        name={name}
        setName={() => undefined}
        email={email}
        setEmail={() => undefined}
        onSubmit={onClose}
        submitLabel='Go back'
        onCancel={undefined}
        removeLabel='Remove site admin'
        onRemove={onRemove}
        isLoading={isLoading}
        isReadOnly
      />
    </Dialog>
  );
};

export default UpdateAdminDialog;
