import type { RefObject } from 'react';
import { useCallback, useEffect, useState } from 'react';

interface Dimensions {
  width: number;
  height: number;
  scaledWidth: number;
  scaledHeight: number;
}

export default function useDimensions(ref: RefObject<HTMLElement>): Dimensions {
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: ref.current?.clientWidth || 0,
    height: ref.current?.clientHeight || 0,
    scaledWidth: ref.current
      ? ref.current.clientWidth * window.devicePixelRatio
      : 0,
    scaledHeight: ref.current
      ? ref.current.clientHeight * window.devicePixelRatio
      : 0,
  });

  const onResize = useCallback(() => {
    if (!ref.current) {
      setDimensions({ width: 0, height: 0, scaledWidth: 0, scaledHeight: 0 });

      return;
    }

    setDimensions({
      width: ref.current.clientWidth,
      height: ref.current.clientHeight,
      scaledWidth: ref.current.clientWidth * window.devicePixelRatio,
      scaledHeight: ref.current.clientHeight * window.devicePixelRatio,
    });
  }, [ref]);

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);

  return dimensions;
}
