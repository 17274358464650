import type { FC } from 'react';

import { Container, Skeleton, Typography } from '@mui/material';

import useIsDesktop from '../../hooks/useIsDesktop';

interface Props {
  name: string;
  tagline: string | undefined;
  isLoading?: boolean;
}

const EventTitle: FC<Props> = ({ name, tagline, isLoading = false }) => {
  const isDesktop = useIsDesktop();

  return (
    <Container maxWidth='lg' sx={{ py: 4 }}>
      <Typography
        variant={isDesktop ? 'h5' : 'h6'}
        color='primary'
        align='center'
        fontWeight='500'
      >
        {!isLoading ? name : <Skeleton />}
      </Typography>
      <Typography
        variant={isDesktop ? 'h6' : 'body1'}
        align='center'
        fontWeight='normal'
      >
        {!isLoading ? tagline : <Skeleton />}
      </Typography>
    </Container>
  );
};

export default EventTitle;
