import type { Event } from '@gb/common';
import {
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore';

import { getPastDate } from '../utils/getDate';
import { getEventCollectionRef } from './refs';

const LIMIT = 20;

export default async function list({
  docLimit = LIMIT,
  startAfterDoc,
  organizerId,
  isActive,
  isPast = false,
}: {
  /** how many events to list in each batch */
  docLimit?: number;
  /** pagination cursor */
  startAfterDoc?: Event | undefined;
  /** optionally filter by organizer */
  organizerId?: string | undefined;
  /** return only active events if true, all events if false */
  isActive: boolean;
  /** return past events if true, future events if false */
  isPast?: boolean;
}): Promise<Event[]> {
  const endsAt = getPastDate();

  let q = query(
    getEventCollectionRef(),
    orderBy('endsAt', isPast ? 'desc' : 'asc'),
    where('endsAt', isPast ? '<' : '>=', endsAt),
    limit(docLimit),
  );

  if (isActive) {
    q = query(q, where('isActive', '==', true));
  }

  if (organizerId) {
    q = query(q, where('organizerIds', 'array-contains', organizerId));
  }

  if (startAfterDoc) {
    q = query(q, startAfter(startAfterDoc.endsAt));
  }

  const snap = await getDocs(q);

  return snap.docs.map((doc) => doc.data());
}
