import type { FC } from 'react';

import type { FormControlProps } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

interface Props {
  value: string;
  onChange: (v: string) => void;
  label?: string;
  id?: string;
  labelId?: string;
  formControlProps?: FormControlProps;
}

const SelectState: FC<Props> = ({
  value,
  onChange,
  label = 'State',
  id = 'state-select',
  labelId = `${id}-label`,
  formControlProps,
}) => {
  return (
    <FormControl fullWidth margin='normal' {...formControlProps}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        id={id}
        labelId={labelId}
        label={label}
        displayEmpty
        variant='outlined'
        inputProps={{ 'aria-label': label }}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        {stateList.map((state) => (
          <MenuItem key={`state-${state}`} value={state}>
            {state}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectState;

const stateList = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];
