import type { FC } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import type { To } from 'react-router-dom';
import { Link } from 'react-router-dom';

import MainAppBar from '../components/common/MainAppBar';
import getPath from '../utils/getPath';

interface Props {
  to?: To;
  title?: string;
  message?: string;
  buttonLabel?: string;
}

const NotFound: FC<Props> = ({
  to = getPath.home(),
  title = 'Something went wrong',
  message = 'Please check your connection and try again, or browse fundraising events',
  buttonLabel = 'Browse events',
}) => {
  return (
    <Box>
      <MainAppBar hasDrawer />
      <Dialog open={true} maxWidth='xs'>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Typography variant='body1' paragraph>
            {message}
          </Typography>
          <Button
            component={Link}
            to={to}
            variant='contained'
            color='secondary'
            fullWidth
          >
            {buttonLabel}
          </Button>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default NotFound;
