import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';

import type { Event } from '@gb/common';

import api from '../api';

interface Hook {
  isActive: boolean;
  setIsActive: Dispatch<SetStateAction<boolean>>;
  canDonate: boolean;
  setCanDonate: Dispatch<SetStateAction<boolean>>;
  goalCents: number | undefined;
  setGoalCents: Dispatch<SetStateAction<number | undefined>>;
  platformRate: number;
  setPlatformRate: Dispatch<SetStateAction<number>>;
  onSubmit: (onSuccess?: () => void) => void;
  isLoading: boolean;
}

export default function useUpdateGeneral(event: Event | undefined): Hook {
  const [isActive, setIsActive] = useState(!!event?.isActive);
  const [canDonate, setCanDonate] = useState(!!event?.canDonate);
  const [goalCents, setGoalCents] = useState(event?.goalCents);
  const [platformRate, setPlatformRate] = useState(event?.platformRate || 0);
  const [isLoading, setIsLoading] = useState(false);

  const onReset = useCallback(() => {
    setIsActive(!!event?.isActive);
    setCanDonate(!!event?.canDonate);
    setGoalCents(event?.goalCents);
    setPlatformRate(event?.platformRate || 0);
  }, [event]);

  useEffect(() => {
    onReset();
  }, [onReset]);

  const onSubmit = useCallback(
    (onSuccess?: () => void) => {
      if (isLoading || !event) {
        return;
      }
      setIsLoading(true);
      api.event
        .update(event.eventId, {
          isActive,
          canDonate,
          goalCents: goalCents || 0,
          platformRate: platformRate || 0,
        })
        .then(onSuccess)
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    },
    [isLoading, event, isActive, canDonate, goalCents, platformRate],
  );

  return {
    isActive,
    setIsActive,
    canDonate,
    setCanDonate,
    goalCents,
    setGoalCents,
    platformRate,
    setPlatformRate,
    onSubmit,
    isLoading,
  };
}
