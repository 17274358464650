import type { FC } from 'react';

import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';

import MetaTags from '../base/MetaTags';
import Footer from '../components/common/Footer';
import MainAppBar from '../components/common/MainAppBar';
import Sponsors from '../components/common/Sponsors';
import EventHeader from '../components/event/EventHeader';
import EventPhrases from '../components/event/EventPhrases';
import EventTabs from '../components/event/EventTabs';
import TabAbout from '../components/event/TabAbout';
import TabBartenders from '../components/event/TabBartenders';
import TabDetails from '../components/event/TabDetails';
import TabDonations from '../components/event/TabDonations';
import useCanManageEvent from '../hooks/useCanManageEvent';
import useGetEvent from '../hooks/useGetEvent';
import useSubscribeTotals from '../hooks/useSubscribeTotals';
import { getCdnSrc } from '../utils/getCdnSrc';
import getPath from '../utils/getPath';
import NotFound from './NotFound';

const ViewEvent: FC = () => {
  const { eventId = '' } = useParams();
  const { event, isLoading, hasError } = useGetEvent(eventId);
  const canManage = useCanManageEvent(event);
  const { totals } = useSubscribeTotals(eventId);

  const donateUrl = getPath.donate({ eventId });

  if (hasError) {
    return <NotFound />;
  }

  return (
    <Box>
      <MetaTags
        title={event?.name}
        description={event?.tagline}
        image={event?.cover ? getCdnSrc({ src: event.cover }) : undefined}
      />
      <MainAppBar backTo={getPath.eventList()} />
      <EventHeader
        event={event}
        totals={totals}
        donateUrl={donateUrl}
        canManage={canManage}
        isLoading={isLoading}
      />
      <EventTabs event={event} donateUrl={donateUrl} showBartenders>
        <TabAbout event={event} />
        <TabDetails event={event} />
        <TabBartenders event={event} />
        <TabDonations event={event} totals={totals} donateUrl={donateUrl} />
      </EventTabs>
      <Sponsors />
      <EventPhrases />
      <Footer />
    </Box>
  );
};

export default ViewEvent;
