import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
} from 'react-router-dom';

import App from '../App';
import getPath from '../utils/getPath';
import AdminLayout from './admin';
import AdminAccountsPayable from './admin/AdminAccountsPayable';
import AdminUsers from './admin/AdminUsers';
import AdminRoute from './AdminRoute';
import CreateEvent from './createEvent';
import CreateCause from './createEvent/CreateCause';
import CreateDetails from './createEvent/CreateDetails';
import CreateGeneral from './createEvent/CreateGeneral';
import Donate from './Donate';
import Home from './Home';
import ListDonations from './ListDonations';
import ListEvents from './ListEvents';
import ManageEvent from './manage';
import ListMyEvents from './manage/ListMyEvents';
import ManageCause from './manage/ManageCause';
import ManageDetails from './manage/ManageDetails';
import ManageDonations from './manage/ManageDonations';
import ManageDonorReports from './manage/ManageDonorReports';
import ManageFinancialReports from './manage/ManageFinancialReports';
import ManageGeneral from './manage/ManageGeneral';
import ManageImages from './manage/ManageImages';
import ManageLeaderboard from './manage/ManageLeaderboard';
import ManageMessages from './manage/ManageMessages';
import ManagePeople from './manage/ManagePeople';
import ManageRemove from './manage/ManageRemove';
import ManageShare from './manage/ManageShare';
import NotFound from './NotFound';
import ProtectedRoute from './ProtectedRoute';
import SignIn from './SignIn';
import SignInComplete from './SignInComplete';
import ThankYou from './ThankYou';
import ViewBartender from './ViewBartender';
import ViewEvent from './ViewEvent';

export default createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />} errorElement={<NotFound />}>
      <Route index element={<Home />} />
      <Route path='events'>
        <Route index element={<ListEvents />} />
        <Route path='past' element={<ListEvents isPast />} />
        <Route
          path='create/*'
          element={
            <ProtectedRoute>
              <AdminRoute>
                <CreateEvent />
              </AdminRoute>
            </ProtectedRoute>
          }
        >
          <Route path='details' element={<CreateDetails />} />
          <Route path='cause' element={<CreateCause />} />
          <Route path='general' element={<CreateGeneral />} />
          <Route path='*' element={<Navigate to='details' replace />} />
        </Route>
        <Route path=':eventId'>
          <Route index element={<ViewEvent />} />
          <Route path='donations' element={<ListDonations />} />
        </Route>
      </Route>
      <Route path='give'>
        <Route index element={<Navigate to={getPath.eventList()} replace />} />
        <Route path=':bartenderId' element={<ViewBartender />} />
      </Route>
      <Route path='donate'>
        <Route index element={<Navigate to={getPath.eventList()} replace />} />
        <Route path=':eventId'>
          <Route index element={<Donate />} />
          <Route path='thankYou/:sessionId' element={<ThankYou />} />
        </Route>
      </Route>
      <Route
        path='manage'
        element={
          <ProtectedRoute>
            <Outlet />
          </ProtectedRoute>
        }
      >
        <Route index element={<ListMyEvents />} />
        <Route path='past' element={<ListMyEvents isPast />} />
        <Route path=':eventId/*' element={<ManageEvent />}>
          <Route path='donations' element={<ManageDonations />} />
          <Route path='leaderboard' element={<ManageLeaderboard />} />
          <Route
            path='financial-reports'
            element={<ManageFinancialReports />}
          />
          <Route path='donor-reports' element={<ManageDonorReports />} />
          <Route path='share' element={<ManageShare />} />
          <Route path='messages' element={<ManageMessages />} />
          <Route path='people' element={<ManagePeople />} />
          <Route path='cause' element={<ManageCause />} />
          <Route path='details' element={<ManageDetails />} />
          <Route path='images' element={<ManageImages />} />
          <Route path='general' element={<ManageGeneral />} />
          <Route path='remove' element={<ManageRemove />} />
          <Route path='*' element={<Navigate to='donations' replace />} />
        </Route>
      </Route>
      <Route
        path='admin/*'
        element={
          <ProtectedRoute>
            <AdminRoute>
              <AdminLayout />
            </AdminRoute>
          </ProtectedRoute>
        }
      >
        <Route path='users' element={<AdminUsers />} />
        <Route path='accounts-payable' element={<AdminAccountsPayable />} />
        <Route path='*' index element={<Navigate to='users' replace />} />
      </Route>
      <Route path='signin'>
        <Route index element={<SignIn />} />
        <Route path='complete' element={<SignInComplete />} />
      </Route>
      <Route
        path='support'
        element={<Navigate to={getPath.marketing('support')} />}
      />
      <Route
        path='legal'
        element={<Navigate to={getPath.marketing('legal')} />}
      />
      <Route
        path='privacy'
        element={<Navigate to={getPath.marketing('legal')} />}
      />
      <Route
        path='contact'
        element={<Navigate to={getPath.marketing('contact')} />}
      />
      <Route path='*' element={<Navigate to='/' replace />} />
    </Route>,
  ),
);
