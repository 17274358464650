import type { Bartender } from '@gb/common';
import { getRefPath } from '@gb/common';
import type {
  CollectionReference,
  DocumentReference,
} from 'firebase/firestore';
import { collection, doc } from 'firebase/firestore';

import { db } from '../firebase';

export function getBartenderCollectionRef(): CollectionReference<Bartender> {
  const ref = collection(db, getRefPath.bartendersCollection());

  return ref as CollectionReference<Bartender>;
}

export function getBartenderRef(
  bartenderId: string,
): DocumentReference<Bartender> {
  return doc(
    db,
    getRefPath.bartender({ bartenderId }),
  ) as DocumentReference<Bartender>;
}
