import { useCallback, useState } from 'react';

import type { Bartender, Organizer, SendWelcomeData } from '@gb/common';
import { getIsOrganizer } from '@gb/common';
import { httpsCallable } from 'firebase/functions';

import { cloudFunctions } from '../api/firebase';

interface Hook {
  onSend: (onSuccess?: () => void) => void;
  isLoading: boolean;
}

const callable = httpsCallable(cloudFunctions, 'people-sendWelcome');

export default function useResendWelcome(person: Bartender | Organizer): Hook {
  const [isLoading, setIsLoading] = useState(false);

  const onSend = useCallback(
    (onSuccess?: () => void) => {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      const isOrganizer = getIsOrganizer(person);
      const data: SendWelcomeData = {
        personId: isOrganizer ? person.organizerId : person.bartenderId,
        name: person.name,
        email: person.email,
        eventId: person.eventId,
        isOrganizer,
      };

      callable(data)
        .then(onSuccess)
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    },
    [isLoading, person],
  );

  return { onSend, isLoading };
}
