import { useEffect, useMemo, useState } from 'react';

import type { Event } from '@gb/common';

import api from '../api';

interface Hook {
  event: Event | undefined;
  bartenderName: string | undefined;
  isLoading: boolean;
  hasError: boolean;
}

export default function useGetBartenderEvent(
  bartenderId: string | undefined,
): Hook {
  const [event, setEvent] = useState<Event | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (!bartenderId) {
      setEvent(undefined);
      setHasError(true);
      setIsLoading(false);

      return;
    }
    setIsLoading(true);
    api.event
      .findByBartenderId(bartenderId)
      .then((data) => {
        setEvent(data);
        setHasError(!data);
      })
      .catch((err) => {
        console.error(err);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [bartenderId]);

  const bartenderName = useMemo(() => {
    if (!bartenderId) {
      return;
    }

    return event?.bartenders?.[bartenderId];
  }, [bartenderId, event]);

  return { event, bartenderName, isLoading, hasError };
}
