import type { FC, ReactNode } from 'react';

import { Alert, Box, List, ListItem, Typography } from '@mui/material';

import { useManageContext } from '.';
import FinancialHeader from '../../components/manage/FinancialHeader';
import FinancialListItem from '../../components/manage/FinancialListItem';
import ManageSection from '../../components/manage/ManageSection';
import { useAuthContext } from '../../context/AuthContext';
import useFinancials from '../../hooks/useFinancials';

const sectionTitle = 'Financial Reports';
const ManageFinancialReports: FC = () => {
  const { event, totals } = useManageContext();

  const { isAdmin } = useAuthContext();

  const { platformRate = 0 } = event || {};

  const {
    totalCents = 0,
    platformCents = 0,
    offlineCents = 0,
    donorCount = 0,
  } = totals || {};

  const {
    platformFeeCents,
    netPlatformFeeCents,
    netCents,
    averageCents,
    bartenderCount,
    bartenderAverageCents,
    grandTotalCents,
  } = useFinancials(event, totals);

  if (!event || !totals) {
    return (
      <ManageSection title={sectionTitle}>
        <Alert severity='info'>
          Fundraising totals will be available after the event receives its
          first donations.
        </Alert>
      </ManageSection>
    );
  }

  return (
    <ManageSection title={sectionTitle}>
      <FinancialHeader event={event} />
      <FinancialSection title='Totals'>
        <FinancialListItem label='Donations' cents={totalCents} />
        <FinancialListItem
          label='Net platform cost'
          cents={netPlatformFeeCents}
          isNegative
        />
        <FinancialListItem label='Net total' cents={netCents} />
        <FinancialListItem label='Offline donations' cents={offlineCents} />
        <FinancialListItem
          label='Net grand total'
          cents={grandTotalCents}
          isBold
        />
      </FinancialSection>
      {!!isAdmin && (
        <FinancialSection title='Platform costs'>
          <FinancialListItem
            label={`Platform cost: ${(platformRate * 100).toFixed(2)}%`}
            cents={platformFeeCents}
            isNegative
          />
          <FinancialListItem
            label='Amount covered by donors'
            cents={platformCents}
          />
          <FinancialListItem
            label='Net platform cost'
            cents={netPlatformFeeCents}
            isBold
            isNegative
          />
        </FinancialSection>
      )}
      <FinancialSection title='Analytics'>
        <FinancialListItem
          label='Number of donations'
          value={donorCount.toString()}
        />
        <FinancialListItem label='Average donation' cents={averageCents} />
        <FinancialListItem
          label='Number of bartenders'
          value={bartenderCount.toString()}
        />
        <FinancialListItem
          label='Average raised per bartender'
          cents={bartenderAverageCents}
        />
      </FinancialSection>
    </ManageSection>
  );
};

export default ManageFinancialReports;

const FinancialSection: FC<{ title: string; children: ReactNode }> = ({
  title,
  children,
}) => {
  return (
    <Box mb={6}>
      <Typography variant='button' fontWeight='bold' color='GrayText'>
        {title}
      </Typography>
      <List disablePadding>
        <ListItem divider />
        {children}
      </List>
    </Box>
  );
};
