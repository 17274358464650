import type { FC } from 'react';
import { useState } from 'react';

import { Navigate, useNavigate } from 'react-router-dom';

import { useManageContext } from '.';
import LoadingIndicator from '../../base/LoadingIndicator';
import SnackAlert from '../../base/SnackAlert';
import ManageSection from '../../components/manage/ManageSection';
import { useAuthContext } from '../../context/AuthContext';
import GeneralForm from '../../forms/GeneralForm';
import OfflineCentsDialog from '../../forms/OfflineCentsDialog';
import useUpdateGeneral from '../../hooks/useUpdateGeneral';
import getPath from '../../utils/getPath';

const ManageGeneral: FC = () => {
  const { isAdmin } = useAuthContext();
  const { event, totals } = useManageContext();
  const { onSubmit, ...props } = useUpdateGeneral(event);
  const navigate = useNavigate();

  const [isOfflineOpen, setIsOfflineOpen] = useState(false);
  const openOfflineDialog = () => setIsOfflineOpen(true);
  const closeOfflineDialog = () => setIsOfflineOpen(false);

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const openSnackbar = () => setIsSnackbarOpen(true);
  const closeSnackbar = () => setIsSnackbarOpen(false);

  function handleSubmit() {
    onSubmit(openSnackbar);
  }

  function goToRemove() {
    if (!event) {
      return;
    }
    navigate(getPath.manage({ eventId: event.eventId, page: 'remove' }));
  }

  if (isAdmin === undefined) {
    return <LoadingIndicator />;
  }

  if (!isAdmin) {
    if (!event) {
      return <Navigate to={getPath.manageList()} />;
    }

    return <Navigate to={getPath.manage({ eventId: event.eventId })} />;
  }

  return (
    <ManageSection title='General Settings'>
      <GeneralForm
        {...props}
        offlineCents={totals?.offlineCents || 0}
        onClickOfflineCents={openOfflineDialog}
        onSubmit={handleSubmit}
        submitLabel='Update event'
        onRemove={goToRemove}
      />
      {isOfflineOpen && (
        <OfflineCentsDialog
          event={event}
          totals={totals}
          isOpen={isOfflineOpen}
          onClose={closeOfflineDialog}
          openSnackbar={openSnackbar}
        />
      )}
      <SnackAlert isOpen={isSnackbarOpen} onClose={closeSnackbar}>
        Changes saved!
      </SnackAlert>
    </ManageSection>
  );
};

export default ManageGeneral;
