import type { ForwardRefRenderFunction } from 'react';
import { forwardRef } from 'react';

import type { TextFieldProps } from '@mui/material';
import { TextField as MuiTextField } from '@mui/material';

type Props = TextFieldProps & {
  onChangeText?: (value: string) => void;
};

const TextField: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  { onChange, onChangeText, ...props },
  ref,
) => {
  return (
    <MuiTextField
      ref={ref}
      onChange={(e) => {
        onChangeText?.(e.target.value);
        onChange?.(e);
      }}
      fullWidth
      variant='outlined'
      margin='normal'
      {...props}
    />
  );
};

export default forwardRef<HTMLDivElement, Props>(TextField);
