import type { FC } from 'react';
import { memo } from 'react';

import type { Donation } from '@gb/common';
import { Box, CircularProgress } from '@mui/material';

import FlatList from '../../base/FlatList';
import DonationListItem from './DonationListItem';

interface Props {
  donations: Donation[];
  onEndReached?: () => void;
  isLoading: boolean;
  ListEmptyComponent?: JSX.Element | null | undefined;
}

const DonationList: FC<Props> = ({
  donations,
  onEndReached,
  isLoading,
  ListEmptyComponent,
}) => {
  return (
    <FlatList
      data={donations}
      Item={ItemMemo}
      extractKey={extractKey}
      onEndReached={onEndReached}
      ListEmptyComponent={ListEmptyComponent}
      ListFooterComponent={
        isLoading ? (
          <Box py={3} display='flex' justifyContent='center'>
            <CircularProgress />
          </Box>
        ) : null
      }
    />
  );
};

export default DonationList;

const Item: FC<{ item: Donation }> = ({ item: donation }) => (
  <DonationListItem
    key={`donation-${donation.donationId}`}
    donation={donation}
  />
);

const ItemMemo = memo(
  Item,
  (prev, next) => prev.item.donationId === next.item.donationId,
);

const extractKey = (item: Donation) => `donation-${item.donationId}`;
