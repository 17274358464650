import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useState } from 'react';

import api from '../api';

interface Hook {
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  createBartender: (onSuccess?: () => void) => void;
  isLoading: boolean;
}

export default function useCreateBartender(eventId: string): Hook {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const createBartender = useCallback(
    (onSuccess?: () => void) => {
      if (isLoading) {
        return;
      }

      setIsLoading(true);
      api.bartender
        .create({ eventId, name: name.trim(), email })
        .then(onSuccess)
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    },
    [isLoading, eventId, name, email],
  );

  return {
    name,
    setName,
    email,
    setEmail,
    createBartender,
    isLoading,
  };
}
