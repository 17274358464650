import type { FC, ReactNode } from 'react';

import { Box, Container, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';

import MetaTags from '../../base/MetaTags';
import PageTitle from '../../base/PageTitle';
import MainAppBar from '../../components/common/MainAppBar';
import ManageFooter from '../../components/manage/ManageFooter';

const AdminLayout: FC = () => {
  return (
    <Box minHeight='100vh' display='flex' flexDirection='column'>
      <MainAppBar hasDrawer />
      <Box flexGrow={1}>
        <Outlet />
      </Box>
      <ManageFooter />
    </Box>
  );
};

export default AdminLayout;

export const AdminSection: FC<{ title: string; children: ReactNode }> = ({
  title,
  children,
}) => {
  return (
    <Box>
      <MetaTags title={title} />
      <PageTitle
        left={<Typography variant='h5'>Admin portal</Typography>}
        title={title}
      />
      <Container maxWidth='sm'>{children}</Container>
    </Box>
  );
};
