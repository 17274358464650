import { useMemo } from 'react';

import type { Event, Totals } from '@gb/common';

import { getPlatformFeeCents } from '../utils/getPlatformFeeCents';

interface Hook {
  platformFeeCents: number;
  netPlatformFeeCents: number;
  netCents: number;
  averageCents: number;
  bartenderCount: number;
  bartenderAverageCents: number;
  grandTotalCents: number;
}

export default function useFinancials(
  event: Event | undefined,
  totals: Totals | undefined,
): Hook {
  const { platformRate = 0, bartenderIds = [] } = event || {};

  const {
    totalCents = 0,
    platformCents = 0,
    offlineCents = 0,
    donorCount = 0,
  } = totals || {};

  const platformFeeCents = useMemo(
    () => getPlatformFeeCents({ totalCents, platformRate }),
    [totalCents, platformRate],
  );

  const netPlatformFeeCents = useMemo(
    () => platformFeeCents - platformCents,
    [platformFeeCents, platformCents],
  );

  const netCents = useMemo(
    () => totalCents - platformFeeCents + platformCents,
    [totalCents, platformFeeCents, platformCents],
  );

  const averageCents = useMemo(() => {
    if (!totalCents || !donorCount) {
      return 0;
    }

    return Math.trunc(totalCents / donorCount);
  }, [totalCents, donorCount]);

  const bartenderCount = useMemo(() => bartenderIds.length, [bartenderIds]);

  const bartenderAverageCents = useMemo(
    () => (bartenderCount ? totalCents / bartenderCount : 0),
    [bartenderCount, totalCents],
  );

  const grandTotalCents = useMemo(
    () => netCents + offlineCents,
    [netCents, offlineCents],
  );

  return {
    platformFeeCents,
    netPlatformFeeCents,
    netCents,
    averageCents,
    bartenderCount,
    bartenderAverageCents,
    grandTotalCents,
  };
}
