import type { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { CreateEventSection } from '.';
import { useCreateEventContext } from '../../context/CreateEventContext';
import DetailsForm from '../../forms/DetailsForm';
import getPath from '../../utils/getPath';

const CreateDetails: FC = () => {
  const { event, set } = useCreateEventContext();

  const navigate = useNavigate();

  function onSubmit() {
    navigate(getPath.eventCreate({ page: 'cause' }));
  }

  function onBack() {
    navigate(getPath.home());
  }

  return (
    <CreateEventSection title='Event Details'>
      <DetailsForm
        startsAt={event.startsAt}
        setStartsAt={(v) => set('startsAt', v)}
        endsAt={event.endsAt}
        setEndsAt={(v) => set('endsAt', v)}
        venue={event.venue}
        setVenue={(v) => set('venue', v)}
        addressStreet={event.addressStreet}
        setAddressStreet={(v) => set('addressStreet', v)}
        addressCity={event.addressCity}
        setAddressCity={(v) => set('addressCity', v)}
        addressState={event.addressState}
        setAddressState={(v) => set('addressState', v)}
        addressZip={event.addressZip}
        setAddressZip={(v) => set('addressZip', v)}
        isVirtual={event.isVirtual}
        setIsVirtual={(v) => set('isVirtual', v)}
        facebookUrl={event.facebookUrl}
        setFacebookUrl={(v) => set('facebookUrl', v)}
        onSubmit={onSubmit}
        submitLabel='Next'
        onCancel={onBack}
        cancelLabel='Cancel'
      />
    </CreateEventSection>
  );
};

export default CreateDetails;
