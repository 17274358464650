import type { FC } from 'react';

import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
} from '@mui/material';

import useIsDesktop from '../../hooks/useIsDesktop';
import getPath from '../../utils/getPath';

const AboutBanner: FC = () => {
  const isDesktop = useIsDesktop();

  return (
    <Box>
      <Container maxWidth='sm' sx={{ py: 2 }}>
        <Stack
          direction={isDesktop ? 'row' : 'column'}
          spacing={1}
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography variant='h6' fontWeight={300} gutterBottom={!isDesktop}>
            Host your own Guest Bartender event!
          </Typography>
          <Button
            variant='contained'
            color='secondary'
            href={getPath.marketing('about')}
          >
            Learn more
          </Button>
        </Stack>
      </Container>
      <Divider />
    </Box>
  );
};

export default AboutBanner;
