import { getDownloadURL } from 'firebase/storage';

import { getStorageRef } from './refs';

export default async function getPublicUrl(
  remotePath: string,
): Promise<string> {
  const ref = getStorageRef(remotePath);

  return getDownloadURL(ref);
}
