import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';

import type { Bartender } from '@gb/common';

import api from '../api';

interface Hook {
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  updateBartender: (onSuccess?: () => void) => void;
  removeBartender: (onSuccess?: () => void) => void;
  isLoading: boolean;
}

export default function useUpdateBartender(bartender: Bartender): Hook {
  const [name, setName] = useState(bartender.name);
  const [email, setEmail] = useState(bartender.email);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setName(bartender.name);
    setEmail(bartender.email);
  }, [bartender]);

  const updateBartender = useCallback(
    (onSuccess?: () => void) => {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      api.bartender
        .update(bartender.bartenderId, { name: name.trim(), email })
        .then(onSuccess)
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    },
    [isLoading, bartender, name, email],
  );

  const removeBartender = useCallback(
    (onSuccess?: () => void) => {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      api.bartender
        .remove(bartender.bartenderId)
        .then(onSuccess)
        .catch(console.error)
        .finally(() => {
          setIsLoading(true);
        });
    },
    [isLoading, bartender],
  );

  return {
    name,
    setName,
    email,
    setEmail,
    updateBartender,
    removeBartender,
    isLoading,
  };
}
