import type { FC } from 'react';

import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';

import MetaTags from '../base/MetaTags';
import Footer from '../components/common/Footer';
import MainAppBar from '../components/common/MainAppBar';
import Sponsors from '../components/common/Sponsors';
import EventHeader from '../components/event/EventHeader';
import EventPhrases from '../components/event/EventPhrases';
import EventTabs from '../components/event/EventTabs';
import TabAbout from '../components/event/TabAbout';
import TabDetails from '../components/event/TabDetails';
import TabDonations from '../components/event/TabDonations';
import useCanManageEvent from '../hooks/useCanManageEvent';
import useGetBartenderEvent from '../hooks/useGetBartenderEvent';
import useSubscribeBartenderTotals from '../hooks/useSubscribeBartenderTotals';
import { getCdnSrc } from '../utils/getCdnSrc';
import getPath from '../utils/getPath';
import NotFound from './NotFound';

const ViewBartender: FC = () => {
  const { bartenderId = '' } = useParams();
  const {
    event,
    bartenderName = '',
    isLoading,
    hasError,
  } = useGetBartenderEvent(bartenderId);

  const canManage = useCanManageEvent(event);

  const { eventId = '', name: eventName = '' } = event || {};

  const { totals, totalCents } = useSubscribeBartenderTotals({
    eventId,
    bartenderId,
  });

  const donateUrl = getPath.donate({ eventId, bartenderId });

  if (hasError) {
    return <NotFound />;
  }

  return (
    <Box>
      <MetaTags
        title={`${bartenderName}'s fundraiser - ${eventName}`}
        description={event?.tagline}
        image={event?.cover ? getCdnSrc({ src: event.cover }) : undefined}
      />
      <MainAppBar backTo={getPath.event({ eventId })} />
      <EventHeader
        event={event}
        bartenderName={bartenderName}
        totals={{ totalCents }}
        donateUrl={donateUrl}
        canManage={canManage}
        isLoading={isLoading}
      />
      <EventTabs event={event} donateUrl={donateUrl}>
        <TabAbout event={event} />
        <TabDetails event={event} />
        <TabDonations
          event={event}
          totals={totals}
          bartenderId={bartenderId}
          donateUrl={donateUrl}
        />
      </EventTabs>
      <Sponsors />
      <EventPhrases />
      <Footer />
    </Box>
  );
};

export default ViewBartender;
