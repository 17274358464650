import type { CheckoutData, CheckoutResponse } from '@gb/common';
import { logEvent } from 'firebase/analytics';
import { httpsCallable } from 'firebase/functions';

import getDonationAnalyticsData from '../donation/utils/getDonationAnalyticsData';
import { analytics, cloudFunctions } from '../firebase';

const callable = httpsCallable(cloudFunctions, 'checkout-create');

export default async function create(
  data: CheckoutData,
): Promise<CheckoutResponse> {
  const response = await callable(data);

  const { amountCents, platformRateCents, eventId, eventName } = data;

  logEvent(
    analytics,
    'begin_checkout',
    getDonationAnalyticsData({
      eventId,
      eventName,
      amountCents,
      platformRateCents,
    }),
  );

  return response.data as CheckoutResponse;
}
