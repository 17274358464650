import type { StorageReference } from 'firebase/storage';
import { ref } from 'firebase/storage';

import { storage } from '../firebase';

export function getEventImageRef({
  eventId,
  type,
}: {
  eventId: string;
  type: 'cover' | 'logo';
}): StorageReference {
  return ref(storage, `events/${eventId}/${type}`);
}

export function getStorageRef(remotePath: string): StorageReference {
  return ref(storage, remotePath);
}
