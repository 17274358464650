import type { FC } from 'react';
import { useRef, useState } from 'react';

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import LoadingIndicator from '../base/LoadingIndicator';
import TextField from '../base/TextField';

interface Props {
  title: string;
  name: string;
  setName: (value: string) => void;
  email: string;
  setEmail: (value: string) => void;
  onSubmit: () => void;
  submitLabel: string;
  onRemove?: () => void;
  removeLabel?: string;
  onCancel: (() => void) | undefined;
  cancelLabel?: string;
  isLoading: boolean;
  isReadOnly?: boolean;
}

const PersonForm: FC<Props> = ({
  title,
  name,
  setName,
  email,
  setEmail,
  onSubmit,
  submitLabel,
  onRemove,
  removeLabel = 'Remove',
  onCancel,
  cancelLabel = 'Cancel',
  isLoading,
  isReadOnly = false,
}) => {
  const formRef = useRef<HTMLFormElement>(null);

  const [isRemoveOpen, setIsRemoveOpen] = useState(false);

  function handleSubmit() {
    const isValid = formRef.current?.reportValidity();
    if (!isValid) {
      return;
    }
    onSubmit();
  }

  function handleEmail(value: string) {
    setEmail(value.trim().toLowerCase());
  }

  if (isRemoveOpen) {
    return (
      <>
        <DialogTitle>{removeLabel}</DialogTitle>
        <DialogContent>
          <Alert severity='error' action={null} sx={{ my: 1 }}>
            <AlertTitle>Are you sure you want to remove {name}?</AlertTitle>
            This action cannot be undone
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsRemoveOpen(false)}>Cancel</Button>
          <Button disabled={isLoading} color='error' onClick={onRemove}>
            Remove
          </Button>
        </DialogActions>
        <LoadingIndicator isLoading={isLoading} />
      </>
    );
  }

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          ref={formRef}
        >
          <TextField
            label='Name'
            value={name}
            onChangeText={setName}
            required
            autoFocus
            disabled={isReadOnly}
          />
          <TextField
            label='Email'
            value={email}
            onChangeText={handleEmail}
            required
            type='email'
            disabled={isReadOnly}
          />
          <Button type='submit' sx={{ display: 'none' }} />
        </form>
        {!!onRemove && (
          <Box mt={3} textAlign='center'>
            <Button
              color='error'
              onClick={() => setIsRemoveOpen(true)}
              variant='outlined'
            >
              {removeLabel}
            </Button>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {!!onCancel && (
          <Button onClick={onCancel} disabled={isLoading}>
            {cancelLabel}
          </Button>
        )}
        <Button disabled={isLoading} onClick={handleSubmit}>
          {submitLabel}
        </Button>
      </DialogActions>
      <LoadingIndicator isLoading={isLoading} />
    </>
  );
};

export default PersonForm;
