import type { FC } from 'react';

import type { Bartender } from '@gb/common';
import { Dialog } from '@mui/material';

import PersonForm from '../../forms/PersonForm';
import useIsDesktop from '../../hooks/useIsDesktop';
import useUpdateBartender from '../../hooks/useUpdateBartender';

interface Props {
  bartender: Bartender;
  isOpen: boolean;
  onClose: () => void;
}

const UpdateBartenderDialog: FC<Props> = ({ bartender, isOpen, onClose }) => {
  const isMobile = !useIsDesktop();
  const {
    name,
    setName,
    email,
    setEmail,
    updateBartender,
    removeBartender,
    isLoading,
  } = useUpdateBartender(bartender);

  function onSubmit() {
    updateBartender(onClose);
  }

  function onRemove() {
    removeBartender(onClose);
  }

  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen={isMobile}>
      <PersonForm
        title='Update guest bartender'
        name={name}
        setName={setName}
        email={email}
        setEmail={setEmail}
        onSubmit={onSubmit}
        submitLabel='Update bartender'
        onRemove={onRemove}
        removeLabel='Remove bartender'
        onCancel={onClose}
        isLoading={isLoading}
      />
    </Dialog>
  );
};

export default UpdateBartenderDialog;
