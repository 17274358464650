import type { FC } from 'react';

import type { Event } from '@gb/common';
import EventIcon from '@mui/icons-material/Event';
import FacebookIcon from '@mui/icons-material/Facebook';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import PlaceIcon from '@mui/icons-material/Place';
import { Box, Grid, Typography } from '@mui/material';
import type { Timestamp } from 'firebase/firestore';

import GoogleMap from '../../base/GoogleMap';
import LinkifyText from '../../base/LinkifyText';
import EventSection from './EventSection';

interface Props {
  event: Event | undefined;
}

const TabDetails: FC<Props> = ({ event }) => {
  if (!event) {
    return null;
  }

  const {
    name,
    venue,
    addressStreet,
    addressCity,
    addressState,
    addressZip,
    isVirtual,
    startsAt,
    endsAt,
    facebookUrl,
  } = event;

  return (
    <EventSection id='details' title='Event Details'>
      <Grid container columnSpacing={4}>
        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
          <Box mb={3}>
            <Typography variant='body1'>
              We invite you to join us and our guest bartenders for an evening
              of drinks, fun, and friendly rivalry in supporting {name}!
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', mb: 2 }}>
            <EventIcon fontSize='medium' color='secondary' sx={{ mr: 2 }} />
            <Box>
              <Typography variant='body1'>
                {getDateString({ startsAt, endsAt })}
              </Typography>
              <Typography variant='body2'>
                {getTimeString({ startsAt, endsAt })}
              </Typography>
            </Box>
          </Box>
          {isVirtual && (
            <Box sx={{ display: 'flex', mb: 2 }}>
              <PhonelinkIcon color='secondary' sx={{ mr: 2 }} />
              <Typography variant='body1'>Virtual event</Typography>
            </Box>
          )}
          {!isVirtual && (
            <Box sx={{ display: 'flex', mb: 2 }}>
              <PlaceIcon fontSize='medium' color='secondary' sx={{ mr: 2 }} />
              <Box>
                <Typography variant='body1'>{venue}</Typography>
                <Typography variant='body2'>{addressStreet}</Typography>
                <Typography variant='body2'>
                  {addressCity}, {addressState} {addressZip}
                </Typography>
              </Box>
            </Box>
          )}
          <Box sx={{ display: 'flex', mb: 2 }}>
            <FacebookIcon fontSize='medium' color='secondary' sx={{ mr: 2 }} />
            <Box>
              <Typography variant='body1'>Share on Facebook</Typography>
              <Typography
                variant='body2'
                sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}
              >
                <LinkifyText content={facebookUrl} />
              </Typography>
            </Box>
          </Box>
        </Grid>
        {!isVirtual && (
          <Grid item xs={12} sm={6}>
            <GoogleMap
              venue={venue}
              addressStreet={addressStreet}
              addressCity={addressCity}
              addressState={addressState}
              addressZip={addressZip}
              aspectRatio={4 / 3}
            />
          </Grid>
        )}
      </Grid>
    </EventSection>
  );
};

function getTimeString({
  startsAt,
  endsAt,
}: {
  startsAt: Timestamp;
  endsAt: Timestamp;
}) {
  const startTime = startsAt
    .toDate()
    .toLocaleString(undefined, { timeStyle: 'short' });

  const endTime = endsAt
    .toDate()
    .toLocaleString(undefined, { timeStyle: 'short' });

  return `${startTime} - ${endTime}`;
}

function getDateString({
  startsAt,
  endsAt,
}: {
  startsAt: Timestamp;
  endsAt: Timestamp;
}) {
  const startDate = startsAt
    .toDate()
    .toLocaleString(undefined, { dateStyle: 'long' });
  const endDate = endsAt
    .toDate()
    .toLocaleString(undefined, { dateStyle: 'long' });

  const isSameDay = startDate === endDate;

  return `${startDate}${isSameDay ? '' : ' - ' + endDate}`;
}

export default TabDetails;
