import type { FC } from 'react';
import { useMemo } from 'react';

import { getAspectRatio } from '../utils/getAspectRatio';
import type { ImageSizeKey } from '../utils/getCdnSrc';
import { CDN_IMAGE_SIZES, getCdnSrc } from '../utils/getCdnSrc';
import type { ResponsiveImageProps } from './ResponsiveImage';
import ResponsiveImage from './ResponsiveImage';

type Props = Omit<ResponsiveImageProps, 'maxWidth'> & {
  maxSize?: ImageSizeKey | undefined;
};

const CdnImage: FC<Props> = ({
  src,
  aspectRatio,
  maxSize = 'lg',
  ...props
}) => {
  const ratio = useMemo(() => getAspectRatio(aspectRatio), [aspectRatio]);

  const fallback = useMemo(() => {
    if (!src) {
      return;
    }

    return getCdnSrc({ src, ratio });
  }, [src, ratio]);

  const srcSet = useMemo(
    () => getSrcSet({ src, ratio, maxWidth: CDN_IMAGE_SIZES[maxSize] }),
    [src, ratio, maxSize],
  );

  return (
    <ResponsiveImage
      src={fallback}
      srcSet={srcSet}
      aspectRatio={aspectRatio}
      {...props}
    />
  );
};

export default CdnImage;

function getSrcSet({
  src,
  ratio,
  maxWidth,
}: {
  src: ResponsiveImageProps['src'];
  ratio: number | undefined;
  maxWidth: number;
}): string | undefined {
  if (!src) {
    return;
  }

  return Object.values(CDN_IMAGE_SIZES)
    .filter((width) => width <= maxWidth)
    .map((width) => `${getCdnSrc({ src, width, ratio })} ${width}w`)
    .join(',');
}
