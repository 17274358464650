import type { ForwardRefRenderFunction } from 'react';
import { forwardRef, useMemo } from 'react';

import type { BoxProps } from '@mui/material';
import { Box, Skeleton } from '@mui/material';

import type { Ratio } from '../utils/getAspectRatio';
import { getAspectRatio } from '../utils/getAspectRatio';

export type ResponsiveImageProps = Omit<BoxProps<'img'>, 'ref'> & {
  aspectRatio?: Ratio | undefined;
  isLoading?: boolean;
};

const ResponsiveImage: ForwardRefRenderFunction<
  HTMLImageElement,
  ResponsiveImageProps
> = ({ aspectRatio, sx, isLoading = false, ...props }, ref) => {
  const ratio = useMemo(
    () => getAspectRatio(aspectRatio)?.toFixed(3),
    [aspectRatio],
  );

  if (isLoading) {
    return (
      <Skeleton
        variant='rectangular'
        width={'100%'}
        height='auto'
        sx={{ aspectRatio: ratio }}
      />
    );
  }

  return (
    <Box
      component='img'
      sx={{
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
        aspectRatio: ratio,
        ...sx,
      }}
      ref={ref}
      {...props}
    />
  );
};

export default forwardRef<HTMLImageElement, ResponsiveImageProps>(
  ResponsiveImage,
);
