import type { FC } from 'react';

import type { AvatarProps } from '@mui/material';
import { Avatar } from '@mui/material';

interface Props {
  name: string;
  size?: number;
  sx?: AvatarProps['sx'];
  avatarProps?: Omit<AvatarProps, 'sx'>;
}

const AVATAR_SIZE = 32;

const AvatarLetter: FC<Props> = ({
  name,
  size = AVATAR_SIZE,
  sx,
  avatarProps,
}) => {
  return (
    <Avatar
      {...stringAvatar(name, {
        fontSize: size / 2 - 1,
        width: size,
        height: size,
        ...sx,
      })}
      {...avatarProps}
    />
  );
};

export default AvatarLetter;

function stringToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

function stringAvatar(name: string, sx?: AvatarProps['sx']) {
  const bySpace = name.trim().split(' ');
  const firstName = bySpace[0];
  const lastName = bySpace[bySpace.length - 1];

  const first = firstName?.[0] || '';
  const last = lastName?.[0] || '';

  return {
    sx: {
      ...sx,
      bgcolor: stringToColor(name),
    },
    children: `${first.toUpperCase()}${last.toUpperCase()}`,
  };
}
