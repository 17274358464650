import { useCallback, useEffect, useState } from 'react';

import type { Totals } from '@gb/common';

import api from '../api';

interface Hook {
  offlineCents: number;
  setOfflineCents: (value: number) => void;
  onSubmit: (onSuccess?: () => void) => void;
  isLoading: boolean;
}

export default function useUpdateOfflineTotal(
  eventId: string | undefined,
  totals: Totals | undefined,
): Hook {
  const [isLoading, setIsLoading] = useState(false);
  const [offlineCents, setOfflineCents] = useState(totals?.offlineCents || 0);

  useEffect(() => {
    setOfflineCents(totals?.offlineCents || 0);
  }, [totals]);

  const onSubmit = useCallback(
    (onSuccess?: () => void) => {
      if (isLoading || !eventId) {
        return;
      }
      setIsLoading(true);
      api.donation
        .updateOfflineTotal(eventId, offlineCents)
        .then(onSuccess)
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    },
    [isLoading, eventId, offlineCents],
  );

  return { offlineCents, setOfflineCents, onSubmit, isLoading };
}
