import type { Organizer } from '@gb/common';
import { setDoc } from 'firebase/firestore';

import { getOrganizerRef } from './refs';

export default async function update(
  organizerId: string,
  data: Partial<Organizer>,
): Promise<void> {
  const ref = getOrganizerRef(organizerId);
  await setDoc(ref, data, { merge: true });
}
