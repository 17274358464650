import type { FC } from 'react';

import type { Bartender, Organizer } from '@gb/common';
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import LoadingIndicator from '../../base/LoadingIndicator';
import useIsDesktop from '../../hooks/useIsDesktop';
import useResendWelcome from '../../hooks/useResendWelcome';

interface Props {
  person: Organizer | Bartender;
  onClose: () => void;
  onAlert: () => void;
}

const ResendWelcomeDialog: FC<Props> = ({ person, onClose, onAlert }) => {
  const { onSend, isLoading } = useResendWelcome(person);

  const isMobile = !useIsDesktop();

  const handleSend = () =>
    onSend(() => {
      onAlert();
      onClose();
    });

  return (
    <Dialog open onClose={onClose} fullScreen={isMobile}>
      <DialogTitle>Resend invitation email</DialogTitle>
      <DialogContent>
        <Alert severity='warning' sx={{ mb: 3 }}>
          <AlertTitle>
            Are you sure you want to resend the invitation to {person.email}?
          </AlertTitle>
          An invitation was sent automatically when this person was added, or
          when their email address was updated.
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
        <Button onClick={handleSend} disabled={isLoading}>
          Resend Invite
        </Button>
      </DialogActions>
      <LoadingIndicator isLoading={isLoading} />
    </Dialog>
  );
};

export default ResendWelcomeDialog;
