import type { FC, ReactNode } from 'react';
import { useRef } from 'react';

import type { Event } from '@gb/common';
import HomeIcon from '@mui/icons-material/Home';
import { Box, IconButton } from '@mui/material';

import DonateFab from '../../base/DonateFab';
import useIsDesktop from '../../hooks/useIsDesktop';
import useIsVisible from '../../hooks/useIsVisible';
import EventTabBar, { TabBarItem } from './EventTabBar';

interface Props {
  event: Event | undefined;
  donateUrl: string;
  showBartenders?: boolean;
  children: ReactNode;
}

const EventTabs: FC<Props> = ({
  event,
  donateUrl,
  showBartenders = false,
  children,
}) => {
  const isMobile = !useIsDesktop();

  const tabRef = useRef<HTMLDivElement>(null);
  const areTabsVisible = useIsVisible(tabRef, 0.2);

  function scrollToTop() {
    window.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
  }

  if (!event) {
    return null;
  }

  return (
    <Box ref={tabRef} pb={3}>
      <EventTabBar
        donateUrl={donateUrl}
        showDonateButton={!isMobile && event.canDonate}
      >
        <IconButton onClick={scrollToTop}>
          <HomeIcon color='primary' />
        </IconButton>
        <TabBarItem id='about' label={isMobile ? 'About' : 'About the Cause'} />
        <TabBarItem
          id='details'
          label={isMobile ? 'Details' : 'Event Details'}
        />
        {showBartenders && (
          <TabBarItem
            id='bartenders'
            label={isMobile ? 'People' : 'Guest Bartenders'}
          />
        )}
        <TabBarItem id='donations' label='Donations' />
      </EventTabBar>
      {children}
      <DonateFab isVisible={isMobile && areTabsVisible} to={donateUrl} />
    </Box>
  );
};

export default EventTabs;
