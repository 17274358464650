import type { Totals } from '@gb/common';
import { get } from 'firebase/database';

import { getTotalsRef } from './refs';

export default async function getTotals(
  eventId: string,
): Promise<Totals | undefined> {
  const ref = getTotalsRef(eventId);

  const snap = await get(ref);
  const val = snap.val() as Totals | null;
  if (!val) {
    return;
  }

  return val;
}
