import { useCallback, useState } from 'react';

import type { SendSignInLinkData } from '@gb/common';
import { fetchSignInMethodsForEmail } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';

import { auth, cloudFunctions } from '../api/firebase';
import getPath from '../utils/getPath';

interface Hook {
  email: string;
  setEmail: (value: string) => void;
  isSent: boolean;
  onSubmit: () => void;
  onReset: () => void;
  isLoading: boolean;
  hasError: boolean;
}

const callable = httpsCallable(cloudFunctions, 'people-sendSignInLink');

export default function useSendSignInLink(redirectTo: string | null): Hook {
  const [email, setEmailState] = useState('');
  const [isSent, setIsSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const setEmail = useCallback((value: string) => {
    setHasError(false);
    setEmailState(value.toLowerCase().trim());
  }, []);

  const onSubmit = useCallback(() => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    sendLink({ email, redirectTo })
      .then(() => {
        window.localStorage.setItem('emailForSignIn', email);
        setHasError(false);
        setIsSent(true);
      })
      .catch((err) => {
        console.error(err);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [isLoading, email, redirectTo]);

  const onReset = useCallback(() => {
    setIsSent(false);
    setEmail('');
  }, [setEmail]);

  return { email, setEmail, isSent, onSubmit, onReset, isLoading, hasError };
}

async function sendLink({
  email,
  redirectTo,
}: {
  email: string;
  redirectTo: string | null;
}): Promise<void> {
  const methods = await fetchSignInMethodsForEmail(auth, email);
  if (!methods.length) {
    throw new Error('user does not exist');
  }
  const now = new Date();
  const data: SendSignInLinkData = {
    email,
    continueUrl:
      window.location.origin + getPath.signInComplete({ redirectTo }),
    timeString: now.toLocaleTimeString(undefined, { timeStyle: 'medium' }),
  };

  await callable(data);
}
