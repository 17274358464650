/**
 * Calculate the platform fee for an event
 * @returns The platform fee (in cents)
 */
export function getPlatformFeeCents({
  totalCents,
  platformRate,
}: {
  /** total donations (in cents) */
  totalCents: number;
  /** platform rate in decimal: 0.10 = 10% */
  platformRate: number;
}): number {
  return Math.round(totalCents * platformRate);
}
