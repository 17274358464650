import type { Donation } from '@gb/common';
import {
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore';

import { getDonationCollectionRef } from './refs';

const LIMIT = 20;

interface Params {
  eventId: string;
  bartenderId?: string | undefined;
}

export default async function list(
  { eventId, bartenderId }: Params,
  docLimit = LIMIT,
  startAfterDoc?: Donation,
): Promise<Donation[]> {
  let q = query(
    getDonationCollectionRef(),
    orderBy('createdAt', 'desc'),
    where('eventId', '==', eventId),
    limit(docLimit),
  );
  if (bartenderId) {
    q = query(q, where('bartenderId', '==', bartenderId));
  }
  if (startAfterDoc) {
    q = query(q, startAfter(startAfterDoc.createdAt));
  }

  const snap = await getDocs(q);

  return snap.docs.map((doc) => doc.data());
}
