import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';

import type { Event } from '@gb/common';

import api from '../api';

interface Hook {
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  tagline: string;
  setTagline: Dispatch<SetStateAction<string>>;
  description: string;
  setDescription: Dispatch<SetStateAction<string>>;
  causeName: string;
  setCauseName: Dispatch<SetStateAction<string>>;
  causeUrl: string;
  setCauseUrl: Dispatch<SetStateAction<string>>;
  causeTaxId: string;
  setCauseTaxId: Dispatch<SetStateAction<string>>;
  onSubmit: (onSuccess?: () => void) => void;
  isLoading: boolean;
}

export default function useUpdateCause(event: Event | undefined): Hook {
  const [name, setName] = useState(event?.name || '');
  const [tagline, setTagline] = useState(event?.tagline || '');
  const [description, setDescription] = useState(event?.description || '');
  const [causeName, setCauseName] = useState(event?.causeName || '');
  const [causeUrl, setCauseUrl] = useState(event?.causeUrl || '');
  const [causeTaxId, setCauseTaxId] = useState(event?.causeTaxId || '');
  const [isLoading, setIsLoading] = useState(false);

  const onReset = useCallback(() => {
    setName(event?.name || '');
    setTagline(event?.tagline || '');
    setDescription(event?.description || '');
    setCauseName(event?.causeName || '');
    setCauseUrl(event?.causeUrl || '');
    setCauseTaxId(event?.causeTaxId || '');
  }, [event]);

  useEffect(() => {
    onReset();
  }, [onReset]);

  const onSubmit = useCallback(
    (onSuccess?: () => void) => {
      if (isLoading || !event) {
        return;
      }

      setIsLoading(true);
      api.event
        .update(event.eventId, {
          name,
          tagline,
          description,
          causeName,
          causeUrl,
          causeTaxId,
        })
        .then(onSuccess)
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    },
    [
      isLoading,
      event,
      name,
      tagline,
      description,
      causeName,
      causeUrl,
      causeTaxId,
    ],
  );

  return {
    name,
    setName,
    tagline,
    setTagline,
    description,
    setDescription,
    causeName,
    setCauseName,
    causeUrl,
    setCauseUrl,
    causeTaxId,
    setCauseTaxId,
    onSubmit,
    isLoading,
  };
}
