import type { FC, ReactNode } from 'react';

import { Box, Container, Divider, Toolbar, Typography } from '@mui/material';

export type EventSectionId = 'about' | 'details' | 'bartenders' | 'donations';

interface Props {
  id: EventSectionId;
  title: string;
  children: ReactNode;
  isLast?: boolean;
}

const EventSection: FC<Props> = ({ id, title, children, isLast = false }) => {
  return (
    <Container
      maxWidth='md'
      sx={{
        position: 'relative',
        py: 3,
        pageBreakInside: 'avoid',
        breakInside: 'avoid',
      }}
    >
      <Box
        id={id}
        position='absolute'
        top={0}
        width={0}
        sx={{ transform: 'translateY(-100%)' }}
      >
        <Toolbar />
      </Box>
      <Typography variant='h5' color='primary' paragraph>
        {title}
      </Typography>
      {children}
      {!isLast && <Divider sx={{ py: 2 }} />}
    </Container>
  );
};

export default EventSection;
