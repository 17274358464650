import type { FC } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Typography,
} from '@mui/material';

import QRCodeDownload from '../../base/QRCodeDownload';
import useIsDesktop from '../../hooks/useIsDesktop';
import { ReactComponent as LogoSvg } from '../../images/logo-full.svg';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  href?: string;
  title?: string;
}

const QrDialog: FC<Props> = ({
  isOpen,
  onClose,
  href = window.location.href,
  title = 'Share this fundraiser',
}) => {
  const isMobile = !useIsDesktop();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen={isMobile}
      maxWidth='xs'
      aria-labelledby='qr-dialog-title'
    >
      <DialogTitle id='qr-dialog-title' textAlign='center'>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText textAlign='center'>
          Use this QR code to share a link to the fundraiser
        </DialogContentText>
        <Link href={href}>
          <Typography
            variant='body2'
            textAlign='center'
            sx={{ wordBreak: 'break-word' }}
            paragraph
          >
            {href}
          </Typography>
        </Link>
        <QRCodeDownload value={href} size={200} boxProps={{ sx: { mb: 3 } }} />
        <Box px={3} display='flex' justifyContent='center'>
          <Box
            sx={{ width: '66%', borderRadius: 2 }}
            px={1}
            bgcolor='primary.main'
          >
            <LogoSvg width='100%' />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default QrDialog;
