import { useCallback, useState } from 'react';

import api from '../api';

interface Hook {
  /** generate and download report */
  downloadReport: () => void;
  /** true while loading */
  isLoading: boolean;
  /** true while error */
  hasError: boolean;
}

export default function useDonorReport(eventId: string | undefined): Hook {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const downloadReport = useCallback(() => {
    if (!eventId || isLoading) {
      return;
    }
    setHasError(false);
    setIsLoading(true);
    api.reports
      .getDonorReport(eventId)
      .then((url) => {
        downloadFile(url);
      })
      .catch((err) => {
        console.error(err);
        setHasError(true);
      })
      .finally(() => setIsLoading(false));
  }, [isLoading, eventId]);

  return { downloadReport, isLoading, hasError };
}

function downloadFile(url: string) {
  // Create a hidden anchor element
  const link = document.createElement('a');
  link.href = url;
  link.style.display = 'none';

  // Add the link to the DOM
  document.body.appendChild(link);

  // Trigger the click event to initiate the download
  link.click();

  // Remove the link from the DOM after the download
  document.body.removeChild(link);
}
