import type { FC } from 'react';

import { Typography } from '@mui/material';

import TextField from '../base/TextField';
import FormBase from './FormBase';

interface Props {
  name: string;
  setName: (value: string) => void;
  tagline: string;
  setTagline: (value: string) => void;
  description: string;
  setDescription: (value: string) => void;
  causeName: string;
  setCauseName: (value: string) => void;
  causeUrl: string;
  setCauseUrl: (value: string) => void;
  causeTaxId: string;
  setCauseTaxId: (value: string) => void;
  onSubmit: () => void;
  submitLabel: string;
  onCancel?: () => void;
  cancelLabel?: string;
  isLoading?: boolean;
}

const CauseForm: FC<Props> = ({
  name,
  setName,
  tagline,
  setTagline,
  description,
  setDescription,
  causeName,
  setCauseName,
  causeUrl,
  setCauseUrl,
  causeTaxId,
  setCauseTaxId,
  onSubmit,
  submitLabel,
  onCancel,
  cancelLabel = 'Cancel',
  isLoading,
}) => {
  return (
    <FormBase
      onSubmit={onSubmit}
      submitLabel={submitLabel}
      onCancel={onCancel}
      cancelLabel={cancelLabel}
      isLoading={isLoading}
    >
      <TextField
        label='Event name'
        value={name}
        onChangeText={setName}
        required
      />
      <Typography variant='button' component='div' sx={{ mt: 3, mb: 1 }}>
        Mission statement
      </Typography>
      <TextField
        label='One-sentence mission statement'
        value={tagline}
        onChangeText={setTagline}
        required
      />
      <TextField
        label='Long-form mission statement'
        value={description}
        onChangeText={setDescription}
        required
        multiline
        minRows={3}
      />
      <Typography variant='button' component='div' sx={{ mt: 3, mb: 1 }}>
        Organization profile
      </Typography>
      <TextField
        label='Organization legal name'
        value={causeName}
        onChangeText={setCauseName}
        required
      />
      <TextField label='Website' value={causeUrl} onChangeText={setCauseUrl} />
      <TextField
        label='Tax ID (if applicable)'
        value={causeTaxId}
        onChangeText={setCauseTaxId}
      />
    </FormBase>
  );
};

export default CauseForm;
