import type { FC } from 'react';

import { Dialog } from '@mui/material';

import PersonForm from '../../forms/PersonForm';
import useCreateAdmin from '../../hooks/admin/useCreateAdmin';
import useIsDesktop from '../../hooks/useIsDesktop';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const AddAdminDialog: FC<Props> = ({ isOpen, onClose }) => {
  const isMobile = !useIsDesktop();
  const { onSubmit, ...props } = useCreateAdmin();

  const handleSubmit = () => onSubmit(onClose);

  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen={isMobile}>
      <PersonForm
        title='Add site admin'
        {...props}
        onSubmit={handleSubmit}
        submitLabel='Add site admin'
        onCancel={onClose}
      />
    </Dialog>
  );
};

export default AddAdminDialog;
