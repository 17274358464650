import type { FC } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';

import MoneyField from '../base/MoneyField';
import PercentField from '../base/PercentField';
import FormBase from './FormBase';

interface Props {
  isActive: boolean;
  setIsActive: (value: boolean) => void;
  canDonate: boolean;
  setCanDonate: (value: boolean) => void;
  goalCents: number | undefined;
  setGoalCents: (value: number) => void;
  platformRate: number;
  setPlatformRate: (value: number) => void;
  offlineCents?: number;
  onClickOfflineCents?: () => void;
  onSubmit: () => void;
  submitLabel: string;
  onCancel?: () => void;
  cancelLabel?: string;
  onRemove?: () => void;
  isLoading?: boolean;
}

const GeneralForm: FC<Props> = ({
  isActive,
  setIsActive,
  canDonate,
  setCanDonate,
  goalCents,
  setGoalCents,
  platformRate,
  setPlatformRate,
  offlineCents,
  onClickOfflineCents,
  onSubmit,
  submitLabel,
  onCancel,
  cancelLabel = 'Cancel',
  onRemove,
  isLoading,
}) => {
  return (
    <FormBase
      onSubmit={onSubmit}
      submitLabel={submitLabel}
      onCancel={onCancel}
      cancelLabel={cancelLabel}
      isLoading={isLoading}
    >
      <Typography variant='button' component='div' sx={{ mb: 1 }}>
        Status
      </Typography>
      <Box>
        <FormHelperText>Show event on calendar?</FormHelperText>
        <FormControlLabel
          control={
            <Switch
              color='primary'
              checked={isActive}
              onChange={(_e, checked) => setIsActive(checked)}
            />
          }
          label={isActive ? 'Event is visible' : 'Event is hidden'}
          labelPlacement='end'
          componentsProps={{
            typography: {
              sx: { color: isActive ? 'inherit' : 'warning.light' },
            },
          }}
        />
      </Box>
      <Box>
        <FormHelperText>Can the event accept donations?</FormHelperText>
        <FormControlLabel
          control={
            <Switch
              color='primary'
              checked={canDonate}
              onChange={(_e, checked) => setCanDonate(checked)}
            />
          }
          label={canDonate ? 'Donations are enabled' : 'Donations turned off'}
          labelPlacement='end'
          componentsProps={{
            typography: {
              sx: { color: canDonate ? 'inherit' : 'warning.light' },
            },
          }}
        />
      </Box>
      {!!onRemove && (
        <Box>
          <FormHelperText>Go to remove event screen</FormHelperText>
          <Button
            onClick={onRemove}
            variant='outlined'
            color='error'
            size='small'
            sx={{ mt: 1 }}
          >
            Remove event
          </Button>
        </Box>
      )}
      <Typography variant='button' component='div' sx={{ mt: 3, mb: 1 }}>
        Financial
      </Typography>
      <Box>
        <MoneyField
          label='Fundraising goal'
          value={goalCents}
          onChange={setGoalCents}
          required
          inputProps={{ step: 1 }}
          fullWidth={false}
        />
      </Box>
      <Box>
        <PercentField
          label='Platform rate'
          value={platformRate}
          onChange={setPlatformRate}
          required
          fullWidth={false}
        />
      </Box>
      {!!onClickOfflineCents && (
        <MoneyField
          label='Offline fundraising total'
          value={offlineCents}
          onChange={() => undefined}
          onClick={onClickOfflineCents}
          fullWidth={false}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <IconButton onClick={onClickOfflineCents} size='small'>
                <EditIcon />
              </IconButton>
            ),
          }}
        />
      )}
    </FormBase>
  );
};

export default GeneralForm;
