import { useCallback } from 'react';

import type { Event } from '@gb/common';

import api from '../api';
import { useAuthContext } from '../context/AuthContext';
import useLoadList from './useLoadList';

interface Hook {
  events: Event[];
  loadMore: () => void;
  hasMore: boolean;
  isLoading: boolean;
}

export default function useListMyEvents({
  isPast = false,
}: {
  isPast?: boolean;
}): Hook {
  const { userId, isAdmin } = useAuthContext();

  const getItems = useCallback(
    async (
      docLimit: number,
      startAfterDoc?: Event | undefined,
    ): Promise<Event[]> => {
      if (!userId) {
        return Promise.resolve([]);
      }
      const organizerId = isAdmin ? undefined : userId;

      return api.event.list({
        docLimit,
        startAfterDoc,
        organizerId,
        isPast,
        isActive: false,
      });
    },
    [userId, isAdmin, isPast],
  );

  const { items: events, loadMore, hasMore, isLoading } = useLoadList(getItems);

  return { events, loadMore, hasMore, isLoading };
}
