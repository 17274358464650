import { useMemo } from 'react';

import type { Totals } from '@gb/common';

import useSubscribeTotals from './useSubscribeTotals';

interface Hook {
  totals: Totals | undefined;
  totalCents: number;
  isLoading: boolean;
}

export default function useSubscribeBartenderTotals({
  eventId,
  bartenderId,
}: {
  eventId: string | undefined;
  bartenderId: string | undefined;
}): Hook {
  const { totals, isLoading } = useSubscribeTotals(eventId);

  const totalCents = useMemo((): number => {
    if (!bartenderId || !totals) {
      return 0;
    }

    return totals.bartenderTotals?.[bartenderId] || 0;
  }, [totals, bartenderId]);

  return { totals, totalCents, isLoading };
}
