import { set } from 'firebase/database';

import { getOfflineTotalRef } from './refs';

export default async function updateOfflineTotal(
  eventId: string,
  offlineCents: number,
): Promise<void> {
  const ref = getOfflineTotalRef(eventId);

  await set(ref, offlineCents);
}
