import type { FC, ReactNode } from 'react';
import { createContext, useContext, useEffect, useMemo, useRef } from 'react';

import { logEvent, setAnalyticsCollectionEnabled } from 'firebase/analytics';
import { useLocation } from 'react-router-dom';

import { analytics } from '../api/firebase';
import { isProd } from '../utils/isDev';
import { useAuthContext } from './AuthContext';

type AnalyticsContextValue = undefined;

const analyticsContext = createContext<AnalyticsContextValue>(undefined);

export function useAnalyticsContext(): AnalyticsContextValue {
  return useContext(analyticsContext);
}

interface Props {
  children: ReactNode;
}

export const AnalyticsContextProvider: FC<Props> = ({ children }) => {
  const { isAdmin } = useAuthContext();
  const location = useLocation();

  const lastLocationRef = useRef<string | null>(null);

  // enable analytics in production for non-admins
  useEffect(() => {
    if (isAdmin === undefined) {
      return;
    }
    const isEnabled = isProd() && !isAdmin;

    setAnalyticsCollectionEnabled(analytics, isEnabled);
  }, [isAdmin]);

  // log screen_view on location change
  useEffect(() => {
    const currentLocation = window.location.href;
    const lastLocation = lastLocationRef.current;
    lastLocationRef.current = currentLocation;
    if (currentLocation === lastLocation) {
      return;
    }
    logEvent(analytics, 'screen_view');
  }, [location]);

  const value = useMemo((): AnalyticsContextValue => undefined, []);

  return (
    <analyticsContext.Provider value={value}>
      {children}
    </analyticsContext.Provider>
  );
};
