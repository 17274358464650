import type { FC, ReactNode } from 'react';

import { Box, Container, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';

import MetaTags from '../../base/MetaTags';
import PageTitle from '../../base/PageTitle';
import MainAppBar from '../../components/common/MainAppBar';
import ManageFooter from '../../components/manage/ManageFooter';
import { CreateEventContextProvider } from '../../context/CreateEventContext';

const CreateEvent: FC = () => {
  return (
    <CreateEventContextProvider>
      <Outlet />
    </CreateEventContextProvider>
  );
};

export default CreateEvent;

export const CreateEventSection: FC<{ title: string; children: ReactNode }> = ({
  title,
  children,
}) => {
  return (
    <Box>
      <MetaTags title={`Create event - ${title}`} />
      <MainAppBar hasDrawer />
      <PageTitle title='Create Event' />
      <Container maxWidth='sm' sx={{ mb: 6 }}>
        <Typography variant='h6' paragraph>
          {title}
        </Typography>
        {children}
      </Container>
      <ManageFooter />
    </Box>
  );
};
