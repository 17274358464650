import { isDev } from './isDev';

const CDN_BASE = '/cdn-cgi/image';

export const CDN_IMAGE_SIZES = { sm: 750, lg: 1500 };
export const DEFAULT_CDN_WIDTH = CDN_IMAGE_SIZES['sm'];

export type ImageSizeKey = keyof typeof CDN_IMAGE_SIZES;

export function getCdnSrc({
  src,
  width = DEFAULT_CDN_WIDTH,
  ratio,
}: {
  src: string;
  width?: number | undefined;
  ratio?: number | undefined;
}): string {
  if (isDev()) {
    // bypass in development
    return src;
  }

  const options: ResizeOptions = { width };
  if (ratio) {
    options.height = Math.round(width / ratio);
  }
  const optionsString = getOptionsString(options);

  return `${CDN_BASE}/${optionsString}/${src}`;
}

interface ResizeOptions {
  fit?: 'scale-down' | 'contain' | 'cover' | 'crop' | 'pad';
  format?: 'auto' | 'avif' | 'webp' | 'json';
  height?: number;
  width?: number;
  metadata?: 'keep' | 'copyright' | 'none';
  quality?: number;
}

const defaultOptions: ResizeOptions = {
  fit: 'crop',
  format: 'auto',
  metadata: 'copyright',
  quality: 85,
};

function getOptionsString(options: ResizeOptions): string {
  return Object.entries({ ...defaultOptions, ...options })
    .map(([key, value]) => `${key}=${value.toString()}`)
    .join(',');
}
