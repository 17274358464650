import type { ChangeEventHandler, FC } from 'react';
import { useMemo } from 'react';

import type { TextFieldProps } from '@mui/material';
import { Timestamp } from 'firebase/firestore';

import TextField from './TextField';

type Props = Omit<TextFieldProps, 'value' | 'onChange' | 'type'> & {
  value: Timestamp | undefined;
  onChange: (value: Timestamp) => void;
};

const DateField: FC<Props> = ({ value, onChange, ...props }) => {
  const stringValue = useMemo(
    (): string | undefined => getStringFromTimestamp(value),
    [value],
  );

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const timestamp = getTimestampFromString(e.target.value);
    onChange(timestamp);
  };

  return (
    <TextField
      value={stringValue}
      onChange={handleChange}
      type='datetime-local'
      {...props}
    />
  );
};

export default DateField;

function getStringFromTimestamp(
  timestamp: Timestamp | undefined,
): string | undefined {
  if (!timestamp) {
    return '';
  }

  const d = timestamp.toDate();

  const yyyy = d.toLocaleDateString(undefined, { year: 'numeric' });
  const MM = d.toLocaleDateString(undefined, { month: '2-digit' });
  const dd = d.toLocaleDateString(undefined, { day: '2-digit' });

  const time = d.toLocaleTimeString(undefined, {
    timeStyle: 'short',
    hour12: false,
  });

  return `${yyyy}-${MM}-${dd}T${time}`;
}

function getTimestampFromString(value: string): Timestamp {
  const date = new Date(value);
  if (isNaN(date.valueOf())) {
    return Timestamp.now();
  }

  return Timestamp.fromDate(date);
}
