import type { FC } from 'react';
import { useMemo, useState } from 'react';

import type { Event, Totals } from '@gb/common';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';

import LoadingIndicator from '../../base/LoadingIndicator';
import ManageAppBar from '../../components/manage/ManageAppBar';
import ManageDrawer from '../../components/manage/ManageDrawer';
import { useAuthContext } from '../../context/AuthContext';
import useIsDesktop from '../../hooks/useIsDesktop';
import useSubscribeEvent from '../../hooks/useSubscribeEvent';
import useSubscribeTotals from '../../hooks/useSubscribeTotals';
import NotFound from '../NotFound';

interface OutletContext {
  event: Event | undefined;
  totals: Totals | undefined;
}

const ManageEvent: FC = () => {
  const { eventId = '' } = useParams();
  const { event, isLoading, hasError } = useSubscribeEvent(eventId);
  const { totals } = useSubscribeTotals(eventId);
  const { isAdmin } = useAuthContext();

  const hasCover = useMemo(() => !!event?.cover, [event]);

  const outletContext = useMemo(
    (): OutletContext => ({ event, totals }),
    [event, totals],
  );

  const isDesktop = useIsDesktop();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  function openDrawer() {
    setIsDrawerOpen(true);
  }

  function closeDrawer() {
    setIsDrawerOpen(false);
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (hasError) {
    return <NotFound />;
  }

  return (
    <ManageDrawer
      isOpen={isDrawerOpen}
      onClose={closeDrawer}
      isPermanent={isDesktop}
      eventId={eventId}
      goalCents={event?.goalCents}
      totals={totals}
      isAdmin={isAdmin}
      hasCover={hasCover}
    >
      <ManageAppBar
        isDesktop={isDesktop}
        openDrawer={openDrawer}
        eventName={event?.name || ''}
      />
      <Outlet context={outletContext} />
    </ManageDrawer>
  );
};

export default ManageEvent;

export function useManageContext(): OutletContext {
  return useOutletContext<OutletContext>();
}
