"use strict";
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
exports.__esModule = true;
exports.getRefPath = void 0;
exports.getRefPath = {
    // firestore
    eventsCollection: function () { return 'events'; },
    event: function (_a) {
        var eventId = _a.eventId;
        return "events/".concat(eventId);
    },
    donationsCollection: function () { return 'donations'; },
    donation: function (_a) {
        var donationId = _a.donationId;
        return "donations/".concat(donationId);
    },
    donorsCollection: function () { return 'donors'; },
    donor: function (_a) {
        var donationId = _a.donationId;
        return "donors/".concat(donationId);
    },
    bartendersCollection: function () { return 'bartenders'; },
    bartender: function (_a) {
        var bartenderId = _a.bartenderId;
        return "bartenders/".concat(bartenderId);
    },
    organizersCollection: function () { return 'organizers'; },
    organizer: function (_a) {
        var organizerId = _a.organizerId;
        return "organizers/".concat(organizerId);
    },
    adminsCollection: function () { return 'admins'; },
    admin: function (_a) {
        var uid = _a.uid;
        return "admins/".concat(uid);
    },
    // real-time database
    totals: function (_a) {
        var eventId = _a.eventId;
        return "totals/".concat(eventId);
    }
};
