import type { FC } from 'react';
import { useMemo } from 'react';

import type { Event } from '@gb/common';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import type { FormControlProps } from '@mui/material';
import { FormControl, FormHelperText, MenuItem, Select } from '@mui/material';

import { getSortedBartenders } from '../utils/getSortedBartenders';

interface Props {
  bartenders: Event['bartenders'];
  bartenderId: string | undefined;
  setBartenderId: (value: string) => void;
  label: string;
  isLocked?: boolean;
  helperText?: string;
  isFullWidth?: boolean;
  margin?: FormControlProps['margin'];
}

const SelectBartender: FC<Props> = ({
  bartenders,
  bartenderId = '',
  setBartenderId,
  label,
  isLocked = false,
  helperText,
  isFullWidth = true,
  margin = 'normal',
}) => {
  const sortedBartenders = useMemo(
    () => getSortedBartenders(bartenders),
    [bartenders],
  );

  return (
    <FormControl fullWidth={isFullWidth} margin={margin}>
      <Select
        displayEmpty
        variant='outlined'
        inputProps={{ 'aria-label': label }}
        value={bartenderId}
        onChange={(e) => setBartenderId(e.target.value)}
        readOnly={isLocked}
        IconComponent={isLocked ? () => null : ArrowDropDownIcon}
      >
        <MenuItem value=''>Everyone</MenuItem>
        {sortedBartenders.map(([id, name]) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SelectBartender;
