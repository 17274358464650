import { useCallback, useEffect, useMemo, useState } from 'react';

interface Hook<T> {
  items: T[];
  loadMore: () => void;
  hasMore: boolean;
  isLoading: boolean;
}

const LIMIT = 20;

export default function useLoadList<T>(
  getItems: (limit: number, startAfter?: T) => Promise<T[]>,
  limit = LIMIT,
): Hook<T> {
  const [items, setItems] = useState<T[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const startAfter = useMemo(
    (): T | undefined => items[items.length - 1],
    [items],
  );

  useEffect(() => {
    setIsLoading(true);
    getItems(limit + 1)
      .then((nextItems) => {
        setHasMore(nextItems.length > limit);
        setItems(nextItems.slice(0, limit));
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  }, [getItems, limit]);

  const loadMore = useCallback(() => {
    if (isLoading || !hasMore) {
      return;
    }
    setIsLoading(true);
    getItems(limit + 1, startAfter)
      .then((nextItems) => {
        setHasMore(nextItems.length > limit);
        setItems((prevItems) => [...prevItems, ...nextItems.slice(0, limit)]);
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  }, [isLoading, hasMore, getItems, limit, startAfter]);

  return { items, hasMore, isLoading, loadMore };
}
