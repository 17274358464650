import { useCallback, useEffect, useMemo, useState } from 'react';

import type { AdminFinanceItem } from '@gb/common';

import api from '../../api';
import { getPlatformFeeCents } from '../../utils/getPlatformFeeCents';

interface Hook {
  isLoading: boolean;
  hasError: boolean;
  items: AdminFinanceItem[];
  totals: {
    totalCents: number;
    netPlatformCostCents: number;
    grandTotalCents: number;
    donorCount: number;
  };
  updatedAt: Date;
  onRefresh: () => void;
}

export default function useAdminFinanceReport(eventLimit: number): Hook {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [items, setItems] = useState<AdminFinanceItem[]>([]);

  const loadItems = useCallback(() => {
    setIsLoading(true);
    setHasError(false);

    api.reports
      .getAdminFinanceReport(eventLimit)
      .then(setItems)
      .catch((err) => {
        console.error(err);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [eventLimit]);

  const onRefresh = useCallback(() => {
    if (isLoading) {
      return;
    }
    loadItems();
  }, [isLoading, loadItems]);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  const totals = useMemo(() => {
    const { totalCents, platformCents, platformFeeCents, donorCount } =
      getTotalSum(items);
    const netPlatformCostCents = platformFeeCents - platformCents;
    const grandTotalCents = totalCents - netPlatformCostCents;

    return { totalCents, netPlatformCostCents, grandTotalCents, donorCount };
  }, [items]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updatedAt = useMemo(() => new Date(), [items]);

  return { isLoading, hasError, items, totals, updatedAt, onRefresh };
}

function getTotalSum(items: Hook['items']) {
  return items.reduce<{
    totalCents: number;
    platformCents: number;
    platformFeeCents: number;
    donorCount: number;
  }>(
    (acc, curr) => {
      const { totalCents, platformCents, platformRate, donorCount } = curr;
      const platformFeeCents = getPlatformFeeCents({
        totalCents,
        platformRate,
      });

      return {
        totalCents: acc.totalCents + totalCents,
        platformCents: acc.platformCents + platformCents,
        platformFeeCents: acc.platformFeeCents + platformFeeCents,
        donorCount: acc.donorCount + donorCount,
      };
    },
    {
      totalCents: 0,
      platformCents: 0,
      platformFeeCents: 0,
      donorCount: 0,
    },
  );
}
