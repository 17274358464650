"use strict";
exports.__esModule = true;
exports.getStorageBucket = exports.getRtdbUrl = void 0;
function getRtdbUrl() {
    return 'https://prod-guest-bartender-default-rtdb.firebaseio.com';
}
exports.getRtdbUrl = getRtdbUrl;
function getStorageBucket() {
    return 'prod-guest-bartender.appspot.com';
}
exports.getStorageBucket = getStorageBucket;
