import { useMemo } from 'react';

import type { To } from 'react-router-dom';
import { useLocation, useResolvedPath } from 'react-router-dom';

/**
 * Check whether a given to is the active route
 * @param to React Router "to" element to check
 * @returns true if the given "to" is the active route
 */
export default function useIsActivePath(to: To | undefined): boolean {
  const location = useLocation();

  const resolved = useResolvedPath(to || '');

  const isActive = useMemo(() => {
    if (to === undefined) {
      return false;
    }

    return resolved.pathname === location.pathname;
  }, [to, location, resolved]);

  return isActive;
}
