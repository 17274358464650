import type { CreateAdminData } from '@gb/common';
import { httpsCallable } from 'firebase/functions';

import { cloudFunctions } from '../firebase';

const callable = httpsCallable(cloudFunctions, 'people-admin-create');

export default async function create(data: CreateAdminData): Promise<void> {
  await callable(data);
}
