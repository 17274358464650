import type { FC, ReactNode } from 'react';

import type { SnackbarOrigin } from '@mui/material';
import { Alert, Snackbar } from '@mui/material';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  autoHideDuration?: number | null;
  anchorOrigin?: SnackbarOrigin;
  children: ReactNode;
}

const SnackAlert: FC<Props> = ({
  isOpen,
  onClose,
  autoHideDuration = 3000,
  anchorOrigin = { vertical: 'bottom', horizontal: 'right' },
  children,
}) => {
  return (
    <Snackbar
      open={isOpen}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      anchorOrigin={anchorOrigin}
    >
      <Alert severity='success' onClose={onClose}>
        {children}
      </Alert>
    </Snackbar>
  );
};

export default SnackAlert;
