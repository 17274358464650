import type { FC } from 'react';
import { useMemo } from 'react';

import type { Event, Totals } from '@gb/common';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

import CdnImage from '../../base/CdnImage';
import DonateTotal from '../../base/DonateTotal';
import { getDaysLeft } from '../../utils/getDaysLeft';
import getPath from '../../utils/getPath';
import ShareIcons from '../common/ShareIcons';
import EventTitle from './EventTitle';

interface Props {
  event: Event | undefined;
  bartenderName?: string;
  totals?: Totals | undefined;
  donateUrl: string;
  canManage: boolean;
  isLoading?: boolean;
}

const EventHeader: FC<Props> = ({
  event,
  totals,
  bartenderName,
  donateUrl,
  canManage,
  isLoading = true,
}) => {
  const {
    eventId = '',
    name: eventName = '',
    canDonate,
    tagline,
    cover,
    startsAt,
    goalCents,
  } = event || {};
  const isBartender = !!bartenderName;

  const { donorCount = 0 } = totals || {};

  const daysLeft = useMemo(() => {
    if (!startsAt) {
      return undefined;
    }

    return getDaysLeft(startsAt);
  }, [startsAt]);

  return (
    <div>
      {canManage && (
        <Box displayPrint='none'>
          <Container sx={{ pt: 3 }}>
            <Alert
              severity='info'
              action={
                <Button
                  component={Link}
                  to={getPath.manage({ eventId })}
                  color='inherit'
                >
                  Manage
                </Button>
              }
              sx={{ alignItems: 'center' }}
            >
              <AlertTitle>Event Organizer</AlertTitle>
              You can manage this event
            </Alert>
          </Container>
        </Box>
      )}
      <EventTitle
        name={isBartender ? `Fundraiser by ${bartenderName}` : eventName}
        tagline={tagline}
        isLoading={isLoading}
      />
      <Container maxWidth='lg'>
        <Grid container spacing={4} justifyContent='center'>
          <Grid item xs={12} sm={10} md={8}>
            <CdnImage aspectRatio='16:9' src={cover} isLoading={isLoading} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            md={4}
            display='flex'
            flexDirection='column'
          >
            {isLoading ? (
              <LoadingSection />
            ) : (
              <>
                {isBartender && (
                  <>
                    <Typography variant='h6' sx={{ mb: 1 }}>
                      {eventName}
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                  </>
                )}
                <DonateTotal
                  goalCents={!isBartender ? goalCents : undefined}
                  totals={totals}
                />
                {!isBartender && (
                  <>
                    <Typography variant='h5' color='primary'>
                      {donorCount}
                    </Typography>
                    <Typography variant='body2' paragraph>
                      {donorCount === 1 ? 'supporter' : 'supporters'}
                    </Typography>
                  </>
                )}
                {daysLeft !== undefined && (
                  <>
                    <Typography variant='h5' color='primary'>
                      {daysLeft}
                    </Typography>
                    <Typography variant='body2' paragraph>
                      {daysLeft === 1 ? 'day' : 'days'} to go
                    </Typography>
                  </>
                )}
                <Box displayPrint='none' sx={{ mb: { xs: 2 } }}>
                  <Box sx={{ mt: 2, mb: 3 }}>
                    {canDonate ? (
                      <Button
                        component={Link}
                        to={donateUrl}
                        variant='contained'
                        color='secondary'
                        size='large'
                        fullWidth
                      >
                        Donate
                      </Button>
                    ) : (
                      <Typography variant='body1' color='GrayText'>
                        This event is not currently accepting donations
                      </Typography>
                    )}
                  </Box>
                  <ShareIcons
                    url={window.location.href}
                    eventName={eventName}
                  />
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default EventHeader;

const LoadingSection: FC = () => (
  <>
    <Skeleton width='100%' height={8} sx={{ marginBottom: '2px' }} />
    {Array.from(Array(3).keys()).map((i) => (
      <Box key={`item-${i}`}>
        <Typography variant='h5'>
          <Skeleton />
        </Typography>
        <Typography variant='body2' paragraph>
          <Skeleton />
        </Typography>
      </Box>
    ))}
  </>
);
