import type { FC, ReactNode } from 'react';

import type { Breakpoint } from '@mui/material';
import { Container, Typography } from '@mui/material';

import MetaTags from '../../base/MetaTags';
import ManageFooter from './ManageFooter';

interface Props {
  title: string;
  maxWidth?: Breakpoint;
  children?: ReactNode;
}

const ManageSection: FC<Props> = ({ title, maxWidth = 'sm', children }) => {
  return (
    <>
      <MetaTags title={title} />
      <Container maxWidth='lg' sx={{ mb: 3 }}>
        <Typography variant='h4'>{title}</Typography>
      </Container>
      <Container maxWidth={maxWidth}>{children}</Container>
      <ManageFooter />
    </>
  );
};

export default ManageSection;
