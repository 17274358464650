import type { FC } from 'react';
import { useMemo } from 'react';

import type { Totals } from '@gb/common';
import { Money } from '@gb/common';
import { Box, LinearProgress, Typography } from '@mui/material';
import type { SystemCssProperties } from '@mui/system';

interface Props {
  totals: Totals | undefined;
  goalCents?: number | undefined;
  barHeight?: number;
  barMargin?: SystemCssProperties['mb'];
}

const DonateTotal: FC<Props> = ({
  totals,
  goalCents = 0,
  barHeight = 8,
  barMargin = 2,
}) => {
  const { totalCents = 0, offlineCents = 0 } = totals || {};

  const grandTotal = useMemo(
    () => totalCents + offlineCents,
    [totalCents, offlineCents],
  );

  const formattedTotal =
    '$' + new Money(grandTotal).toString({ showCommas: true });

  return (
    <Box>
      {!!goalCents && (
        <LinearProgress
          variant='determinate'
          value={getProgress({ totalCents: grandTotal, goalCents })}
          color='secondary'
          sx={{ height: barHeight, mb: barMargin }}
        />
      )}
      <Typography variant='h5' color='secondary.dark'>
        {formattedTotal}
      </Typography>
      <Typography variant='body2' paragraph>
        donated
        {!!goalCents &&
          ` of $${new Money(goalCents).toString({
            showCommas: true,
          })} goal`}
      </Typography>
    </Box>
  );
};

export default DonateTotal;

const MAX_PROGRESS = 100;

function getProgress({
  totalCents,
  goalCents,
}: {
  totalCents: number;
  goalCents: number;
}): number {
  if (!totalCents || !goalCents) {
    return 0;
  }
  const progress = (totalCents / goalCents) * 100;
  if (progress > MAX_PROGRESS) {
    return MAX_PROGRESS;
  }

  return progress;
}
