import type { FC, ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import type { Event } from '@gb/common';
import { Timestamp } from 'firebase/firestore';

import api from '../api';

type EventData = Omit<Event, 'eventId'>;

interface Value {
  event: EventData;
  set: <K extends keyof EventData>(key: K, value: EventData[K]) => void;
  onSubmit: (onSuccess?: (eventId: string) => void) => void;
  isLoading: boolean;
}

const DEFAULT_EVENT: EventData = {
  name: '',
  tagline: '',
  description: '',
  isActive: true,
  canDonate: true,
  causeName: '',
  startsAt: Timestamp.now(),
  endsAt: Timestamp.now(),
  facebookUrl: '',
  isVirtual: false,
  venue: 'BBC Tavern and Grill',
  addressStreet: '4019 Kennett Pike',
  addressCity: 'Greenville',
  addressState: 'DE',
  addressZip: '19807',
  platformRate: 0.15,
  goalCents: 15000 * 100,
};

const createEventContext = createContext<Value>({
  event: DEFAULT_EVENT,
  set: () => undefined,
  onSubmit: () => undefined,
  isLoading: false,
});

export function useCreateEventContext(): Value {
  return useContext(createEventContext);
}

interface Props {
  children: ReactNode;
}

export const CreateEventContextProvider: FC<Props> = ({ children }) => {
  const [event, setEvent] = useState<EventData>(DEFAULT_EVENT);
  const [isLoading, setIsLoading] = useState(false);

  const set = useCallback(
    <K extends keyof EventData>(key: K, value: EventData[K]) => {
      setEvent((prev) => ({ ...prev, [key]: value }));
    },
    [],
  );

  const onSubmit = useCallback(
    (onSuccess?: (eventId: string) => void) => {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      api.event
        .create(event)
        .then(onSuccess)
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    },
    [event, isLoading],
  );

  const value = useMemo(
    (): Value => ({ event, set, onSubmit, isLoading }),
    [event, set, onSubmit, isLoading],
  );

  return (
    <createEventContext.Provider value={value}>
      {children}
    </createEventContext.Provider>
  );
};
