import type { BartenderMessageData } from '@gb/common';
import { httpsCallable } from 'firebase/functions';

import { cloudFunctions } from '../firebase';

const callable = httpsCallable<BartenderMessageData, undefined>(
  cloudFunctions,
  'people-sendMessage',
);

/**
 * Send a message to all bartenders and organizers for an event
 * @param data eventId and message fields
 */
export default async function sendMessage(
  data: BartenderMessageData,
): Promise<void> {
  await callable(data);
}
