import type { FC } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import type { IconButtonProps } from '@mui/material';
import { IconButton } from '@mui/material';

import CopyElement from './CopyElement';

interface Props extends IconButtonProps {
  value: string;
}

const CopyIconButton: FC<Props> = ({
  value,
  size = 'medium',
  ...iconButtonProps
}) => {
  return (
    <CopyElement
      value={value}
      render={(onClick) => (
        <IconButton onClick={onClick} size={size} {...iconButtonProps}>
          <ContentCopyIcon fontSize='inherit' />
        </IconButton>
      )}
    />
  );
};

export default CopyIconButton;
