import type { Bartender } from '@gb/common';
import type { Timestamp } from 'firebase/firestore';
import {
  getDocs,
  limit,
  query,
  serverTimestamp,
  setDoc,
  where,
} from 'firebase/firestore';

import { getEventCollectionRef } from '../event/refs';
import { getUniqueId } from '../utils/getUniqueId';
import { getBartenderCollectionRef, getBartenderRef } from './refs';

const MIN_ID_LENGTH = 6;

export default async function create(
  data: Omit<Bartender, 'bartenderId' | 'createdAt'>,
): Promise<string> {
  const bartenderId = await getUniqueId(
    getBartenderCollectionRef(),
    getExists,
    MIN_ID_LENGTH,
  );
  const ref = getBartenderRef(bartenderId);

  // @ts-expect-error resolves to Timestamp
  const createdAt: Timestamp = serverTimestamp();

  await setDoc(ref, { ...data, bartenderId, createdAt });

  return bartenderId;
}

export async function getExists(id: string): Promise<boolean> {
  const q = query(
    getEventCollectionRef(),
    where('bartenderIds', 'array-contains', id),
    limit(1),
  );
  const docs = await getDocs(q);

  return !docs.empty;
}
