import type { FC } from 'react';

import type { Event } from '@gb/common';
import { Box, Grid, Typography } from '@mui/material';

import CdnImage from '../../base/CdnImage';
import LinkifyText from '../../base/LinkifyText';
import EventSection from './EventSection';

interface Props {
  event: Event | undefined;
}

const TabAbout: FC<Props> = ({ event }) => {
  if (!event) {
    return null;
  }

  const {
    causeName = event.name,
    description,
    logo,
    causeUrl,
    causeTaxId,
  } = event;

  return (
    <EventSection id='about' title='About the Cause'>
      <Grid container columnSpacing={4}>
        <Grid item xs={12} sm={7} md={8} sx={{ mb: 2 }}>
          <Typography
            variant='subtitle1'
            paragraph
            sx={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}
          >
            <LinkifyText content={description} />
          </Typography>
        </Grid>
        <Grid item md={1} display={{ xs: 'none', md: 'block' }} />
        <Grid item xs={12} sm={5} md={3}>
          {!!logo && (
            <CdnImage
              src={logo}
              maxSize='sm'
              mb={1}
              pr={{ xs: '33%', sm: 0 }}
            />
          )}
          <Box mb={1}>
            <Typography variant='button' color='grey.600'>
              Organization
            </Typography>
            <Typography variant='body2'>{causeName}</Typography>
          </Box>
          {!!causeUrl && (
            <Box mb={1}>
              <Typography variant='button' color='grey.600'>
                Website
              </Typography>
              <Typography
                variant='body2'
                sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
              >
                <LinkifyText content={causeUrl} />
              </Typography>
            </Box>
          )}
          {!!causeTaxId && (
            <Box mb={1}>
              <Typography variant='button' color='grey.600'>
                Tax Id
              </Typography>
              <Typography variant='body2'>{causeTaxId}</Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </EventSection>
  );
};

export default TabAbout;
