import { useEffect, useState } from 'react';

import type { Bartender } from '@gb/common';

import api from '../api';

interface Hook {
  bartenders: Bartender[];
  isLoading: boolean;
}

export default function useSubscribeListBartenders(eventId: string): Hook {
  const [bartenders, setBartenders] = useState<Bartender[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    return api.bartender.subscribeList(
      eventId,
      (data) => {
        setBartenders(data);
        setIsLoading(false);
      },
      (err) => {
        console.error(err);
        setBartenders([]);
        setIsLoading(false);
      },
    );
  }, [eventId]);

  return { bartenders, isLoading };
}
