import type { Event } from '@gb/common';
import { setDoc } from 'firebase/firestore';

import { getEventRef } from './refs';

export default async function update(
  eventId: string,
  data: Partial<Event>,
): Promise<void> {
  const ref = getEventRef(eventId);

  await setDoc(ref, data, { merge: true });
}
