import type { FC } from 'react';
import { useState } from 'react';

import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'next-share';

import QrDialog from './QrDialog';

interface Props {
  url: string;
  eventName: string;
  headline?: string;
}

const ShareIcons: FC<Props> = ({
  url,
  eventName,
  headline = 'Share this Fundraiser',
}) => {
  const [isQrOpen, setIsQrOpen] = useState(false);

  return (
    <Box mt='auto'>
      <Typography variant='button'>{headline}</Typography>
      <Stack spacing={1} direction='row'>
        <FacebookShareButton url={url}>
          <IconButton component='div' edge='start'>
            <FacebookIcon />
          </IconButton>
        </FacebookShareButton>
        <TwitterShareButton
          url={url}
          title={`Guest Bartender Program: donate to ${eventName}`}
        >
          <IconButton component='div'>
            <TwitterIcon />
          </IconButton>
        </TwitterShareButton>
        <LinkedinShareButton
          url={url}
          title={`Guest Bartender Program: donate to ${eventName}`}
        >
          <IconButton component='div'>
            <LinkedInIcon />
          </IconButton>
        </LinkedinShareButton>
        <EmailShareButton
          url={url}
          subject={eventName}
          body='Here is a link to the Guest Bartender fundraiser:'
        >
          <IconButton component='div'>
            <EmailIcon />
          </IconButton>
        </EmailShareButton>
        <IconButton onClick={() => setIsQrOpen(true)}>
          <QrCode2Icon />
        </IconButton>
      </Stack>
      <QrDialog
        isOpen={isQrOpen}
        onClose={() => setIsQrOpen(false)}
        href={url}
        title={eventName}
      />
    </Box>
  );
};

export default ShareIcons;
