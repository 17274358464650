"use strict";
exports.__esModule = true;
exports.stripeTimeToTimestamp = exports.stripeTimeToDate = void 0;
var firestore_1 = require("firebase/firestore");
var MILLIS_PER_SECOND = 1000;
/**
 * Convert Stripe timestamp to a Date
 * @param seconds time in seconds since epoch
 * @returns Javascript Date
 */
function stripeTimeToDate(seconds) {
    var millis = seconds * MILLIS_PER_SECOND;
    return new Date(millis);
}
exports.stripeTimeToDate = stripeTimeToDate;
/**
 * Convert Stripe timestamp to a Firestore Timestamp
 * @param seconds time in seconds since epoch
 * @returns Firestore Timestamp
 */
function stripeTimeToTimestamp(seconds) {
    return firestore_1.Timestamp.fromMillis(seconds * MILLIS_PER_SECOND);
}
exports.stripeTimeToTimestamp = stripeTimeToTimestamp;
