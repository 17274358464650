import type { FC } from 'react';

import type { Event, Totals } from '@gb/common';
import { Money } from '@gb/common';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';

import useLeaderboard from '../../hooks/useLeaderboard';

const AVATAR_SIZE = 28;

interface Props {
  event: Event | undefined;
  totals: Totals | undefined;
}

const Leaderboard: FC<Props> = ({ event, totals }) => {
  const { leaders } = useLeaderboard(event, totals);

  if (!leaders.length) {
    return (
      <Box py={1}>
        <Typography variant='subtitle1'>
          Fundraising leaderboard will be available after the first donations
          are received. Check back soon!
        </Typography>
      </Box>
    );
  }

  return (
    <List>
      {leaders.map((leader) => {
        const { bartenderId, name, totalCents, place } = leader;

        const formattedAmount = '$' + new Money(totalCents).toString();

        const isLeader = !!totalCents && place <= 2;

        return (
          <ListItem key={`leader-${bartenderId}`} disableGutters divider>
            <ListItemAvatar>
              <Avatar
                sx={{
                  fontSize: AVATAR_SIZE / 2,
                  width: AVATAR_SIZE,
                  height: AVATAR_SIZE,
                  bgcolor: totalCents ? 'primary.main' : 'grey.400',
                }}
              >
                {totalCents ? place + 1 : ' '}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Stack direction='row' alignItems='center'>
                  {isLeader && (
                    <WorkspacePremiumIcon
                      htmlColor={getMedalColor(place)}
                      fontSize='small'
                      sx={{ mr: 1 }}
                    />
                  )}
                  {name}
                </Stack>
              }
            />
            <Typography
              variant='body1'
              sx={{
                pl: 1,
                color: totalCents ? 'secondary.main' : 'grey.400',
                fontWeight: 'bold',
              }}
            >
              {formattedAmount}
            </Typography>
          </ListItem>
        );
      })}
    </List>
  );
};

export default Leaderboard;

function getMedalColor(i: number): string {
  switch (i) {
    case 0:
      return 'goldenrod';
    case 1:
      return 'lightsteelblue';
    case 2:
      return 'tan';
    default:
      return 'inherit';
  }
}
