import type { FC } from 'react';

import { Box } from '@mui/material';

import CopyrightNotice from '../../base/CopyrightNotice';

const ManageFooter: FC = () => {
  return (
    <Box mt='auto'>
      <CopyrightNotice color='grey.500' boxProps={{ sx: { py: 2 } }} />
    </Box>
  );
};

export default ManageFooter;
