import { deleteDoc } from 'firebase/firestore';

import donation from '../donation';
import { getEventRef } from './refs';

export default async function remove(eventId: string): Promise<void> {
  const totals = await donation.getTotals(eventId);
  if (totals?.totalCents) {
    throw new Error('Events cannot be deleted once donations start');
  }

  const ref = getEventRef(eventId);

  await deleteDoc(ref);
}
