"use strict";
exports.__esModule = true;
exports.Money = void 0;
var Money = /** @class */ (function () {
    function Money(cents) {
        this.cents = cents;
    }
    Money.prototype.getCents = function () {
        return this.cents;
    };
    Money.prototype.getDollars = function () {
        return Math.trunc(this.cents) / 100;
    };
    Money.prototype.toString = function (_a) {
        var _b = _a === void 0 ? {} : _a, _c = _b.showCommas, showCommas = _c === void 0 ? true : _c;
        var dollars = this.getDollars();
        var hasCents = Math.trunc(dollars) !== dollars;
        var str = dollars.toFixed(hasCents ? 2 : 0);
        if (!showCommas) {
            return str;
        }
        return numberWithCommas(str);
    };
    Money.fromCents = function (cents) {
        return new Money(cents);
    };
    Money.fromDollars = function (dollars) {
        return new Money(Math.trunc(dollars * 100));
    };
    Money.fromString = function (input) {
        var dollars = Number(input.trim().replace(/[^0-9.,]/g, ''));
        return Money.fromDollars(dollars);
    };
    return Money;
}());
exports.Money = Money;
function numberWithCommas(n) {
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
