import type { FC } from 'react';

import {
  Box,
  FormControlLabel,
  FormHelperText,
  Stack,
  Switch,
} from '@mui/material';
import type { Timestamp } from 'firebase/firestore';

import DateField from '../base/DateField';
import SelectState from '../base/SelectState';
import TextField from '../base/TextField';
import FormBase from './FormBase';

interface Props {
  startsAt: Timestamp;
  setStartsAt: (value: Timestamp) => void;
  endsAt: Timestamp;
  setEndsAt: (value: Timestamp) => void;
  venue: string | undefined;
  setVenue: (value: string) => void;
  addressStreet: string | undefined;
  setAddressStreet: (value: string) => void;
  addressCity: string | undefined;
  setAddressCity: (value: string) => void;
  addressState: string | undefined;
  setAddressState: (value: string) => void;
  addressZip: string | undefined;
  setAddressZip: (value: string) => void;
  isVirtual: boolean;
  setIsVirtual: (value: boolean) => void;
  facebookUrl: string;
  setFacebookUrl: (value: string) => void;
  onSubmit: () => void;
  submitLabel: string;
  onCancel?: () => void;
  cancelLabel?: string;
  isLoading?: boolean;
}

const DetailsForm: FC<Props> = ({
  startsAt,
  setStartsAt,
  endsAt,
  setEndsAt,
  venue,
  setVenue,
  addressStreet,
  setAddressStreet,
  addressCity,
  setAddressCity,
  addressState,
  setAddressState,
  addressZip,
  setAddressZip,
  isVirtual,
  setIsVirtual,
  facebookUrl,
  setFacebookUrl,
  onSubmit,
  submitLabel,
  onCancel,
  cancelLabel = 'Cancel',
  isLoading,
}) => {
  return (
    <FormBase
      onSubmit={onSubmit}
      submitLabel={submitLabel}
      onCancel={onCancel}
      cancelLabel={cancelLabel}
      isLoading={isLoading}
    >
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 0, sm: 2 }}>
        <Box sx={{ width: '100%' }}>
          <DateField
            label='Start time'
            value={startsAt}
            onChange={setStartsAt}
            required
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          <DateField
            label='End time'
            value={endsAt}
            onChange={setEndsAt}
            required
          />
        </Box>
      </Stack>
      <TextField
        label='Facebook link'
        value={facebookUrl}
        onChangeText={setFacebookUrl}
        required
      />
      <FormHelperText>Is the event in-person or virtual?</FormHelperText>
      <FormControlLabel
        control={
          <Switch
            color='primary'
            checked={!isVirtual}
            onChange={(_e, checked) => setIsVirtual(!checked)}
          />
        }
        label={isVirtual ? 'Virtual event (online only)' : 'In-person event'}
        labelPlacement='end'
      />
      {!isVirtual && (
        <>
          <TextField
            label='Venue name'
            value={venue}
            onChangeText={setVenue}
            required
          />
          <TextField
            label='Street'
            value={addressStreet}
            onChangeText={setAddressStreet}
            required
          />
          <TextField
            label='City'
            value={addressCity}
            onChangeText={setAddressCity}
            required
          />
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 0, sm: 2 }}
          >
            <Box sx={{ width: '100%' }}>
              <SelectState
                value={addressState || ''}
                onChange={setAddressState}
                formControlProps={{ required: true }}
              />
            </Box>
            <Box sx={{ width: '100%' }}>
              <TextField
                label='Zip'
                value={addressZip}
                onChangeText={setAddressZip}
                required
              />
            </Box>
          </Stack>
        </>
      )}
    </FormBase>
  );
};

export default DetailsForm;
