import type { FC } from 'react';
import { useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  ListSubheader,
  Menu,
  MenuItem,
  MenuList,
  Toolbar,
} from '@mui/material';
import { Link } from 'react-router-dom';

import AvatarMenu from '../../base/AvatarMenu';
import getPath from '../../utils/getPath';

interface Props {
  isDesktop: boolean;
  openDrawer: () => void;
  eventName: string;
}

const ManageAppBar: FC<Props> = ({ isDesktop, openDrawer, eventName }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = !!anchorEl;
  const onClickMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position='sticky'
      color={isDesktop ? 'inherit' : 'primary'}
      elevation={0}
      sx={{ mb: isDesktop ? 0 : 1 }}
    >
      <Toolbar>
        {!isDesktop && (
          <IconButton
            onClick={openDrawer}
            size='large'
            edge='start'
            color='inherit'
          >
            <MenuIcon />
          </IconButton>
        )}
        {isDesktop ? (
          <Button
            onClick={onClickMenu}
            id='event-popup-button'
            aria-controls={isMenuOpen ? 'event-popup-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={isMenuOpen ? 'true' : undefined}
            endIcon={<ArrowDropDownIcon />}
          >
            {eventName}
          </Button>
        ) : (
          <IconButton
            onClick={onClickMenu}
            color='inherit'
            sx={{ ml: 'auto' }}
            id='event-popup-button'
            aria-controls={isMenuOpen ? 'event-popup-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={isMenuOpen ? 'true' : undefined}
          >
            <MoreVertIcon />
          </IconButton>
        )}
        <Menu
          id='event-popup-menu'
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={closeMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuList sx={{ minWidth: 280, maxWidth: '100%' }}>
            <MenuItem divider component={Link} to={getPath.manageList()}>
              See all events
            </MenuItem>
            <ListSubheader>Selected event</ListSubheader>
            <MenuItem selected>{eventName}</MenuItem>
          </MenuList>
        </Menu>
        <Box ml={isDesktop ? 'auto' : 0}>
          <AvatarMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default ManageAppBar;
