import type { FC, ReactNode } from 'react';

import { AppBar, Box, Button, Stack, Toolbar } from '@mui/material';
import { Container } from '@mui/system';
import { Link } from 'react-router-dom';

import type { EventSectionId } from './EventSection';

interface Props {
  donateUrl: string;
  showDonateButton: boolean;
  children: ReactNode;
}

const EventTabBar: FC<Props> = ({ donateUrl, showDonateButton, children }) => {
  return (
    <AppBar
      position='sticky'
      color='inherit'
      variant='outlined'
      elevation={0}
      sx={{ borderLeftWidth: 0, borderRightWidth: 0 }}
    >
      <Box displayPrint='none'>
        <Toolbar>
          <Container
            maxWidth='lg'
            sx={{ display: 'flex', alignItems: 'center' }}
            disableGutters={!showDonateButton}
          >
            <Stack spacing={{ xs: 0, md: 1 }} direction='row'>
              {children}
            </Stack>
            {showDonateButton && (
              <Box ml='auto'>
                <Button
                  variant='contained'
                  color='secondary'
                  component={Link}
                  to={donateUrl}
                >
                  Donate
                </Button>
              </Box>
            )}
          </Container>
        </Toolbar>
      </Box>
    </AppBar>
  );
};

export default EventTabBar;

interface TabBarItemProps {
  label: string;
  id: EventSectionId;
}

export const TabBarItem: FC<TabBarItemProps> = ({ label, id }) => {
  function onClick() {
    const el = document.getElementById(id);
    if (!el) {
      return;
    }
    el.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <Button key={id} onClick={onClick}>
      {label}
    </Button>
  );
};
