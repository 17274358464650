"use strict";
exports.__esModule = true;
exports.isLegacyCharge = exports.isCharge = void 0;
var isCharge = function (charge) {
    return charge.metadata.apiVersion === 'v3.0';
};
exports.isCharge = isCharge;
var isLegacyCharge = function (charge) {
    return charge.metadata.gb_api_version === 'v2.1.1';
};
exports.isLegacyCharge = isLegacyCharge;
