import type { Donation, GetChargeData, GetChargeReponse } from '@gb/common';
import { Timestamp } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

import { cloudFunctions } from '../firebase';

const getChargeCallable = httpsCallable(cloudFunctions, 'charge-get');

export async function getFromSessionId(data: GetChargeData): Promise<Donation> {
  const response = await getChargeCallable(data);

  const { donation, createdAtMillis } = response.data as GetChargeReponse;

  return { ...donation, createdAt: Timestamp.fromMillis(createdAtMillis) };
}
