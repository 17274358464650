import type { FC } from 'react';
import { useMemo } from 'react';

import type { Event } from '@gb/common';
import GroupsIcon from '@mui/icons-material/Groups';
import {
  Box,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

import AvatarLetter from '../../base/AvatarLetter';
import useIsDesktop from '../../hooks/useIsDesktop';
import getPath from '../../utils/getPath';
import { getSortedBartenders } from '../../utils/getSortedBartenders';
import EventSection from './EventSection';

interface Props {
  event: Event | undefined;
}

const TabBartenders: FC<Props> = ({ event }) => {
  const sortedBartenders = useMemo(
    () => getSortedBartenders(event?.bartenders),
    [event],
  );

  const isDesktop = useIsDesktop();

  if (!event) {
    return null;
  }

  return (
    <EventSection id='bartenders' title='Guest Bartenders'>
      <Grid container columnSpacing={2} alignItems='center'>
        {sortedBartenders.map(([bartenderId, name]) => {
          const bartenderUrl = getPath.bartender({ bartenderId });

          return (
            <Grid key={`bartender-${bartenderId}`} item xs={6}>
              <ListItem disablePadding dense={!isDesktop}>
                <ListItemButton
                  disableGutters={!isDesktop}
                  component={Link}
                  to={bartenderUrl}
                >
                  {isDesktop && (
                    <ListItemAvatar>
                      <AvatarLetter name={name} />
                    </ListItemAvatar>
                  )}
                  <ListItemText primary={name} />
                </ListItemButton>
              </ListItem>
            </Grid>
          );
        })}
      </Grid>
      {!sortedBartenders.length && (
        <Box py={5} display='flex' flexDirection='column' alignItems='center'>
          <Typography variant='h3'>
            <GroupsIcon fontSize='inherit' />
          </Typography>
          <Typography variant='h6' paragraph sx={{ py: 2 }} textAlign='center'>
            Guest bartenders are still signing up.
            <br />
            Check back soon!
          </Typography>
        </Box>
      )}
    </EventSection>
  );
};

export default TabBartenders;
