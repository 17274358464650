import type { FC, ReactNode } from 'react';
import { memo } from 'react';

import type { Event } from '@gb/common';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

import FlatList from '../base/FlatList';
import MetaTags from '../base/MetaTags';
import PageTitle from '../base/PageTitle';
import Footer from '../components/common/Footer';
import MainAppBar from '../components/common/MainAppBar';
import Sponsors from '../components/common/Sponsors';
import AboutBanner from '../components/event/AboutBanner';
import EventListItem from '../components/event/EventListItem';
import { useAuthContext } from '../context/AuthContext';
import useListEvents from '../hooks/useListEvents';
import getPath from '../utils/getPath';

interface Props {
  isPast?: boolean;
}

const ListEvents: FC<Props> = ({ isPast = false }) => {
  const { events, loadMore, isLoading } = useListEvents({ isPast });
  const { isAdmin } = useAuthContext();

  const title = !isPast ? 'Events Calendar' : 'Past Events';

  return (
    <Box>
      <MetaTags title={title} />
      <MainAppBar hasDrawer />
      <PageTitle
        title={title}
        right={
          !!isAdmin && (
            <Button
              component={Link}
              to={getPath.eventCreate()}
              variant='contained'
              color='secondary'
            >
              Create Event
            </Button>
          )
        }
        boxProps={{ sx: { mb: 0 } }}
      />
      <AboutBanner />
      <Container
        maxWidth='lg'
        sx={{
          py: 2,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <Button
          component={Link}
          to={getPath.eventList({ isPast: !isPast })}
          variant='outlined'
        >
          {isPast ? 'View upcoming events' : 'View past events'}
        </Button>
      </Container>
      <Container maxWidth='lg' sx={{ mb: 6 }}>
        <FlatList
          data={events}
          ContainerComponent={ContainerComponent}
          Item={ItemMemo}
          extractKey={extractKey}
          ListEmptyComponent={
            !isLoading ? (
              <Grid item xs={12} sx={{ py: 6 }}>
                <Typography variant='body1' textAlign='center'>
                  There are no events right now. Check back soon!
                </Typography>
              </Grid>
            ) : null
          }
          onEndReached={loadMore}
        />
      </Container>
      {isLoading && (
        <Box display='flex' justifyContent='center' mb={3}>
          <CircularProgress variant='indeterminate' />
        </Box>
      )}
      <Sponsors />
      <Footer />
    </Box>
  );
};

export default ListEvents;

const ContainerComponent: FC<{ children: ReactNode }> = ({ children }) => (
  <Grid container columnSpacing={4} rowSpacing={6}>
    {children}
  </Grid>
);

const Item: FC<{ item: Event }> = ({ item: event }) => {
  const { eventId } = event;
  const eventUrl = getPath.event({ eventId });

  return (
    <Grid item xs={12} sm={6} md={4}>
      <EventListItem event={event} eventUrl={eventUrl} />
    </Grid>
  );
};

const ItemMemo = memo(
  Item,
  (prev, next) => prev.item.eventId === next.item.eventId,
);

const extractKey = (item: Event) => `event-${item.eventId}`;
