import type { FC } from 'react';
import { useState } from 'react';

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import { useManageContext } from '.';
import LoadingIndicator from '../../base/LoadingIndicator';
import ManageSection from '../../components/manage/ManageSection';
import { useAuthContext } from '../../context/AuthContext';
import useRemoveEvent from '../../hooks/useRemoveEvent';
import getPath from '../../utils/getPath';

const ManageRemove: FC = () => {
  const { isAdmin } = useAuthContext();
  const { event, totals } = useManageContext();
  const navigate = useNavigate();

  const { canRemove, onRemove, isLoading } = useRemoveEvent(event, totals);

  const [hasDialog, setHasDialog] = useState(false);
  const openDialog = () => setHasDialog(true);
  const closeDialog = () => setHasDialog(false);

  function handleRemove() {
    const onSuccess = () => navigate(getPath.manageList());
    onRemove(onSuccess);
  }

  if (isAdmin === undefined) {
    return <LoadingIndicator />;
  }

  if (!isAdmin) {
    if (!event) {
      return <Navigate to={getPath.manageList()} />;
    }

    return <Navigate to={getPath.manage({ eventId: event.eventId })} />;
  }

  return (
    <ManageSection title='Remove Event'>
      <Typography variant='button' component='div' sx={{ mb: 1 }}>
        Permanently remove event
      </Typography>
      <Typography variant='body1' paragraph>
        {canRemove
          ? 'Events can be removed until donations start.'
          : 'Events cannot be removed once donations have started.'}
      </Typography>
      {canRemove ? (
        <>
          <Box component='form'>
            <Stack spacing={1} pt={2} direction={{ xs: 'column', sm: 'row' }}>
              <Button
                component={Link}
                to={getPath.manage({
                  eventId: event?.eventId || '',
                  page: 'general',
                })}
                variant='outlined'
                fullWidth
              >
                Back
              </Button>
              <Button
                onClick={openDialog}
                variant='contained'
                color='error'
                fullWidth
              >
                Remove Event
              </Button>
            </Stack>
          </Box>
          <Dialog open={hasDialog} onClose={closeDialog}>
            <DialogTitle>Remove {event?.name}?</DialogTitle>
            <DialogContent>
              <Alert severity='error'>
                <AlertTitle>This action cannot be undone</AlertTitle>
                Removing an event will remove all Guest Bartenders and Event
                Organizers. They will not be notified.
              </Alert>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} disabled={isLoading}>
                Cancel
              </Button>
              <Button
                onClick={handleRemove}
                variant='contained'
                color='error'
                disabled={isLoading}
              >
                Remove Event
              </Button>
            </DialogActions>
            <LoadingIndicator isLoading={isLoading} color='error' />
          </Dialog>
        </>
      ) : (
        <Box>
          <Stack px={'25%'} pt={3} justifyContent='center'>
            <Button
              component={Link}
              to={getPath.manage({
                eventId: event?.eventId || '',
                page: 'general',
              })}
              variant='outlined'
              fullWidth
            >
              Back
            </Button>
          </Stack>
        </Box>
      )}
    </ManageSection>
  );
};

export default ManageRemove;
