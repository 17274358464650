import type { Event } from '@gb/common';
import { setDoc } from 'firebase/firestore';

import { getUniqueId } from '../utils/getUniqueId';
import get from './get';
import { getEventCollectionRef, getEventRef } from './refs';

const MIN_ID_LENGTH = 6;

export default async function create(
  data: Omit<Event, 'eventId'>,
): Promise<Event['eventId']> {
  const eventId = await getUniqueId(
    getEventCollectionRef(),
    getExists,
    MIN_ID_LENGTH,
  );
  const ref = getEventRef(eventId);

  await setDoc(ref, { ...data, eventId });

  return eventId;
}

async function getExists(id: string) {
  const event = await get(id);

  return !!event;
}
