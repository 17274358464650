import type { CollectionReference } from 'firebase/firestore';
import { doc } from 'firebase/firestore';

import { isIdValid } from './isIdValid';

export async function generateId<T>(
  collectionRef: CollectionReference<T>,
  getExists: (id: string) => Promise<boolean>,
  length: number,
): Promise<string | undefined> {
  let id = '';
  while (!isIdValid(id)) {
    // generate shortened ids until a valid one is found
    const longRef = doc(collectionRef);
    id = getShortenedId(longRef.id, length);
  }

  // make sure the id isn't already being used
  const exists = await getExists(id);
  if (exists) {
    return;
  }

  return id;
}

function getShortenedId(input: string, length: number): string {
  return input.toLowerCase().slice(0, length);
}
