import type { FC } from 'react';

import type { BoxProps, TypographyProps } from '@mui/material';
import { Box, Typography } from '@mui/material';

import getCurrentYear from '../utils/getCurrentYear';

const FIRST_YEAR = 2010;
const CURRENT_YEAR = getCurrentYear();

interface Props {
  boxProps?: BoxProps;
  color?: TypographyProps['color'];
  typographyProps?: Omit<TypographyProps<'p'>, 'color'>;
}

const CopyrightNotice: FC<Props> = ({
  boxProps,
  color = 'grey.300',
  typographyProps,
}) => {
  return (
    <Box {...boxProps}>
      <Typography
        variant='caption'
        component='p'
        color={color}
        textAlign='center'
        {...typographyProps}
      >
        Copyright &copy; {FIRST_YEAR} - {CURRENT_YEAR} 115 LLC. All rights
        reserved. <br /> Guest Bartender, Guest Bartending, Guest Bartender
        Program are all service marks of 115 LLC
      </Typography>
    </Box>
  );
};

export default CopyrightNotice;
