import type { FC } from 'react';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Alert, Button, Card, CardContent, Typography } from '@mui/material';

import { useManageContext } from '.';
import LoadingIndicator from '../../base/LoadingIndicator';
import ManageSection from '../../components/manage/ManageSection';
import useDonorReport from '../../hooks/useDonorReport';

const ManageDonorReports: FC = () => {
  const { event, totals } = useManageContext();
  const { downloadReport, isLoading, hasError } = useDonorReport(
    event?.eventId,
  );

  if (!event) {
    return null;
  }

  const hasDonors = !!totals?.donorCount;

  return (
    <ManageSection title='Donor Reports'>
      {hasDonors ? (
        <>
          <Alert severity='info' sx={{ mb: 3 }}>
            Get reports for your event donors.
          </Alert>

          <Card variant='outlined' sx={{ textAlign: 'center', mb: 2 }}>
            <CardContent>
              <Typography variant='body1' paragraph>
                Download a list of donors and recipients in Excel format.
              </Typography>
              <Button
                variant='contained'
                color='secondary'
                startIcon={<FileDownloadIcon />}
                sx={{ mb: 1 }}
                onClick={downloadReport}
                disabled={isLoading}
              >
                {!isLoading ? 'Download' : 'Loading...'}
              </Button>
            </CardContent>
            <LoadingIndicator isLoading={isLoading} />
          </Card>
          {hasError && (
            <Typography color='error'>
              Something went wrong. Please check your connection and try again.
            </Typography>
          )}
        </>
      ) : (
        <Alert severity='info'>
          Donor reports will be available after the event receives its first
          donations.
        </Alert>
      )}
    </ManageSection>
  );
};

export default ManageDonorReports;
