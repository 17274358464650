export type Ratio = '16:9' | '4:3' | '1:1' | number;

export function getAspectRatio(ratio: Ratio | undefined): number | undefined {
  if (!ratio) {
    return;
  }
  if (ratio === '16:9') {
    return 1.7778;
  }
  if (ratio === '4:3') {
    return 1.3333;
  }
  if (ratio === '1:1') {
    return 1;
  }

  return ratio;
}
