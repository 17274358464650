import type { Event } from '@gb/common';
import { getDocs, limit, orderBy, query, where } from 'firebase/firestore';

import { getFutureDate, getPastDate } from '../utils/getDate';
import { getEventCollectionRef } from './refs';

const LIMIT = 100;

export default async function listActive({
  docLimit = LIMIT,
}: {
  /** how many events to list in each batch */
  docLimit?: number;
} = {}): Promise<Event[]> {
  const past = getPastDate();
  const future = getFutureDate();

  const q = query(
    getEventCollectionRef(),
    orderBy('endsAt', 'asc'),
    where('endsAt', '>=', past),
    where('endsAt', '<=', future),
    limit(docLimit),
  );

  const snap = await getDocs(q);

  return snap.docs.map((doc) => doc.data());
}
