import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useState } from 'react';

import api from '../../api';

interface Hook {
  subject: string;
  setSubject: Dispatch<SetStateAction<string>>;
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
  onSubmit: (onSuccess?: () => void) => void;
  onReset: () => void;
  isLoading: boolean;
  hasError: boolean;
}

const confirmMessage =
  'Are you sure you want to send the message to all bartenders and organizers?\n\nPress OK to send\nPress Cancel to go back to editing';
const clearMessage =
  'Are you sure you want to clear your message? Your draft will not be saved.';

export default function useMessageBartenders(
  eventId: string | undefined,
): Hook {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const resetForm = useCallback(() => {
    setSubject('');
    setMessage('');
  }, []);

  const onReset = useCallback(() => {
    if (!subject && !message) {
      return;
    }
    if (!window.confirm(clearMessage)) {
      return;
    }
    resetForm();
  }, [subject, message, resetForm]);

  const onSubmit = useCallback(
    (onSuccess?: () => void) => {
      if (isLoading) {
        return;
      }
      if (!eventId) {
        setHasError(true);

        return;
      }
      if (!window.confirm(confirmMessage)) {
        return;
      }
      setIsLoading(true);
      setHasError(false);
      api.bartender
        .sendMessage({ eventId, subject, message })
        .then(() => {
          onSuccess?.();
          resetForm();
          window.alert('Your message has been sent!');
        })
        .catch((err) => {
          console.error(err);
          setHasError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [isLoading, eventId, subject, message, resetForm],
  );

  return {
    subject,
    setSubject,
    message,
    setMessage,
    onSubmit,
    onReset,
    isLoading,
    hasError,
  };
}
