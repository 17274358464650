import type { FC, FormEventHandler, ReactNode } from 'react';
import { useRef } from 'react';

import type { BoxProps } from '@mui/material';
import { Box, Button, Stack, Typography } from '@mui/material';

interface Props {
  onSubmit: () => void;
  submitLabel: string;
  submitLoadingLabel?: string;
  onCancel?: (() => void) | undefined;
  cancelLabel?: string;
  isLoading?: boolean | undefined;
  hasError?: boolean;
  errorMessage?: string;
  boxProps?: BoxProps<'form'>;
  children: ReactNode;
}

const FormBase: FC<Props> = ({
  onSubmit,
  submitLabel,
  submitLoadingLabel = 'Saving...',
  onCancel,
  cancelLabel = 'Cancel',
  isLoading = false,
  hasError,
  errorMessage = 'Something went wrong. Please check your connection and try again.',
  boxProps,
  children,
}) => {
  const formRef = useRef<HTMLFormElement>(null);

  function handleSubmit() {
    const isValid = formRef.current?.reportValidity();
    if (!isValid) {
      return;
    }
    onSubmit();
  }

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  return (
    <Box
      component='form'
      ref={formRef}
      onSubmit={handleFormSubmit}
      {...boxProps}
    >
      {children}
      <Button type='submit' sx={{ display: 'none' }} />
      <Stack
        direction='row'
        spacing={4}
        justifyContent='center'
        alignItems='center'
        mt={4}
        mb={3}
      >
        {!!onCancel && (
          <Button
            disabled={isLoading}
            fullWidth
            variant='outlined'
            onClick={onCancel}
          >
            {cancelLabel}
          </Button>
        )}
        <Button
          disabled={isLoading}
          fullWidth
          variant='contained'
          color='secondary'
          onClick={handleSubmit}
        >
          {!isLoading ? submitLabel : submitLoadingLabel}
        </Button>
      </Stack>
      {!!hasError && <Typography color='error'>{errorMessage}</Typography>}
    </Box>
  );
};

export default FormBase;
