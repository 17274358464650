import type { FC } from 'react';

import { Box, Button, Container, Typography } from '@mui/material';
import { Link, Navigate, useSearchParams } from 'react-router-dom';

import LoadingIndicator from '../base/LoadingIndicator';
import MainAppBar from '../components/common/MainAppBar';
import useCompleteSignInLink from '../hooks/useCompleteSignInLink';
import getPath from '../utils/getPath';

const SignInComplete: FC = () => {
  const { isComplete, hasError } = useCompleteSignInLink();
  const [searchParams] = useSearchParams();

  if (isComplete && !hasError) {
    let to = searchParams.get('redirectTo');
    if (!to) {
      to = getPath.home();
    }

    return <Navigate to={to} replace />;
  }

  return (
    <Box display='flex' flexDirection='column' minHeight='100vh'>
      <MainAppBar hasDrawer />
      <Box flexGrow={1}>
        {!hasError ? (
          <>
            <LoadingIndicator sx={{ mb: 3 }} />
            <Container maxWidth='xs'>
              <Typography variant='h5' textAlign='center' color='GrayText'>
                Signing you in...
              </Typography>
            </Container>
          </>
        ) : (
          <Container
            maxWidth='xs'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: 3,
            }}
          >
            <Typography variant='subtitle1' gutterBottom>
              Something went wrong
            </Typography>
            <Typography variant='body1' gutterBottom>
              Please check your email address and try again
            </Typography>
            <Button component={Link} to={getPath.signIn()}>
              Try again
            </Button>
          </Container>
        )}
      </Box>
    </Box>
  );
};

export default SignInComplete;
