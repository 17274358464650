import type { FC } from 'react';
import { useState } from 'react';

import { Box, Typography } from '@mui/material';

import { useManageContext } from '.';
import DonationList from '../../components/common/DonationList';
import DonationListHeader from '../../components/common/DonationListHeader';
import ManageSection from '../../components/manage/ManageSection';
import useListDonations from '../../hooks/useListDonations';

const ManageDonations: FC = () => {
  const { event, totals } = useManageContext();

  const [bartenderId, setBartenderId] = useState<string>('');

  const { donations, loadMore, isLoading } = useListDonations({
    eventId: event?.eventId,
    bartenderId,
  });

  if (!event) {
    return null;
  }

  const { bartenders } = event;

  return (
    <ManageSection title='Donations'>
      <DonationListHeader
        totals={totals}
        bartenders={bartenders}
        bartenderId={bartenderId}
        setBartenderId={setBartenderId}
      />
      <DonationList
        donations={donations}
        onEndReached={loadMore}
        isLoading={isLoading}
        ListEmptyComponent={
          !isLoading ? (
            <Box py={5}>
              <Typography variant='h5' textAlign='center'>
                There are no donations yet
              </Typography>
            </Box>
          ) : null
        }
      />
    </ManageSection>
  );
};

export default ManageDonations;
