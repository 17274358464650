import type { Event } from '@gb/common';
import { getDocs, limit, query, where } from 'firebase/firestore';

import { getEventCollectionRef } from './refs';

export default async function findByBartenderId(
  bartenderId: string,
): Promise<Event | undefined> {
  const ref = getEventCollectionRef();

  const q = query(
    ref,
    where('bartenderIds', 'array-contains', bartenderId),
    limit(1),
  );

  const snap = await getDocs(q);

  const [match] = snap.docs.map((doc) => doc.data());

  return match;
}
