import type { Breakpoint } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';

/**
 * Check if screen is a desktop or mobile size
 * @param [breakpoint] optional transition breakpoint
 * @returns true if screen size is at or above breakpoint
 */
export default function useIsDesktop(breakpoint: Breakpoint = 'sm'): boolean {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(breakpoint));

  return isDesktop;
}
