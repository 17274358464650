import type { Totals } from '@gb/common';
import { onValue } from 'firebase/database';

import { getTotalsRef } from './refs';

export default function subscribeTotals(
  eventId: string,
  onUpdate: (value: Totals | undefined) => void,
  onError: (err: Error) => void = console.error,
): () => void {
  const ref = getTotalsRef(eventId);

  const unsubscribe = onValue(
    ref,
    (snap) => {
      const totals = snap.val() as Totals | null;
      onUpdate(totals || undefined);
    },
    onError,
  );

  return unsubscribe;
}
