import type { FC } from 'react';
import { useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, ButtonBase, IconButton, Toolbar } from '@mui/material';
import type { To } from 'react-router-dom';
import { Link } from 'react-router-dom';

import logoDark from '../../images/logo-full-dark.png';
import { ReactComponent as LogoSvg } from '../../images/logo.svg';
import getPath from '../../utils/getPath';
import MainDrawer from './MainDrawer';

interface WithDrawer {
  hasDrawer: true;
  backTo?: undefined;
}
interface WithBack {
  hasDrawer?: false | undefined;
  backTo: To;
}

type Props = WithDrawer | WithBack;

const MainAppBar: FC<Props> = ({ hasDrawer = false, backTo }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);

  return (
    <>
      <Box displayPrint='none'>
        <AppBar position='static' color='primary'>
          <Toolbar>
            <IconButton
              size='large'
              edge='start'
              color='inherit'
              aria-label={hasDrawer ? 'menu' : 'back'}
              sx={{ mr: 1 }}
              onClick={hasDrawer ? openDrawer : undefined}
              component={hasDrawer ? 'button' : Link}
              to={!hasDrawer ? backTo : undefined}
            >
              {hasDrawer ? <MenuIcon /> : <ArrowBackIcon />}
            </IconButton>
            <ButtonBase to={getPath.home()} component={Link}>
              <LogoSvg width='100%' />
            </ButtonBase>
          </Toolbar>
        </AppBar>
        {hasDrawer && (
          <MainDrawer isOpen={isDrawerOpen} onClose={closeDrawer} />
        )}
      </Box>
      <Box
        display='none'
        displayPrint='flex'
        flexDirection='row'
        justifyContent='center'
      >
        <Box component='img' src={logoDark} sx={{ width: '40%' }} />
      </Box>
    </>
  );
};

export default MainAppBar;
