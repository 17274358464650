import type { FC } from 'react';
import { useEffect, useMemo } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  AppBar,
  Box,
  Container,
  Grid,
  Link as MuiLink,
  Toolbar,
  Typography,
  useScrollTrigger,
} from '@mui/material';
import Button from '@mui/material/Button';
import { Link, useParams, useSearchParams } from 'react-router-dom';

import LoadingIndicator from '../base/LoadingIndicator';
import MetaTags from '../base/MetaTags';
import DonateForm from '../forms/DonateForm';
import useCreateCheckout from '../hooks/useCreateCheckout';
import useGetEvent from '../hooks/useGetEvent';
import { getCdnSrc } from '../utils/getCdnSrc';
import getPath from '../utils/getPath';
import NotFound from './NotFound';

const Donate: FC = () => {
  const { eventId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const initialBartenderId = searchParams.get('bartenderId');

  const { event, isLoading: isEventLoading, hasError } = useGetEvent(eventId);
  const canDonate = !!event?.canDonate;

  const {
    onCheckout,
    bartenderId,
    setBartenderId,
    donorName,
    setDonorName,
    isAnonymous,
    setIsAnonymous,
    amountCents,
    setAmountCents,
    hasPlatformCost,
    setHasPlatformCost,
    isLoading,
  } = useCreateCheckout(event);

  useEffect(() => {
    if (!initialBartenderId) {
      return;
    }
    setBartenderId(initialBartenderId);
  }, [initialBartenderId, setBartenderId]);

  const onSubmit = () => {
    onCheckout((checkoutUrl) => (window.location.href = checkoutUrl));
  };

  const backTo = useMemo(() => {
    if (!initialBartenderId) {
      return getPath.event({ eventId });
    }

    return getPath.bartender({ bartenderId: initialBartenderId });
  }, [initialBartenderId, eventId]);

  const isScrolled = useScrollTrigger({ threshold: 0 });

  if (isEventLoading) {
    return <LoadingIndicator />;
  }

  if (hasError) {
    return <NotFound />;
  }

  return (
    <Box position='relative'>
      <MetaTags
        title={`Donate to ${event?.name || 'fundraiser'}`}
        description={event?.tagline}
        image={event?.cover ? getCdnSrc({ src: event.cover }) : undefined}
      />
      <AppBar
        color='inherit'
        position='sticky'
        sx={{ display: { xs: 'flex', md: 'none' } }}
        elevation={isScrolled ? 1 : 0}
      >
        <Toolbar>
          <Button
            variant='text'
            startIcon={<ArrowBackIcon />}
            component={Link}
            to={backTo}
          >
            Back
          </Button>
        </Toolbar>
      </AppBar>
      <Box
        display={{ xs: 'none', md: 'block' }}
        width='50vw'
        height='100vh'
        position='fixed'
        top='0'
        left='50%'
        sx={{
          boxShadow: '15px 0 30px 0 rgb(0 0 0 / 18%)',
          clipPath: 'inset(0px 0px 0px -15px)',
        }}
      />
      <Container
        maxWidth='lg'
        sx={{
          display: { xs: 'block', md: 'flex' },
          position: 'relative',
          alignItems: 'center',
          minHeight: { xs: 0, md: '100vh' },
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ p: { xs: 0, md: 10 }, display: { xs: 'none', md: 'block' } }}
          >
            <Button
              variant='text'
              startIcon={<ArrowBackIcon />}
              component={Link}
              to={backTo}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={12} md={6} p={{ xs: 0, md: 10 }}>
            <Typography variant='h6' textAlign={{ xs: 'center', md: 'left' }}>
              {canDonate ? 'Donate now' : 'Thank you!'}
            </Typography>
            {canDonate ? (
              <>
                <DonateForm
                  bartenders={event.bartenders}
                  eventName={event.name}
                  initialBartenderId={initialBartenderId}
                  bartenderId={bartenderId}
                  setBartenderId={setBartenderId}
                  donorName={donorName}
                  setDonorName={setDonorName}
                  isAnonymous={isAnonymous}
                  setIsAnonymous={setIsAnonymous}
                  amountCents={amountCents}
                  setAmountCents={setAmountCents}
                  hasPlatformCost={hasPlatformCost}
                  setHasPlatformCost={setHasPlatformCost}
                  onSubmit={onSubmit}
                  isLoading={isLoading}
                />
                <Box display='flex' justifyContent='center' pb={6}>
                  <Typography variant='caption' textAlign='center'>
                    All donations subject to our{' '}
                    <MuiLink href={getPath.marketing('legal')} target='_blank'>
                      Terms &amp; Conditions
                    </MuiLink>
                  </Typography>
                </Box>
              </>
            ) : (
              <Box>
                <Typography variant='body1' color='GrayText'>
                  This event is not currently accepting donations
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Donate;
