import type { FC, ReactNode } from 'react';

import { Box, Button, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import LoadingIndicator from '../base/LoadingIndicator';
import Footer from '../components/common/Footer';
import MainAppBar from '../components/common/MainAppBar';
import { useAuthContext } from '../context/AuthContext';
import getPath from '../utils/getPath';

interface Props {
  children: ReactNode;
}

const AdminRoute: FC<Props> = ({ children }) => {
  const { isAdmin } = useAuthContext();

  if (isAdmin === undefined) {
    return <LoadingIndicator />;
  }

  if (!isAdmin) {
    return (
      <Box display='flex' flexDirection='column' minHeight='100vh'>
        <MainAppBar hasDrawer />
        <Container maxWidth='xs' sx={{ flexGrow: 1, py: 3, mb: 3 }}>
          <Typography
            variant='subtitle1'
            fontWeight='bold'
            textAlign='center'
            gutterBottom
          >{`You don't have permission to access this page.`}</Typography>
          <Typography variant='body1' textAlign='center' gutterBottom>
            If you believe this is in error, please contact an administrator.
          </Typography>
          <Button
            variant='contained'
            color='secondary'
            component={Link}
            to={getPath.home()}
            fullWidth
            sx={{ mt: 1 }}
          >
            Back to home
          </Button>
        </Container>
        <Footer />
      </Box>
    );
  }

  return <>{children}</>;
};

export default AdminRoute;
