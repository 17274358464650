import type { Organizer } from '@gb/common';
import type { Timestamp } from 'firebase/firestore';
import { doc, serverTimestamp, setDoc } from 'firebase/firestore';

import { getOrganizerCollectionRef } from './refs';

export default async function create(
  data: Omit<Organizer, 'organizerId' | 'createdAt'>,
): Promise<string> {
  const ref = doc(getOrganizerCollectionRef());
  const organizerId = ref.id;
  // @ts-expect-error resolves to Timestamp
  const createdAt: Timestamp = serverTimestamp();

  await setDoc(ref, { ...data, organizerId, createdAt });

  return organizerId;
}
