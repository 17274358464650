import type { FC } from 'react';
import { useState } from 'react';

import PersonIcon from '@mui/icons-material/Person';
import {
  Avatar,
  IconButton,
  ListSubheader,
  Menu,
  MenuItem,
  MenuList,
} from '@mui/material';
import { Link } from 'react-router-dom';

import { useAuthContext } from '../context/AuthContext';
import getPath from '../utils/getPath';

const AvatarMenu: FC = () => {
  const { isSignedIn, email, onSignOut } = useAuthContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = !!anchorEl;
  const openMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={openMenu}>
        <Avatar sx={{ bgcolor: 'secondary.dark' }}>
          <PersonIcon />
        </Avatar>
      </IconButton>
      <Menu
        id='avatar-menu'
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={closeMenu}
        MenuListProps={{
          disablePadding: true,
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuList sx={{ minWidth: 220, maxWidth: '100%' }}>
          <ListSubheader>
            {isSignedIn ? `${email || ''}` : 'Signed out'}
          </ListSubheader>
          {isSignedIn ? (
            <MenuItem onClick={() => onSignOut()}>Sign out</MenuItem>
          ) : (
            <MenuItem component={Link} to={getPath.signIn()}>
              Sign in
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </>
  );
};

export default AvatarMenu;
