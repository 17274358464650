import type { Bartender } from '@gb/common';
import { updateDoc } from 'firebase/firestore';

import { getBartenderRef } from './refs';

export default async function update(
  bartenderId: string,
  data: Partial<Omit<Bartender, 'eventId' | 'bartenderId'>>,
): Promise<string> {
  const ref = getBartenderRef(bartenderId);

  await updateDoc(ref, data);

  return bartenderId;
}
