import type { FC } from 'react';
import { useMemo, useRef } from 'react';

import { Box } from '@mui/material';
import { Marker, StaticGoogleMap } from 'react-static-google-map';

import useDimensions from '../hooks/useDimensions';
import demoImage from '../images/map-demo.png';
import { isDev } from '../utils/isDev';
import ResponsiveImage from './ResponsiveImage';

const GOOGLE_MAPS_API_KEY = 'AIzaSyDr0TqnB0onWChHwpBA5w8CbkPtGjf2xjQ';
const URL_BASE = 'https://www.google.com/maps/search/?api=1';

interface Props {
  venue: string | undefined;
  addressStreet: string | undefined;
  addressCity: string | undefined;
  addressState: string | undefined;
  addressZip: string | undefined;
  aspectRatio?: number;
}

const GoogleMap: FC<Props> = ({
  venue = '',
  addressStreet = '',
  addressCity = '',
  addressState = '',
  addressZip = '',
  aspectRatio = 1,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { width } = useDimensions(containerRef);

  const location = useMemo(
    () =>
      `${venue}, ${addressStreet}, ${addressCity}, ${addressState} ${addressZip}`,
    [venue, addressStreet, addressCity, addressState, addressZip],
  );

  const mapUrl = useMemo(() => getMapUrl(location), [location]);

  if (isDev()) {
    // render fake map in development
    return (
      <a href={mapUrl} target='_blank' rel='noreferrer'>
        <ResponsiveImage aspectRatio={aspectRatio} src={demoImage} />
      </a>
    );
  }

  return (
    <Box ref={containerRef} sx={{ width: '100%' }}>
      <a href={mapUrl} target='_blank' rel='noreferrer'>
        {!!width && (
          <StaticGoogleMap
            as={ResponsiveImage}
            size={getSizeString({ width, aspectRatio })}
            scale={getScale(window.devicePixelRatio)}
            apiKey={GOOGLE_MAPS_API_KEY}
            zoom={13}
          >
            <Marker location={location} />
          </StaticGoogleMap>
        )}
      </a>
    </Box>
  );
};

function getSizeString({
  width,
  aspectRatio,
}: {
  width: number;
  aspectRatio: number;
}) {
  const height = width / aspectRatio;

  return `${Math.trunc(width)}x${Math.trunc(height)}`;
}

function getScale(pixelRatio: number): 1 | 2 | 4 {
  if (pixelRatio === 1 || pixelRatio === 2 || pixelRatio === 4) {
    return pixelRatio;
  }

  return 2;
}

function getMapUrl(location: string): string {
  const query = encodeURIComponent(location);

  return URL_BASE + `&query=${query}`;
}

export default GoogleMap;
