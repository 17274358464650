import type { FC, ReactElement, ReactNode } from 'react';
import { useEffect, useMemo, useRef } from 'react';

import { List } from '@mui/material';

import useIsVisible from '../hooks/useIsVisible';

interface Props<T> {
  data: T[];
  ContainerComponent?: FC<{ children: ReactNode }> | undefined;
  Item: FC<{ item: T; index: number }>;
  extractKey: (item: T, index: number) => string;
  onEndReached?: (() => void) | undefined;
  ListFooterComponent?: JSX.Element | null | undefined;
  ListEmptyComponent?: JSX.Element | null | undefined;
}

export default function FlatList<T>({
  data,
  ContainerComponent = DefaultContainer,
  Item,
  extractKey,
  onEndReached,
  ListFooterComponent,
  ListEmptyComponent,
}: Props<T>): ReactElement | null {
  const endRef = useRef<HTMLDivElement>(null);
  const isEndVisible = useIsVisible(endRef);

  useEffect(() => {
    if (!isEndVisible) {
      return;
    }
    onEndReached?.();
  }, [onEndReached, isEndVisible]);

  const children = useMemo(
    () =>
      data.map((item, index) => (
        <Item key={extractKey(item, index)} item={item} index={index} />
      )),
    [data, Item, extractKey],
  );

  const isEmpty = useMemo(() => !data.length, [data]);

  return (
    <div>
      <ContainerComponent>{children}</ContainerComponent>
      <div ref={endRef} style={{ width: '100%', height: '0px' }} />
      {isEmpty && !!ListEmptyComponent && ListEmptyComponent}
      {!!ListFooterComponent && ListFooterComponent}
    </div>
  );
}

const DefaultContainer: FC<{ children: ReactNode }> = ({ children }) => (
  <List disablePadding>{children}</List>
);
