import type { Event } from '@gb/common';
import type { FirestoreError } from 'firebase/firestore';
import { onSnapshot } from 'firebase/firestore';

import { getEventRef } from './refs';

export default function subscribe(
  eventId: string,
  onUpdate: (data: Event | undefined) => void,
  onError: (err: FirestoreError) => void = console.error,
): () => void {
  const ref = getEventRef(eventId);
  const unsubscribe = onSnapshot(ref, (doc) => onUpdate(doc.data()), onError);

  return unsubscribe;
}
