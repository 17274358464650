import type { FC } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import type { ButtonProps } from '@mui/material';
import { Button } from '@mui/material';

import CopyElement from './CopyElement';

interface Props extends Omit<ButtonProps, 'onClick'> {
  value: string;
}

const CopyButton: FC<Props> = ({
  value,
  size = 'medium',
  children = 'Copy',
  ...buttonProps
}) => {
  return (
    <CopyElement
      value={value}
      render={(onClick) => (
        <Button
          startIcon={<ContentCopyIcon fontSize={size} />}
          onClick={onClick}
          size={size}
          {...buttonProps}
        >
          {children}
        </Button>
      )}
    />
  );
};

export default CopyButton;
