import type { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { CreateEventSection } from '.';
import { useCreateEventContext } from '../../context/CreateEventContext';
import GeneralForm from '../../forms/GeneralForm';
import getPath from '../../utils/getPath';

const CreateGeneral: FC = () => {
  const { event, set, isLoading, onSubmit } = useCreateEventContext();

  const navigate = useNavigate();

  function handleSubmit() {
    onSubmit((eventId) => {
      navigate(getPath.manage({ eventId }));
    });
  }

  function onBack() {
    navigate(getPath.eventCreate({ page: 'cause' }));
  }

  return (
    <CreateEventSection title='General'>
      <GeneralForm
        isActive={event.isActive}
        setIsActive={(v) => set('isActive', v)}
        canDonate={event.canDonate}
        setCanDonate={(v) => set('canDonate', v)}
        goalCents={event.goalCents}
        setGoalCents={(v) => set('goalCents', v || 0)}
        platformRate={event.platformRate}
        setPlatformRate={(v) => set('platformRate', v || 0)}
        onSubmit={handleSubmit}
        submitLabel='Create event'
        onCancel={onBack}
        cancelLabel='Back'
        isLoading={isLoading}
      />
    </CreateEventSection>
  );
};

export default CreateGeneral;
