import type { FC } from 'react';
import { useMemo } from 'react';

import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';

import { useManageContext } from '.';
import CopyButton from '../../base/CopyButton';
import CopyIconButton from '../../base/CopyIconButton';
import ShareIcons from '../../components/common/ShareIcons';
import ManageSection from '../../components/manage/ManageSection';
import getPath from '../../utils/getPath';
import { getSortedBartenders } from '../../utils/getSortedBartenders';

const ManageShare: FC = () => {
  const { event } = useManageContext();
  const { eventId = '', name = '', bartenders, facebookUrl = '' } = event || {};

  const eventUrl = useMemo(
    () => window.location.origin + getPath.event({ eventId }),
    [eventId],
  );

  const sortedBartenders = useMemo(
    () => getSortedBartenders(bartenders),
    [bartenders],
  );

  return (
    <ManageSection title='Share Event'>
      <Box mb={6}>
        <Alert severity='info' sx={{ mb: 2 }}>
          <AlertTitle>Event page</AlertTitle>
          Share the fundraising links for your event!
        </Alert>
        <LinkCard label='Fundraising Link' url={eventUrl} />
        <LinkCard label='Facebook Invite Link' url={facebookUrl} />
        <Card variant='outlined'>
          <CardContent>
            <ShareIcons
              url={eventUrl}
              eventName={name}
              headline='Share your event'
            />
          </CardContent>
        </Card>
      </Box>
      {!!sortedBartenders.length && (
        <Box mb={3}>
          <Alert severity='info' sx={{ mb: 2 }}>
            <AlertTitle>Guest Bartenders</AlertTitle>
            Each Guest Bartender has a dedicated fundraising link.
          </Alert>
          <List disablePadding>
            {sortedBartenders.map(([bartenderId, bartenderName]) => {
              const bartenderUrl =
                window.location.origin + getPath.bartender({ bartenderId });

              return (
                <ListItem
                  key={`bartender-${bartenderId}`}
                  disablePadding
                  dense
                  secondaryAction={
                    <CopyIconButton
                      value={bartenderUrl}
                      size='small'
                      edge='end'
                    />
                  }
                >
                  <ListItemButton href={bartenderUrl} target='_blank'>
                    <ListItemText
                      primary={bartenderName}
                      secondary={bartenderUrl}
                      secondaryTypographyProps={{
                        sx: { wordBreak: 'break-word' },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Box>
      )}
    </ManageSection>
  );
};

export default ManageShare;

const LinkCard: FC<{ label: string; url: string }> = ({ label, url }) => (
  <Card variant='outlined' sx={{ mb: 2 }}>
    <CardContent>
      <Stack
        direction='row'
        spacing={2}
        alignItems='center'
        justifyContent='space-between'
        mb={1}
      >
        <Typography variant='button'>{label}</Typography>
        <CopyButton value={url} size='small' />
      </Stack>
      <Link href={url} target='_blank'>
        <Typography variant='body1' sx={{ wordBreak: 'break-word' }}>
          {url}
        </Typography>
      </Link>
    </CardContent>
  </Card>
);
