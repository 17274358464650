import { useEffect, useState } from 'react';

import type { Organizer } from '@gb/common';

import api from '../api';

interface Hook {
  organizers: Organizer[];
  isLoading: boolean;
}

export default function useSubscribeListOrganizers(eventId: string): Hook {
  const [organizers, setOrganizers] = useState<Organizer[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    return api.organizer.subscribeList(
      eventId,
      (data) => {
        setOrganizers(data);
        setIsLoading(false);
      },
      (err) => {
        console.error(err);
        setOrganizers([]);
        setIsLoading(false);
      },
    );
  }, [eventId]);

  return { organizers, isLoading };
}
