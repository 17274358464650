import { useEffect, useState } from 'react';

import type { Donation } from '@gb/common';
import { logEvent } from 'firebase/analytics';

import api from '../api';
import getDonationAnalyticsData from '../api/donation/utils/getDonationAnalyticsData';
import { analytics } from '../api/firebase';

interface Hook {
  donation: Donation | undefined;
  isLoading: boolean;
}

export default function useCompleteDonation(
  sessionId: string | undefined,
): Hook {
  const [donation, setDonation] = useState<Donation | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!sessionId) {
      setIsLoading(false);

      return;
    }
    setIsLoading(true);
    api.donation
      .getFromSessionId({ sessionId })
      .then(setDonation)
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  }, [sessionId]);

  useEffect(() => {
    if (!donation) {
      return;
    }
    const { donationId, eventId, eventName, amountCents, platformRateCents } =
      donation;
    const data = getDonationAnalyticsData({
      eventId,
      eventName,
      amountCents,
      platformRateCents,
    });
    logEvent(analytics, 'purchase', {
      transaction_id: donationId,
      affiliation: eventName,
      ...data,
    });
  }, [donation]);

  return { donation, isLoading };
}
