import type { ChangeEventHandler, FC } from 'react';

import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import type { ButtonProps } from '@mui/material';
import { Button } from '@mui/material';

type Props = Omit<ButtonProps<'label'>, 'children'> & {
  onSelectFile: (value: File | undefined) => void;
  isLoading: boolean;
  label?: string;
  accept?: string;
};

const UploadButton: FC<Props> = ({
  onSelectFile,
  isLoading,
  label = 'Upload',
  accept = 'image/*',
  ...props
}) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const files = e.target.files;
    onSelectFile(files?.[0]);
    // clear file selection
    e.target.value = '';
  };

  return (
    <Button
      variant='contained'
      component='label'
      disabled={isLoading}
      startIcon={<AddAPhotoIcon />}
      color='secondary'
      {...props}
    >
      {label}
      <input hidden accept={accept} type='file' onChange={handleChange} />
    </Button>
  );
};

export default UploadButton;
