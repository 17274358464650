import type { FC, ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import type { User } from 'firebase/auth';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

import { auth } from '../api/firebase';
import getPath from '../utils/getPath';

interface AuthContextValue {
  userId: string | undefined | null;
  email: string | undefined | null;
  isSignedIn: boolean | undefined;
  isAdmin: boolean | undefined;
  onSignOut: (onSuccess?: () => void) => void;
}

const authContext = createContext<AuthContextValue>({
  userId: undefined,
  email: undefined,
  isSignedIn: undefined,
  isAdmin: undefined,
  onSignOut: () => undefined,
});

export function useAuthContext(): AuthContextValue {
  return useContext(authContext);
}

interface Props {
  children: ReactNode;
}

export const AuthContextProvider: FC<Props> = ({ children }) => {
  const [user, setUser] = useState<User | null | undefined>(undefined);
  const [isAdmin, setIsAdmin] = useState<undefined | boolean>(undefined);
  const [isSignOutOpen, setIsSignOutOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    return onAuthStateChanged(auth, setUser, console.error);
  }, []);

  const isSignedIn = useMemo(
    () => (user === undefined ? undefined : !!user),
    [user],
  );

  const userId = useMemo(() => {
    if (!user) {
      return user;
    }

    return user.uid;
  }, [user]);

  const email = useMemo(() => {
    if (!user) {
      return user;
    }

    return user.email;
  }, [user]);

  useEffect(() => {
    if (!user) {
      setIsAdmin(user === undefined ? undefined : false);

      return;
    }
    user
      .getIdTokenResult()
      .then(({ claims }) => {
        setIsAdmin(!!claims.isAdmin);
      })
      .catch(console.error);
  }, [user]);

  const onSignOut = useCallback(() => setIsSignOutOpen(true), []);

  const onConfirmSignOut = useCallback(() => {
    signOut(auth)
      .then(() => navigate(getPath.signIn()))
      .catch(console.error);
  }, [navigate]);

  const value = useMemo(
    (): AuthContextValue => ({
      userId,
      email,
      isSignedIn,
      isAdmin,
      onSignOut,
    }),
    [userId, email, isSignedIn, isAdmin, onSignOut],
  );

  return (
    <authContext.Provider value={value}>
      {children}
      <SignOutDialog
        isOpen={isSignOutOpen}
        onClose={() => setIsSignOutOpen(false)}
        onConfirm={onConfirmSignOut}
      />
    </authContext.Provider>
  );
};

const SignOutDialog: FC<{
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}> = ({ isOpen, onClose, onConfirm }) => {
  function handleConfirm() {
    onClose();
    onConfirm();
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{'Sign out'}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          Are you sure you want to sign out of your account?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Go back</Button>
        <Button onClick={handleConfirm} color='error' autoFocus>
          Sign out
        </Button>
      </DialogActions>
    </Dialog>
  );
};
