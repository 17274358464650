import type { Event } from '@gb/common';
import { deleteField } from 'firebase/firestore';
import { deleteObject } from 'firebase/storage';

import event from '../event';
import { getEventImageRef } from './refs';

export default async function remove({
  eventId,
  type,
}: {
  eventId: string;
  type: 'cover' | 'logo';
}): Promise<void> {
  const ref = getEventImageRef({ eventId, type });

  await deleteObject(ref);

  const eventData: Partial<Event> = { [type]: deleteField() };

  await event.update(eventId, eventData);
}
