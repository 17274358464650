import { getRtdbUrl, getStorageBucket } from '@gb/common';
import {
  getAnalytics,
  setAnalyticsCollectionEnabled,
} from 'firebase/analytics';
import type { FirebaseOptions } from 'firebase/app';
import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectDatabaseEmulator, getDatabase } from 'firebase/database';
import {
  connectFirestoreEmulator,
  getFirestore,
  initializeFirestore,
} from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { connectStorageEmulator, getStorage } from 'firebase/storage';

import { isProd } from '../utils/isDev';

const firebasePorts = {
  functions: 5001,
  storage: 5002,
  auth: 5003,
  firestore: 5004,
  database: 5005,
};

const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyD8airEucVpl26bw-FBS-ui0MmbfAqUVJ8',
  authDomain: 'prod-guest-bartender.firebaseapp.com',
  databaseURL: getRtdbUrl(),
  projectId: 'prod-guest-bartender',
  storageBucket: getStorageBucket(),
  messagingSenderId: '504753054600',
  appId: '1:504753054600:web:5dbbd2833c8335c5db903d',
  measurementId: 'G-P5S3FHRTFG',
};

// init app
export const firebaseApp = initializeApp(firebaseConfig);

// Storage
export const storage = getStorage(firebaseApp);

// Auth
export const auth = getAuth(firebaseApp);

// Cloud Functions
export const cloudFunctions = getFunctions(firebaseApp);

// Firestore
initializeFirestore(firebaseApp, { ignoreUndefinedProperties: true });
export const db = getFirestore(firebaseApp);

// Real-time Database
export const rtdb = getDatabase(firebaseApp);

// Analytics
export const analytics = getAnalytics(firebaseApp);
// disable by default
setAnalyticsCollectionEnabled(analytics, false);

if (!isProd()) {
  connectStorageEmulator(storage, 'localhost', firebasePorts.storage);
  connectAuthEmulator(auth, `http://localhost:${firebasePorts.auth}`, {
    disableWarnings: true,
  });
  connectFunctionsEmulator(
    cloudFunctions,
    'localhost',
    firebasePorts.functions,
  );
  connectFirestoreEmulator(db, 'localhost', firebasePorts.firestore);
  connectDatabaseEmulator(rtdb, 'localhost', firebasePorts.database);
}
