import type { FC } from 'react';

import { Link } from '@mui/material';
import Linkify from 'linkify-react';
import type { IntermediateRepresentation } from 'linkifyjs';

interface Props {
  content: string | undefined;
  render?: (ir: IntermediateRepresentation) => JSX.Element;
}

const renderLink = ({ attributes, content }: IntermediateRepresentation) => {
  const { href, ...props } = attributes;

  return (
    <Link
      href={href as string}
      {...props}
      rel='noopener'
      target='_blank'
      sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
    >
      {content}
    </Link>
  );
};

const LinkifyText: FC<Props> = ({ content, render = renderLink }) => {
  return <Linkify options={{ render }}>{content}</Linkify>;
};

export default LinkifyText;
