import type { FC } from 'react';

import { Helmet } from 'react-helmet-async';

interface Props {
  title?: string | undefined;
  ogTitle?: string | undefined;
  description?: string | undefined;
  url?: string;
  image?: string | undefined;
}

const MetaTags: FC<Props> = ({
  title = 'Easy money for your cause',
  ogTitle = title,
  description = 'With the participation of local organizations and businesses, Guest Bartender events support local and national charities, teams, schools, animal shelters and other causes.',
  url = window.location.href,
  image,
}) => {
  const fullTitle = `${title} - Guest Bartender Program`;
  const fullOgTitle = `${ogTitle} - Guest Bartender Program`;

  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta name='og:title' content={fullOgTitle} />
      <meta name='description' content={description} />
      <meta name='og:description' content={description} />
      <meta name='og:url' content={url} />
      {!!image && <meta name='og:image' content={image} />}
    </Helmet>
  );
};

export default MetaTags;
