import type { Event } from '@gb/common';
import { getDoc } from 'firebase/firestore';

import { getEventRef } from './refs';

export default async function get(eventId: string): Promise<Event | undefined> {
  const snap = await getDoc(getEventRef(eventId));

  return snap.data();
}
