import type { FC } from 'react';
import { useRef } from 'react';

import { Button } from '@mui/material';

import LoadingIndicator from '../base/LoadingIndicator';
import TextField from '../base/TextField';

interface Props {
  email: string;
  setEmail: (value: string) => void;
  onSubmit: () => void;
  isLoading: boolean;
  hasError: boolean;
}

const SignInForm: FC<Props> = ({
  email,
  setEmail,
  onSubmit,
  isLoading,
  hasError,
}) => {
  const formRef = useRef<HTMLFormElement>(null);

  function handleSubmit() {
    const isValid = formRef.current?.reportValidity();
    if (!isValid) {
      return;
    }
    onSubmit();
  }

  return (
    <form
      ref={formRef}
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <TextField
        label='email address'
        autoComplete='email'
        value={email}
        onChangeText={setEmail}
        error={hasError}
        placeholder='Enter your email address'
        helperText={
          hasError ? 'Please check your email and try again.' : undefined
        }
        autoFocus
        type='email'
        required
      />
      <Button
        color='secondary'
        variant='contained'
        onClick={handleSubmit}
        disabled={isLoading || !email}
        fullWidth
        sx={{ mt: 1 }}
      >
        Sign in
      </Button>
      <Button type='submit' hidden />
      <LoadingIndicator isLoading={isLoading} />
    </form>
  );
};

export default SignInForm;
