import type { FC } from 'react';

import { Dialog } from '@mui/material';

import PersonForm from '../../forms/PersonForm';
import useCreateBartender from '../../hooks/useCreateBartender';
import useIsDesktop from '../../hooks/useIsDesktop';

interface Props {
  eventId: string;
  isOpen: boolean;
  onClose: () => void;
}

const AddBartenderDialog: FC<Props> = ({ eventId, isOpen, onClose }) => {
  const isMobile = !useIsDesktop();
  const { name, setName, email, setEmail, createBartender, isLoading } =
    useCreateBartender(eventId);

  function onSubmit() {
    createBartender(onClose);
  }

  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen={isMobile}>
      <PersonForm
        title='Add guest bartender'
        name={name}
        setName={setName}
        email={email}
        setEmail={setEmail}
        onSubmit={onSubmit}
        submitLabel='Add bartender'
        onCancel={onClose}
        isLoading={isLoading}
      />
    </Dialog>
  );
};

export default AddBartenderDialog;
