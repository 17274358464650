import type { FC } from 'react';
import { useMemo } from 'react';

import { Navigate } from 'react-router-dom';

import LoadingIndicator from '../base/LoadingIndicator';
import { useAuthContext } from '../context/AuthContext';
import getPath from '../utils/getPath';

const Home: FC = () => {
  const { isSignedIn } = useAuthContext();

  const isLoading = useMemo(() => isSignedIn === undefined, [isSignedIn]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isSignedIn) {
    return <Navigate to={getPath.manageList()} replace />;
  }

  return <Navigate to={getPath.eventList()} replace />;
};

export default Home;
