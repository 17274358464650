import type { FC } from 'react';

import { Outlet, ScrollRestoration } from 'react-router-dom';

import ContextProviders from './context';

const App: FC = () => {
  return (
    <ContextProviders>
      <Outlet />
      <ScrollRestoration />
    </ContextProviders>
  );
};

export default App;
