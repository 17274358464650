import { useCallback, useMemo, useState } from 'react';

import type { Event, Totals } from '@gb/common';

import api from '../api';

interface Hook {
  onRemove: (onSuccess?: () => void) => void;
  canRemove: boolean;
  isLoading: boolean;
}

export default function useRemoveEvent(
  event: Event | undefined,
  totals: Totals | undefined,
): Hook {
  const [isLoading, setIsLoading] = useState(false);

  const canRemove = useMemo(() => !totals?.totalCents, [totals]);

  const onRemove = useCallback(
    (onSuccess?: () => void) => {
      if (isLoading || !event || !canRemove) {
        return;
      }
      setIsLoading(true);
      api.event
        .remove(event.eventId)
        .then(onSuccess)
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    },
    [isLoading, event, canRemove],
  );

  return { onRemove, canRemove, isLoading };
}
