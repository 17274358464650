import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useState } from 'react';

import api from '../../api';

interface Hook {
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  onSubmit: (onSuccess?: () => void) => void;
  isLoading: boolean;
}

export default function useCreateAdmin(): Hook {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(
    (onSuccess?: () => void) => {
      if (isLoading) {
        return;
      }
      setIsLoading(true);

      api.admin
        .create({ name, email })
        .then(onSuccess)
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    },
    [isLoading, name, email],
  );

  return { name, setName, email, setEmail, onSubmit, isLoading };
}
