import type { FC } from 'react';

import HandshakeIcon from '@mui/icons-material/Handshake';
import { Fab, Slide } from '@mui/material';
import type { To } from 'react-router-dom';
import { Link } from 'react-router-dom';

interface Props {
  isVisible?: boolean;
  to: To;
}

const DonateFab: FC<Props> = ({ isVisible = true, to }) => {
  return (
    <Slide direction='up' in={isVisible}>
      <Fab
        component={Link}
        to={to}
        variant='extended'
        color='secondary'
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        <HandshakeIcon sx={{ mr: 1 }} />
        Donate
      </Fab>
    </Slide>
  );
};

export default DonateFab;
