import { useCallback } from 'react';

import type { Event } from '@gb/common';

import api from '../api';
import useLoadList from './useLoadList';

interface Hook {
  events: Event[];
  loadMore: () => void;
  hasMore: boolean;
  isLoading: boolean;
}

export default function useListEvents({
  isPast = false,
}: {
  isPast?: boolean;
}): Hook {
  const getItems = useCallback(
    async (
      docLimit: number,
      startAfterDoc?: Event | undefined,
    ): Promise<Event[]> => {
      return api.event.list({
        docLimit,
        startAfterDoc,
        isPast,
        isActive: true,
      });
    },
    [isPast],
  );

  const { items: events, loadMore, hasMore, isLoading } = useLoadList(getItems);

  return { events, loadMore, hasMore, isLoading };
}
