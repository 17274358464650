import type { FC } from 'react';

import type { Event } from '@gb/common';
import { Box, Card, CardActionArea, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import CdnImage from '../../base/CdnImage';

interface Props {
  event: Event;
  eventUrl: string;
}

const EventListItem: FC<Props> = ({ event, eventUrl }) => {
  const { name, cover, startsAt } = event;

  return (
    <Card elevation={0} sx={{ borderRadius: 0 }}>
      <CardActionArea component={Link} to={eventUrl}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            borderTop: '3px solid black',
          }}
        >
          <Typography variant='h3' fontWeight='bold' lineHeight={1}>
            {formatDate(startsAt, { day: 'numeric' })}
          </Typography>
          <Typography variant='h6' fontWeight='bold'>
            {formatDate(startsAt, { month: 'short' })}
          </Typography>
          <Typography
            variant='body2'
            fontWeight='bold'
            color='grey.400'
            sx={{ ml: 'auto' }}
            lineHeight={2}
          >
            {formatDate(startsAt, { weekday: 'short' })}
          </Typography>
        </Box>
        <CdnImage aspectRatio='16:9' src={cover} maxSize='sm' />
        <Box sx={{ py: 1 }}>
          <Typography variant='body1' fontWeight='bold'>
            {name}
          </Typography>
          <Typography variant='body2'>
            {formatDate(startsAt, {
              timeStyle: 'short',
              dateStyle: 'long',
            })}
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default EventListItem;

function formatDate(
  startsAt: Event['startsAt'],
  options: Intl.DateTimeFormatOptions,
) {
  return startsAt.toDate().toLocaleString(undefined, options);
}
