import type { FC } from 'react';
import { useMemo, useState } from 'react';

import type { Event, Totals } from '@gb/common';
import { Grid } from '@mui/material';

import useListDonations from '../../hooks/useListDonations';
import getPath from '../../utils/getPath';
import DonationList from '../common/DonationList';
import DonatePrompt from './DonatePrompt';
import DonateSidebar from './DonateSidebar';
import EventSection from './EventSection';
import LeaderboardDialog from './LeaderboardDialog';

interface Props {
  event: Event | undefined;
  totals: Totals | undefined;
  bartenderId?: string | undefined;
  donateUrl: string;
}

const TabDonations: FC<Props> = ({ event, totals, bartenderId, donateUrl }) => {
  const { donations, isLoading } = useListDonations({
    eventId: event?.eventId,
    bartenderId,
  });

  const [isLeaderboardOpen, setIsLeaderboardOpen] = useState(false);

  const donateListUrl = useMemo(() => {
    if (!event) {
      return '';
    }

    return getPath.donateList({ eventId: event.eventId, bartenderId });
  }, [event, bartenderId]);

  return (
    <EventSection id='donations' title='Donations' isLast>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4} md={4} displayPrint='none'>
          <DonateSidebar
            donateListUrl={donateListUrl}
            onLeaderboard={() => setIsLeaderboardOpen(true)}
            donateUrl={donateUrl}
            canDonate={!!event?.canDonate}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={1}
          sx={{ display: { xs: 'none', md: 'block' } }}
        />
        <Grid item xs={12} sm={8} md={7}>
          <DonationList
            donations={donations}
            isLoading={isLoading}
            ListEmptyComponent={
              !isLoading && event?.canDonate ? (
                <DonatePrompt
                  donateUrl={donateUrl}
                  text='Be the first to fund this cause!'
                />
              ) : null
            }
          />
        </Grid>
      </Grid>
      <LeaderboardDialog
        isOpen={isLeaderboardOpen}
        onClose={() => setIsLeaderboardOpen(false)}
        event={event}
        totals={totals}
      />
    </EventSection>
  );
};

export default TabDonations;
