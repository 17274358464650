import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';

import DraftsIcon from '@mui/icons-material/Drafts';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { Link, useSearchParams } from 'react-router-dom';

import LoadingIndicator from '../base/LoadingIndicator';
import MetaTags from '../base/MetaTags';
import PageTitle from '../base/PageTitle';
import Footer from '../components/common/Footer';
import MainAppBar from '../components/common/MainAppBar';
import { useAuthContext } from '../context/AuthContext';
import SignInForm from '../forms/SignInForm';
import useSendSignInLink from '../hooks/useSendSignInLink';
import getPath from '../utils/getPath';

const SignIn: FC = () => {
  const { isSignedIn, onSignOut } = useAuthContext();
  const [searchParams] = useSearchParams();

  const redirectTo = useMemo(
    () => searchParams.get('redirectTo'),
    [searchParams],
  );

  const { email, setEmail, isSent, onSubmit, onReset, isLoading, hasError } =
    useSendSignInLink(redirectTo);

  if (isSignedIn === undefined) {
    return <LoadingIndicator />;
  }

  if (isSignedIn) {
    return (
      <Layout>
        <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
          You are signed in
        </Typography>
        <Typography variant='body1' paragraph>
          To sign in with a different account, please sign out.
        </Typography>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          mt={3}
          sx={{ width: '100%' }}
        >
          <Button
            variant='outlined'
            onClick={() => onSignOut()}
            fullWidth
            color='error'
          >
            Sign out
          </Button>
          <Button
            variant='contained'
            component={Link}
            to={getPath.home()}
            fullWidth
            color='secondary'
          >
            Continue
          </Button>
        </Stack>
      </Layout>
    );
  }

  if (isSent) {
    return (
      <Layout>
        <DraftsIcon color='secondary' sx={{ fontSize: 60, mb: 1 }} />
        <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
          Check your email
        </Typography>
        <Typography variant='body1' textAlign='center' gutterBottom>
          We sent an email to you at{' '}
          <Typography
            component='span'
            fontSize='inherit'
            fontWeight={600}
            sx={{ whiteSpace: 'nowrap' }}
          >
            {email}
          </Typography>
          . It has a magic link that will sign you in.
        </Typography>
        <Button onClick={onReset} sx={{ mt: 3 }}>
          Try again
        </Button>
      </Layout>
    );
  }

  return (
    <Layout>
      <SignInForm
        email={email}
        setEmail={setEmail}
        onSubmit={onSubmit}
        isLoading={isLoading}
        hasError={hasError}
      />
    </Layout>
  );
};

export default SignIn;

const Layout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Box display='flex' flexDirection='column' minHeight='100vh'>
      <MetaTags title='Sign in' />
      <MainAppBar hasDrawer />
      <PageTitle title='Sign in' />
      <Container maxWidth='xs' sx={{ flexGrow: 1, py: 3, mb: 3 }}>
        <Box display='flex' flexDirection='column' alignItems='center'>
          {children}
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};
