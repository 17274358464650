import type { FC } from 'react';

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/material';

import { useManageContext } from '.';
import CdnImage from '../../base/CdnImage';
import UploadButton from '../../base/UploadButton';
import ManageSection from '../../components/manage/ManageSection';
import useUpdateImage from '../../hooks/useUpdateImage';

const ManageImages: FC = () => {
  const { event } = useManageContext();

  const { eventId, cover, logo } = event || {};
  const hasCover = !!cover;

  return (
    <ManageSection title='Images'>
      {!hasCover ? (
        <Alert severity='warning' sx={{ mb: 3 }}>
          <AlertTitle>Missing cover photo</AlertTitle>
          Please upload a cover photo for your event.
        </Alert>
      ) : (
        <Alert severity='info' sx={{ mb: 3 }}>
          These images are displayed on your event page.
        </Alert>
      )}
      <Box mb={6}>
        <Typography variant='button' paragraph>
          Cover photo
        </Typography>
        {!!cover && <CdnImage src={cover} aspectRatio='16:9' mb={2} />}
        <Typography variant='caption' paragraph>
          16:9 ratio (1920 x 1080)
        </Typography>
        <UploadImage eventId={eventId} type='cover' currentImage={cover} />
      </Box>
      <Box mb={6}>
        <Typography variant='button' paragraph>
          Organization logo
        </Typography>
        {!!logo && (
          <Box width='50%'>
            <CdnImage src={logo} maxSize='sm' mb={2} />
          </Box>
        )}
        <UploadImage eventId={eventId} type='logo' currentImage={logo} />
      </Box>
    </ManageSection>
  );
};

export default ManageImages;

const UploadImage: FC<{
  eventId: string | undefined;
  type: 'cover' | 'logo';
  currentImage: string | undefined;
}> = ({ eventId, type, currentImage }) => {
  const { onSubmit, onRemove, isLoading } = useUpdateImage(eventId, type);

  return (
    <Stack spacing={2} direction='row'>
      <UploadButton
        onSelectFile={onSubmit}
        isLoading={isLoading}
        label={currentImage ? 'Replace' : 'Upload'}
      />
      <Button
        onClick={() => onRemove()}
        disabled={!currentImage}
        variant='outlined'
        color='error'
      >
        Remove
      </Button>
    </Stack>
  );
};
