import type { CollectionReference } from 'firebase/firestore';

import { generateId } from './generateId';

const MAX_TRIES = 8;

export async function getUniqueId<T>(
  collectionRef: CollectionReference<T>,
  getExists: (id: string) => Promise<boolean>,
  minLength: number,
): Promise<string> {
  let id: string | undefined = undefined;
  let i = 0;
  let length = minLength;
  while (i < MAX_TRIES && !id) {
    id = await generateId(collectionRef, getExists, length);
    i++;
    length++;
  }

  if (!id) {
    throw new Error('unable to generate unique ref');
  }

  return id;
}
