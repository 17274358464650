import type { FC } from 'react';
import { useState } from 'react';

import { Alert } from '@mui/material';

import { useManageContext } from '.';
import SnackAlert from '../../base/SnackAlert';
import ManageSection from '../../components/manage/ManageSection';
import CauseForm from '../../forms/CauseForm';
import useUpdateCause from '../../hooks/useUpdateCause';

const ManageCause: FC = () => {
  const { event } = useManageContext();

  const { onSubmit, ...props } = useUpdateCause(event);

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const closeSnackbar = () => setIsSnackbarOpen(false);

  function handleSubmit() {
    onSubmit(() => setIsSnackbarOpen(true));
  }

  return (
    <ManageSection title='About the Cause'>
      <Alert severity='info' sx={{ mb: 2 }}>
        Information about the cause will be displayed on fundraising pages.
      </Alert>
      <CauseForm {...props} onSubmit={handleSubmit} submitLabel='Update' />
      <SnackAlert isOpen={isSnackbarOpen} onClose={closeSnackbar}>
        Changes saved!
      </SnackAlert>
    </ManageSection>
  );
};

export default ManageCause;
