import { useEffect, useState } from 'react';

import type { AdminUser } from '@gb/common';

import api from '../../api';

interface Hook {
  admins: AdminUser[];
  isLoading: boolean;
}

export default function useSubscribeListAdmins(): Hook {
  const [admins, setAdmins] = useState<AdminUser[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    return api.admin.subscribeList(
      (data) => {
        setAdmins(data);
        setIsLoading(false);
      },
      (err) => {
        console.error(err);
        setAdmins([]);
        setIsLoading(false);
      },
    );
  }, []);

  return { admins, isLoading };
}
