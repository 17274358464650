import type { FC, ReactNode } from 'react';

import { Navigate } from 'react-router-dom';

import LoadingIndicator from '../base/LoadingIndicator';
import { useAuthContext } from '../context/AuthContext';
import getPath from '../utils/getPath';

interface Props {
  children: ReactNode;
}

const ProtectedRoute: FC<Props> = ({ children }) => {
  const { isSignedIn } = useAuthContext();

  if (isSignedIn === undefined) {
    return <LoadingIndicator />;
  }

  if (!isSignedIn) {
    return (
      <Navigate
        to={`${getPath.signIn({ redirectTo: window.location.pathname })}`}
      />
    );
  }

  return <>{children}</>;
};

export default ProtectedRoute;
