import type { FC } from 'react';

import type { Event, Totals } from '@gb/common';
import { Money } from '@gb/common';
import { Box, Card, CardContent, Typography } from '@mui/material';

import SelectBartender from '../../base/SelectBartender';

interface Props {
  totals: Totals | undefined;
  bartenders: Event['bartenders'];
  bartenderId: string | undefined;
  setBartenderId: (value: string) => void;
}

const DonationListHeader: FC<Props> = ({
  totals,
  bartenders,
  bartenderId = '',
  setBartenderId,
}) => {
  const { totalCents = 0, offlineCents = 0 } = totals || {};

  const eventTotalCents = totalCents + offlineCents;

  const bartenderTotalCents = totals?.bartenderTotals?.[bartenderId] || 0;

  return (
    <Card variant='outlined' sx={{ mb: 2 }}>
      <CardContent sx={{ pt: 2, '&:last-child': { pb: 2 } }}>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Box>
            <Typography variant='h6' color='secondary'>
              $
              {new Money(
                bartenderId ? bartenderTotalCents : eventTotalCents,
              ).toString()}
            </Typography>
            <Typography variant='body2' color='GrayText'>
              Total Raised
            </Typography>
          </Box>
          <SelectBartender
            bartenders={bartenders}
            bartenderId={bartenderId}
            setBartenderId={setBartenderId}
            label='Bartender'
            helperText='Filter by bartender'
            isFullWidth={false}
            margin='none'
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default DonationListHeader;
