import { useEffect, useRef, useState } from 'react';

import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';

import { auth } from '../api/firebase';

interface Hook {
  isComplete: boolean;
  hasError: boolean;
}

export default function useCompleteSignInLink(): Hook {
  const [hasError, setHasError] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const hasRunOnce = useRef<boolean>(false);

  useEffect(() => {
    if (hasRunOnce.current || isComplete) {
      return;
    }
    hasRunOnce.current = true;

    if (!isSignInWithEmailLink(auth, window.location.href)) {
      setHasError(true);

      return;
    }
    let email = window.localStorage.getItem('emailForSignIn');
    if (!email) {
      email = window.prompt('Please provide your email for confirmation');
    }
    if (!email) {
      setHasError(true);

      return;
    }
    setHasError(false);

    signInWithEmailLink(auth, email, window.location.href)
      .then(() => {
        window.localStorage.removeItem('emailForSignIn');
        setIsComplete(true);
      })
      .catch((err) => {
        setHasError(true);
        console.error(err);
      });
  }, [isComplete]);

  return { isComplete, hasError };
}
