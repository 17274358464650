import type { FC } from 'react';
import { useState } from 'react';

import type { AdminUser } from '@gb/common';
import { Alert, Button, Stack, Typography } from '@mui/material';

import { AdminSection } from '.';
import LoadingIndicator from '../../base/LoadingIndicator';
import ManagePeopleList from '../../components/manage/ManagePeopleList';
import AddAdminDialog from '../../components/settings/AddAdminDialog';
import UpdateAdminDialog from '../../components/settings/UpdateAdminDialog';
import useSubscribeListAdmins from '../../hooks/admin/useSubscribeListAdmins';

const AdminUsers: FC = () => {
  const { admins, isLoading } = useSubscribeListAdmins();

  const [isAddOpen, setIsAddOpen] = useState(false);
  const openAdd = () => setIsAddOpen(true);
  const closeAdd = () => setIsAddOpen(false);

  const [activeAdmin, setActiveAdmin] = useState<AdminUser | undefined>(
    undefined,
  );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <AdminSection title='Users'>
      <Stack
        direction='row'
        spacing={2}
        alignItems='center'
        justifyContent='space-between'
        sx={{ mb: 3 }}
      >
        <Typography variant='h6'>Site Admins</Typography>
        <Button variant='contained' color='secondary' onClick={openAdd}>
          Add admin user
        </Button>
      </Stack>
      <Alert severity='warning' sx={{ mb: 3 }}>
        Site admins can create and manage all events. They have full access to
        all settings. Only add trusted employees here
      </Alert>
      <ManagePeopleList
        people={admins}
        onClickPerson={setActiveAdmin}
        emptyMessage='There are no site admins yet'
        personLabel='user'
      />
      {isAddOpen && <AddAdminDialog isOpen={true} onClose={closeAdd} />}
      {!!activeAdmin && (
        <UpdateAdminDialog
          activeAdmin={activeAdmin}
          isOpen={true}
          onClose={() => setActiveAdmin(undefined)}
        />
      )}
    </AdminSection>
  );
};

export default AdminUsers;
