import type { FC } from 'react';
import { useState } from 'react';

import type { Organizer } from '@gb/common';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Alert, Box, Button, Typography } from '@mui/material';

import SnackAlert from '../../base/SnackAlert';
import useSubscribeListOrganizers from '../../hooks/useSubscribeListOrganizers';
import AddOrganizerDialog from './AddOrganizerDialog';
import ManagePeopleList from './ManagePeopleList';
import ResendWelcomeDialog from './ResendWelcomeDialog';
import UpdateOrganizerDialog from './UpdateOrganizerDialog';

interface Props {
  eventId: string;
}

const ManageOrganizers: FC<Props> = ({ eventId }) => {
  const { organizers } = useSubscribeListOrganizers(eventId);

  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [activeOrganizer, setActiveOrganizer] = useState<
    Organizer | undefined
  >();
  const [resendOrganizer, setResendOrganizer] = useState<
    Organizer | undefined
  >();
  const [isResendAlertOpen, setIsResendAlertOpen] = useState(false);
  const openResendAlert = () => setIsResendAlertOpen(true);
  const closeResendAlert = () => setIsResendAlertOpen(false);

  const openAddDialog = () => setIsAddDialogOpen(true);
  const closeAddDialog = () => setIsAddDialogOpen(false);

  const openEditDialog = (organizer: Organizer) =>
    setActiveOrganizer(organizer);
  const closeEditDialog = () => setActiveOrganizer(undefined);

  const openResendDialog = (organizer: Organizer) =>
    setResendOrganizer(organizer);
  const closeResendDialog = () => setResendOrganizer(undefined);

  return (
    <Box mb={3}>
      <Box display='flex' justifyContent='space-between' mb={2}>
        <Typography variant='h5'>Event Organizers</Typography>
        <Button
          onClick={openAddDialog}
          variant='contained'
          color='secondary'
          endIcon={<PersonAddIcon />}
        >
          Add
        </Button>
      </Box>
      <Alert severity='info' action={null} sx={{ mb: 2 }}>
        Event Organizers can manage the event, add Guest Bartenders and other
        Organizers, and view financial reports
      </Alert>
      <ManagePeopleList
        people={organizers}
        onClickPerson={openEditDialog}
        onResendPerson={openResendDialog}
        emptyMessage='There are no event organizers yet'
        personLabel='organizer'
      />

      {isAddDialogOpen && (
        <AddOrganizerDialog
          eventId={eventId}
          isOpen={true}
          onClose={closeAddDialog}
        />
      )}

      {!!activeOrganizer && (
        <UpdateOrganizerDialog
          organizer={activeOrganizer}
          isOpen={true}
          onClose={closeEditDialog}
        />
      )}

      {!!resendOrganizer && (
        <ResendWelcomeDialog
          person={resendOrganizer}
          onClose={closeResendDialog}
          onAlert={openResendAlert}
        />
      )}

      <SnackAlert isOpen={isResendAlertOpen} onClose={closeResendAlert}>
        Invitation sent!
      </SnackAlert>
    </Box>
  );
};

export default ManageOrganizers;
