import type { AdminUser } from '@gb/common';
import type { FirestoreError } from 'firebase/firestore';
import { limit, onSnapshot, orderBy, query } from 'firebase/firestore';

import { getAdminCollectionRef } from './refs';

const LIMIT = 250;

export default function subscribeList(
  onUpdate: (data: AdminUser[]) => void,
  onError: (err: FirestoreError) => void = console.error,
): () => void {
  const q = query(getAdminCollectionRef(), orderBy('name'), limit(LIMIT));

  const unsubscribe = onSnapshot(
    q,
    (snap) => onUpdate(snap.docs.map((doc) => doc.data())),
    onError,
  );

  return unsubscribe;
}
