import type { FC } from 'react';
import { useState } from 'react';

import type { Bartender } from '@gb/common';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Alert, Box, Button, Typography } from '@mui/material';

import SnackAlert from '../../base/SnackAlert';
import useSubscribeListBartenders from '../../hooks/useSubscribeListBartenders';
import AddBartenderDialog from './AddBartenderDialog';
import ManagePeopleList from './ManagePeopleList';
import ResendWelcomeDialog from './ResendWelcomeDialog';
import UpdateBartenderDialog from './UpdateBartenderDialog';

interface Props {
  eventId: string;
}

const ManageBartenders: FC<Props> = ({ eventId }) => {
  const { bartenders } = useSubscribeListBartenders(eventId);

  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [activeBartender, setActiveBartender] = useState<
    Bartender | undefined
  >();
  const [resendBartender, setResendBartender] = useState<
    Bartender | undefined
  >();
  const [isResendAlertOpen, setIsResendAlertOpen] = useState(false);
  const openResendAlert = () => setIsResendAlertOpen(true);
  const closeResendAlert = () => setIsResendAlertOpen(false);

  const openAddDialog = () => setIsAddDialogOpen(true);
  const closeAddDialog = () => setIsAddDialogOpen(false);

  const openEditDialog = (bartender: Bartender) =>
    setActiveBartender(bartender);

  const closeEditDialog = () => setActiveBartender(undefined);

  const openResendDialog = (bartender: Bartender) =>
    setResendBartender(bartender);
  const closeResendDialog = () => setResendBartender(undefined);

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' mb={2}>
        <Typography variant='h5'>Guest Bartenders</Typography>
        <Button
          onClick={openAddDialog}
          variant='contained'
          color='secondary'
          endIcon={<PersonAddIcon />}
        >
          Add
        </Button>
      </Box>

      <Alert severity='info' action={null} sx={{ mb: 2 }}>
        Guest Bartenders spread the word about the event to their friends,
        families, co-workers and on social media
      </Alert>
      <ManagePeopleList
        people={bartenders}
        onClickPerson={openEditDialog}
        onResendPerson={openResendDialog}
        emptyMessage='There are no guest bartenders yet'
        personLabel='bartender'
      />

      {isAddDialogOpen && (
        <AddBartenderDialog
          eventId={eventId}
          isOpen={true}
          onClose={closeAddDialog}
        />
      )}

      {!!activeBartender && (
        <UpdateBartenderDialog
          bartender={activeBartender}
          isOpen={true}
          onClose={closeEditDialog}
        />
      )}

      {!!resendBartender && (
        <ResendWelcomeDialog
          person={resendBartender}
          onClose={closeResendDialog}
          onAlert={openResendAlert}
        />
      )}

      <SnackAlert isOpen={isResendAlertOpen} onClose={closeResendAlert}>
        Invitation sent!
      </SnackAlert>
    </Box>
  );
};

export default ManageBartenders;
