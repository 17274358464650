import type { Event } from '@gb/common';
import { getDownloadURL, uploadBytes } from 'firebase/storage';

import event from '../event';
import { getEventImageRef } from './refs';

export default async function upload(
  {
    eventId,
    type,
  }: {
    eventId: string;
    type: 'cover' | 'logo';
  },
  data: Blob,
): Promise<void> {
  const contentType = data.type;
  if (!allowedContentTypes.includes(contentType)) {
    throw new Error('invalid contentType');
  }

  const ref = getEventImageRef({ eventId, type });

  await uploadBytes(ref, data, {
    contentType,
    cacheControl: DEFAULT_CACHE_CONTROL,
  });

  const downloadUrl = await getDownloadURL(ref);

  const eventData: Partial<Event> = { [type]: downloadUrl };
  await event.update(eventId, eventData);
}

const allowedContentTypes = ['image/png', 'image/jpeg', 'image/jpg'];

const DEFAULT_CACHE_CONTROL = `public,max-age=${60 * 60 * 24 * 2}`;
