import type { FC, ReactNode } from 'react';

import type { BoxProps } from '@mui/material';
import { Box, Container, Grid, Typography } from '@mui/material';

interface Props {
  title: string;
  left?: ReactNode;
  right?: ReactNode;
  boxProps?: BoxProps;
}

const PageTitle: FC<Props> = ({ title, left, right, boxProps }) => {
  return (
    <Box
      {...boxProps}
      sx={{ py: 2, bgcolor: 'grey.50', mb: 3, ...boxProps?.sx }}
    >
      <Container maxWidth='lg'>
        <Grid container>
          <Grid
            item
            xs={12}
            md={3}
            display='flex'
            justifyContent={{ xs: 'center', md: 'flex-start' }}
          >
            {left}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant='h5'
              textTransform='uppercase'
              fontWeight={500}
              textAlign='center'
            >
              {title}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            display='flex'
            justifyContent={{ xs: 'center', md: 'flex-end' }}
          >
            {right}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default PageTitle;
