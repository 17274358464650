import type { FC } from 'react';

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FacebookIcon from '@mui/icons-material/Facebook';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import ListIcon from '@mui/icons-material/List';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PaymentsIcon from '@mui/icons-material/Payments';
import {
  Box,
  ButtonBase,
  Divider,
  Drawer,
  List,
  ListSubheader,
  Toolbar,
} from '@mui/material';
import { Link } from 'react-router-dom';

import DrawerItem from '../../base/DrawerItem';
import { useAuthContext } from '../../context/AuthContext';
import { ReactComponent as LogoSvg } from '../../images/logo-dark.svg';
import getPath, {
  ANALYTICS_URL,
  FACEBOOK_URL,
  INSTAGRAM_URL,
} from '../../utils/getPath';

const MainDrawer: FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose,
}) => {
  const { isAdmin, isSignedIn, onSignOut } = useAuthContext();

  return (
    <Drawer anchor='left' open={isOpen} onClose={onClose}>
      <Box
        role='presentation'
        sx={{
          width: 320,
          maxWidth: '90vw',
          bgcolor: 'background.paper',
        }}
        onClick={onClose}
        onKeyDown={onClose}
      >
        <Toolbar>
          <ButtonBase component={Link} to={getPath.home()}>
            <LogoSvg width='100%' />
          </ButtonBase>
        </Toolbar>
        <Divider />
        <nav aria-label='main menu'>
          <List>
            <DrawerItem
              Icon={HomeOutlinedIcon}
              label='Home'
              to={getPath.home()}
            />
            <ListSubheader>Account</ListSubheader>
            {isSignedIn ? (
              <>
                <DrawerItem
                  Icon={ListIcon}
                  label='My events'
                  to={getPath.manageList()}
                />
                <DrawerItem
                  Icon={LogoutIcon}
                  label='Sign out'
                  onClick={() => onSignOut()}
                />
              </>
            ) : (
              <DrawerItem
                Icon={LoginIcon}
                label='Sign in'
                to={getPath.signIn()}
              />
            )}
            {!!isAdmin && (
              <>
                <ListSubheader>Admin</ListSubheader>
                <DrawerItem
                  Icon={AdminPanelSettingsIcon}
                  label='Users'
                  to={getPath.admin('users')}
                />
                <DrawerItem
                  Icon={PaymentsIcon}
                  label='Accounts Payable'
                  to={getPath.admin('accounts-payable')}
                />
                <DrawerItem
                  Icon={AutoGraphIcon}
                  label='Analytics'
                  onClick={() => window.open(ANALYTICS_URL)}
                />
              </>
            )}
            <ListSubheader>Links</ListSubheader>
            <DrawerItem
              Icon={CalendarMonthIcon}
              label='Events Calendar'
              to={getPath.eventList()}
            />
            <DrawerItem
              Icon={InfoOutlinedIcon}
              label='About the Program'
              onClick={() => window.open(getPath.marketing('about'), '_self')}
            />
            <DrawerItem
              Icon={HelpOutlineIcon}
              label='Contact Us'
              onClick={() => window.open(getPath.marketing('contact'), '_self')}
            />

            <ListSubheader>Social</ListSubheader>
            <DrawerItem
              Icon={FacebookIcon}
              label='Facebook'
              onClick={() => window.open(FACEBOOK_URL)}
            >
              <OpenInNewIcon fontSize='small' />
            </DrawerItem>
            <DrawerItem
              Icon={InstagramIcon}
              label='Instagram'
              onClick={() => window.open(INSTAGRAM_URL)}
            >
              <OpenInNewIcon fontSize='small' />
            </DrawerItem>
          </List>
        </nav>
      </Box>
    </Drawer>
  );
};

export default MainDrawer;
