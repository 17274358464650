/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { generatePath } from 'react-router-dom';

export const FACEBOOK_URL = 'https://www.facebook.com/guestbartenderprogram';
export const INSTAGRAM_URL = 'https://www.instagram.com/guestbartenderprogram';

export const ANALYTICS_URL = 'https://analytics.google.com';

const MARKETING_BASE = 'https://about.guestbartender.org';

const getPath = {
  home: () => generatePath('/'),
  signIn: (params?: { redirectTo: string }) => {
    if (!params) {
      return generatePath('/signin');
    }

    return generatePath('/signin?redirectTo=:redirectTo', params);
  },
  signInComplete: ({ redirectTo }: { redirectTo: string | null }) => {
    if (!redirectTo) {
      return generatePath('/signin/complete');
    }

    return generatePath('/signin/complete?redirectTo=:redirectTo', {
      redirectTo,
    });
  },
  eventList: ({ isPast = false }: { isPast?: boolean } = {}) =>
    generatePath(!isPast ? '/events' : '/events/past'),
  event: (params: { eventId: string }) =>
    generatePath('/events/:eventId', params),
  eventCreate: (
    params: { page: 'details' | 'cause' | 'general' | '' } = { page: '' },
  ) => generatePath('/events/create/:page', params),
  bartender: (params: { bartenderId: string }) =>
    generatePath('/give/:bartenderId', params),
  donate: ({
    eventId,
    bartenderId,
  }: {
    eventId: string;
    bartenderId?: string | undefined;
  }) => {
    if (!bartenderId) {
      return generatePath('/donate/:eventId', { eventId });
    }

    return (
      generatePath('/donate/:eventId', { eventId }) +
      getQueryString({ bartenderId })
    );
  },
  donateList: ({
    eventId,
    bartenderId,
  }: {
    eventId: string;
    bartenderId?: string | undefined;
  }) => {
    if (!bartenderId) {
      return generatePath('/events/:eventId/donations', { eventId });
    }

    return (
      generatePath('/events/:eventId/donations', { eventId }) +
      getQueryString({ bartenderId })
    );
  },
  thankYou: ({ eventId, sessionId }: { eventId: string; sessionId: string }) =>
    generatePath('/donate/:eventId/thankYou/:sessionId', {
      eventId,
      sessionId,
    }),
  manageList: ({ isPast = false }: { isPast?: boolean } = {}) =>
    generatePath(!isPast ? '/manage' : '/manage/past'),
  manage: ({ eventId, page }: { eventId: string; page?: ManagePage }) =>
    generatePath('/manage/:eventId/:page', { eventId, page: page || '' }),
  admin: (page?: AdminPage) =>
    generatePath('/admin/:page', { page: page || '' }),
  marketing: (page: MarketingPage) =>
    `${MARKETING_BASE}/${marketingPages[page]}`,
};

export type ManagePage =
  | 'donations'
  | 'leaderboard'
  | 'financial-reports'
  | 'donor-reports'
  | 'share'
  | 'messages'
  | 'general'
  | 'remove'
  | 'people'
  | 'cause'
  | 'details'
  | 'images';

export type AdminPage = 'users' | 'accounts-payable';

const marketingPages = {
  about: '',
  contact: 'contact',
  support: 'help-articles',
  legal: 'legal',
};
type MarketingPage = keyof typeof marketingPages;

export default getPath;

function getQueryString(options: Record<string, string>): string {
  return '?' + new URLSearchParams(options).toString();
}
