import type { FC } from 'react';
import { useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Card,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';

interface PersonBase {
  name: string;
  email: string;
}

interface Props<Person extends PersonBase> {
  people: Person[];
  onClickPerson: (p: Person) => void;
  onResendPerson?: (p: Person) => void;
  emptyMessage: string;
  personLabel: string;
}

export default function ManagePeopleList<Person extends PersonBase>({
  people,
  onClickPerson,
  onResendPerson,
  emptyMessage,
  personLabel,
}: Props<Person>): JSX.Element | null {
  if (!people.length) {
    return (
      <Typography variant='subtitle1' color='primary' textAlign='center'>
        {emptyMessage}
      </Typography>
    );
  }

  return (
    <Card variant='outlined'>
      <List disablePadding>
        {people.map((person, i) => {
          const { name, email } = person;
          const key = `${email}-${i}`;

          const onClick = () => onClickPerson(person);
          const onResend = onResendPerson
            ? () => onResendPerson(person)
            : undefined;

          return (
            <ListItem
              key={key}
              disablePadding
              secondaryAction={
                <PersonMenu
                  id={key}
                  onEdit={onClick}
                  onResend={onResend}
                  personLabel={personLabel}
                />
              }
            >
              <ListItemButton onClick={onClick} dense>
                <ListItemText primary={name} secondary={email} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Card>
  );
}

const PersonMenu: FC<{
  id: string;
  onEdit: () => void;
  onResend: (() => void) | undefined;
  personLabel: string;
}> = ({ id, onEdit, onResend, personLabel }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id={`${id}-button`}
        aria-controls={open ? id : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        edge='end'
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          'aria-labelledby': `${id}-button`,
        }}
      >
        <MenuItem onClick={onEdit}>Edit {personLabel}</MenuItem>
        {!!onResend && <MenuItem onClick={onResend}>Resend invite</MenuItem>}
      </Menu>
    </div>
  );
};
