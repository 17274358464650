import type { FC } from 'react';
import { useState } from 'react';

import { Alert } from '@mui/material';

import { useManageContext } from '.';
import SnackAlert from '../../base/SnackAlert';
import ManageSection from '../../components/manage/ManageSection';
import DetailsForm from '../../forms/DetailsForm';
import useUpdateDetails from '../../hooks/useUpdateDetails';

const ManageDetails: FC = () => {
  const { event } = useManageContext();

  const { onSubmit, ...props } = useUpdateDetails(event);

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const closeSnackbar = () => setIsSnackbarOpen(false);

  function handleSubmit() {
    onSubmit(() => setIsSnackbarOpen(true));
  }

  return (
    <ManageSection title='Event Details'>
      <Alert severity='info' sx={{ mb: 2 }}>
        Information about the event date, location, etc will be displayed on
        fundraising pages.
      </Alert>
      <DetailsForm
        {...props}
        onSubmit={handleSubmit}
        submitLabel='Update event'
      />
      <SnackAlert isOpen={isSnackbarOpen} onClose={closeSnackbar}>
        Changes saved!
      </SnackAlert>
    </ManageSection>
  );
};

export default ManageDetails;
