import type { FC } from 'react';

import { Alert } from '@mui/material';

import { useManageContext } from '.';
import Leaderboard from '../../components/common/Leaderboard';
import ManageSection from '../../components/manage/ManageSection';

const ManageLeaderboard: FC = () => {
  const { event, totals } = useManageContext();

  return (
    <ManageSection title='Leaderboard'>
      <Alert severity='info' sx={{ mb: 2 }}>
        Keep track of guest bartenders who are the top fundraisers. Compete to
        see who can raise the most for your cause!
      </Alert>
      <Leaderboard event={event} totals={totals} />
    </ManageSection>
  );
};

export default ManageLeaderboard;
