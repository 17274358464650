import type { FC } from 'react';

import type { Event, Totals } from '@gb/common';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import MoneyField from '../base/MoneyField';
import useUpdateOfflineTotal from '../hooks/useUpdateOfflineTotal';
import FormBase from './FormBase';

interface Props {
  event: Event | undefined;
  totals: Totals | undefined;
  isOpen: boolean;
  onClose: () => void;
  openSnackbar: () => void;
}

const OfflineCentsDialog: FC<Props> = ({
  event,
  totals,
  isOpen,
  onClose,
  openSnackbar,
}) => {
  const { offlineCents, setOfflineCents, onSubmit, isLoading } =
    useUpdateOfflineTotal(event?.eventId, totals);

  const handleSubmit = () =>
    onSubmit(() => {
      openSnackbar();
      onClose();
    });

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Update offline fundraising total</DialogTitle>
      <DialogContent>
        <FormBase
          onSubmit={handleSubmit}
          submitLabel='Update'
          onCancel={onClose}
          isLoading={isLoading}
        >
          <MoneyField
            label='Offline fundraising total'
            value={offlineCents}
            onChange={setOfflineCents}
            fullWidth={false}
            required
            autoFocus
          />
        </FormBase>
      </DialogContent>
    </Dialog>
  );
};

export default OfflineCentsDialog;
