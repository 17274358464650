import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';

import { Box, Button, Container, Stack } from '@mui/material';
import { Link, useParams, useSearchParams } from 'react-router-dom';

import LoadingIndicator from '../base/LoadingIndicator';
import MetaTags from '../base/MetaTags';
import DonationList from '../components/common/DonationList';
import DonationListHeader from '../components/common/DonationListHeader';
import Footer from '../components/common/Footer';
import MainAppBar from '../components/common/MainAppBar';
import Sponsors from '../components/common/Sponsors';
import DonatePrompt from '../components/event/DonatePrompt';
import EventTitle from '../components/event/EventTitle';
import useGetEvent from '../hooks/useGetEvent';
import useListDonations from '../hooks/useListDonations';
import useSubscribeTotals from '../hooks/useSubscribeTotals';
import getPath from '../utils/getPath';

const ListDonations: FC = () => {
  const { eventId = '' } = useParams();
  const [searchParams] = useSearchParams();

  const initialBartenderId = useMemo(
    () => searchParams.get('bartenderId') || undefined,
    [searchParams],
  );

  const [bartenderId, setBartenderId] = useState(initialBartenderId);

  useEffect(() => {
    setBartenderId(initialBartenderId);
  }, [initialBartenderId]);

  const { event, isLoading: isEventLoading } = useGetEvent(eventId);
  const { totals } = useSubscribeTotals(eventId);
  const { donations, isLoading } = useListDonations({ eventId, bartenderId });

  const donateUrl = useMemo(
    () => getPath.donate({ eventId, bartenderId }),
    [eventId, bartenderId],
  );

  const backTo = useMemo(() => {
    if (!initialBartenderId) {
      return getPath.event({ eventId });
    }

    return getPath.bartender({ bartenderId: initialBartenderId });
  }, [initialBartenderId, eventId]);

  if (isEventLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Box>
      <MainAppBar backTo={backTo} />
      <MetaTags title={`Donations for ${event?.name || 'the fundraiser'}`} />
      <EventTitle name={event?.name || ''} tagline={event?.tagline} />
      <Container maxWidth='sm' sx={{ mb: 6 }}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          alignItems='center'
          justifyContent='space-between'
          spacing={2}
          sx={{ mb: 3 }}
        >
          <Button variant='outlined' fullWidth component={Link} to={backTo}>
            Learn more
          </Button>
          <Button
            variant='contained'
            fullWidth
            color='secondary'
            component={Link}
            to={donateUrl}
          >
            Donate
          </Button>
        </Stack>
        <DonationListHeader
          totals={totals}
          bartenders={event?.bartenders}
          bartenderId={bartenderId}
          setBartenderId={setBartenderId}
        />
        <DonationList
          donations={donations}
          isLoading={isLoading}
          ListEmptyComponent={
            !isLoading ? (
              <DonatePrompt
                donateUrl={donateUrl}
                text={`Be the first to fund this ${
                  bartenderId ? 'bartender' : 'cause'
                }!`}
              />
            ) : null
          }
        />
      </Container>
      <Sponsors />
      <Footer />
    </Box>
  );
};

export default ListDonations;
