import { Timestamp } from 'firebase/firestore';

const MILLIS_PER_DAY = 24 * 60 * 60 * 1000;

export function getDaysLeft(
  fromTimestamp: Timestamp,
  nowTimestamp = Timestamp.now(),
): number {
  const from = fromTimestamp.toMillis();
  const now = nowTimestamp.toMillis();
  if (now >= from) {
    return 0;
  }

  const diffMillis = from - now;

  return Math.ceil(diffMillis / MILLIS_PER_DAY);
}
