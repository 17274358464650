import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useState } from 'react';

import type { Event } from '@gb/common';

import api from '../api';
import getPath from '../utils/getPath';

const SESSION_ID_TEMPLATE_VARIABLE = '{CHECKOUT_SESSION_ID}';

const DEFAULT_AMOUNT_CENTS = 5000;

interface Hook {
  onCheckout: (onSuccess?: (checkoutUrl: string) => void) => void;
  bartenderId: string;
  setBartenderId: Dispatch<SetStateAction<string>>;
  donorName: string;
  setDonorName: Dispatch<SetStateAction<string>>;
  isAnonymous: boolean;
  setIsAnonymous: Dispatch<SetStateAction<boolean>>;
  amountCents: number;
  setAmountCents: (value: number) => void;
  hasPlatformCost: boolean;
  setHasPlatformCost: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
}

export default function useCreateCheckout(event: Event | undefined): Hook {
  const [bartenderId, setBartenderId] = useState('');
  const [donorName, setDonorName] = useState('');
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [amountCents, setAmountCents] = useState(DEFAULT_AMOUNT_CENTS);
  const [hasPlatformCost, setHasPlatformCost] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const onCheckout = useCallback(
    (onSuccess?: (checkoutUrl: string) => void) => {
      if (isLoading || !event) {
        return;
      }
      setIsLoading(true);
      const { eventId, name: eventName, platformRate, bartenders } = event;
      const platformRateCents = getPlatformRateCents({
        amountCents,
        platformRate,
        hasPlatformCost,
      });
      const bartenderName = getBartenderName(bartenderId, bartenders);

      api.checkout
        .create({
          eventId,
          eventName,
          bartenderId,
          bartenderName,
          amountCents,
          platformRateCents,
          donorName: isAnonymous ? undefined : donorName,
          isAnonymous,
          successUrl: getSuccessUrl({ eventId }),
          cancelUrl: getCancelUrl(),
        })
        .then(({ checkoutUrl }) => {
          if (!checkoutUrl) {
            return;
          }
          onSuccess?.(checkoutUrl);
        })
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    },
    [
      isLoading,
      event,
      bartenderId,
      donorName,
      isAnonymous,
      amountCents,
      hasPlatformCost,
    ],
  );

  return {
    onCheckout,
    bartenderId,
    setBartenderId,
    donorName,
    setDonorName,
    isAnonymous,
    setIsAnonymous,
    amountCents,
    setAmountCents,
    hasPlatformCost,
    setHasPlatformCost,
    isLoading,
  };
}

const getSuccessUrl = ({ eventId }: { eventId: string }) =>
  window.location.origin +
  getPath.thankYou({
    eventId,
    sessionId: SESSION_ID_TEMPLATE_VARIABLE,
  });

const getCancelUrl = () => window.location.href;

function getPlatformRateCents({
  amountCents,
  platformRate,
  hasPlatformCost,
}: {
  amountCents: number;
  platformRate: number;
  hasPlatformCost: boolean;
}) {
  if (!hasPlatformCost) {
    return 0;
  }

  return Math.round(amountCents * platformRate);
}

function getBartenderName(
  bartenderId: string,
  bartenders: Event['bartenders'],
): string | undefined {
  return bartenders?.[bartenderId];
}
