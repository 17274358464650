import { useEffect, useMemo, useState } from 'react';

import type { Event } from '@gb/common';

import api from '../api';
import { useAuthContext } from '../context/AuthContext';

interface Hook {
  event: Event | undefined;
  canManage: boolean;
  isLoading: boolean;
  hasError: boolean;
}

export default function useSubscribeEvent(eventId: string | undefined): Hook {
  const [event, setEvent] = useState<Event | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (!eventId) {
      setEvent(undefined);
      setHasError(true);
      setIsLoading(false);

      return;
    }
    setIsLoading(true);

    return api.event.subscribe(
      eventId,
      (data) => {
        setEvent(data);
        setIsLoading(false);
        setHasError(!data);
      },
      (err) => {
        console.error(err);
        setIsLoading(false);
        setHasError(true);
      },
    );
  }, [eventId]);

  const { userId, isAdmin } = useAuthContext();

  const isOrganizer = useMemo(() => {
    if (!event || !userId) {
      return false;
    }

    return !!event.organizerIds?.includes(userId);
  }, [event, userId]);

  const canManage = useMemo(
    () => isOrganizer || !!isAdmin,
    [isOrganizer, isAdmin],
  );

  return {
    event,
    canManage,
    isLoading,
    hasError,
  };
}
