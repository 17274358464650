import type { FC, ReactNode } from 'react';

import type { Totals } from '@gb/common';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CollectionsIcon from '@mui/icons-material/Collections';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import FeedIcon from '@mui/icons-material/Feed';
import MailIcon from '@mui/icons-material/Mail';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PeopleIcon from '@mui/icons-material/People';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SettingsIcon from '@mui/icons-material/Settings';
import ShareIcon from '@mui/icons-material/Share';
import SupportIcon from '@mui/icons-material/Support';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListSubheader,
} from '@mui/material';
import { Link } from 'react-router-dom';

import DonateTotal from '../../base/DonateTotal';
import DrawerItem from '../../base/DrawerItem';
import useIsPrint from '../../hooks/useIsPrint';
import { ReactComponent as LogoSvg } from '../../images/logo.svg';
import getPath from '../../utils/getPath';

interface Props {
  eventId: string;
  isOpen: boolean;
  onClose: () => void;
  isPermanent: boolean;
  totals: Totals | undefined;
  goalCents: number | undefined;
  isAdmin: boolean | undefined;
  hasCover: boolean;
  children: ReactNode;
}

const DRAWER_WIDTH = 256;

const ManageDrawer: FC<Props> = ({
  eventId,
  isOpen,
  onClose,
  isPermanent,
  goalCents = 0,
  totals,
  isAdmin,
  hasCover,
  children,
}) => {
  const isPrint = useIsPrint();

  if (isPrint) {
    return (
      <Box minHeight='100vh' display='flex' flexDirection='column' flexGrow={1}>
        {children}
      </Box>
    );
  }

  return (
    <Box display='flex'>
      <Box displayPrint='none'>
        <Drawer
          variant={isPermanent ? 'permanent' : 'temporary'}
          anchor='left'
          open={isPermanent || isOpen}
          onClose={onClose}
          PaperProps={{ sx: { bgcolor: 'primary.main', color: 'grey.200' } }}
        >
          <Box
            role='presentation'
            sx={{
              width: DRAWER_WIDTH,
              maxWidth: '90vw',
              minHeigth: '100vh',
            }}
            onClick={onClose}
            onKeyDown={onClose}
          >
            <nav aria-label='Manage event menu'>
              <List>
                <Box>
                  <Button component={Link} to={getPath.home()}>
                    <Box sx={{ mb: 1, borderRadius: 2 }}>
                      <LogoSvg width='100%' />
                    </Box>
                  </Button>
                </Box>
                <Divider sx={{ bgcolor: 'primary.light' }} />
                <DrawerItem
                  label='Manage Events'
                  Icon={ArrowBackIcon}
                  to={getPath.manageList()}
                  listItemProps={{
                    disablePadding: false,
                    disableGutters: true,
                  }}
                />
                <Divider sx={{ bgcolor: 'primary.light' }} />
                <Subheader label='Fundraising' />
                <DrawerItem
                  label='Donations'
                  Icon={VolunteerActivismIcon}
                  to={getPath.manage({ eventId, page: 'donations' })}
                />
                <DrawerItem
                  label='Leaderboard'
                  Icon={EmojiEventsIcon}
                  to={getPath.manage({ eventId, page: 'leaderboard' })}
                />
                <DrawerItem
                  label='Financial Reports'
                  Icon={AccountBalanceIcon}
                  to={getPath.manage({ eventId, page: 'financial-reports' })}
                />
                <DrawerItem
                  label='Donor Reports'
                  Icon={ReceiptLongIcon}
                  to={getPath.manage({ eventId, page: 'donor-reports' })}
                />
                <Subheader label='Promote' />
                <DrawerItem
                  label='Share Event'
                  Icon={ShareIcon}
                  to={getPath.manage({ eventId, page: 'share' })}
                />
                <DrawerItem
                  label='Message Bartenders'
                  Icon={MailIcon}
                  to={getPath.manage({ eventId, page: 'messages' })}
                />
                <DrawerItem
                  label='Event Page'
                  Icon={VisibilityIcon}
                  onClick={() => window.open(getPath.event({ eventId }))}
                >
                  <OpenInNewIcon fontSize='small' />
                </DrawerItem>
                <Subheader label='Get Help' />
                <DrawerItem
                  Icon={SupportIcon}
                  label='Support'
                  onClick={() =>
                    window.open(getPath.marketing('support'), '_self')
                  }
                />
                <Subheader label='Settings' />
                {!!isAdmin && (
                  <DrawerItem
                    label='General'
                    Icon={SettingsIcon}
                    to={getPath.manage({ eventId, page: 'general' })}
                  />
                )}
                <DrawerItem
                  label='People'
                  Icon={PeopleIcon}
                  to={getPath.manage({ eventId, page: 'people' })}
                />
                <DrawerItem
                  label='About the Cause'
                  Icon={FeedIcon}
                  to={getPath.manage({ eventId, page: 'cause' })}
                />
                <DrawerItem
                  label='Event Details'
                  Icon={EventAvailableIcon}
                  to={getPath.manage({ eventId, page: 'details' })}
                />
                <DrawerItem
                  label='Images'
                  Icon={CollectionsIcon}
                  to={getPath.manage({ eventId, page: 'images' })}
                >
                  {!hasCover ? (
                    <WarningAmberIcon fontSize='small' color='warning' />
                  ) : null}
                </DrawerItem>
              </List>
            </nav>
          </Box>
          <Box mt='auto'>
            <Divider sx={{ bgcolor: 'primary.light' }} />
            <Box px={1} py={2}>
              <DonateTotal
                goalCents={goalCents}
                totals={totals}
                barHeight={6}
                barMargin={1}
              />
            </Box>
          </Box>
        </Drawer>
      </Box>
      <Box
        width={isPermanent ? `calc(100% - ${DRAWER_WIDTH}px)` : undefined}
        ml={isPermanent ? `${DRAWER_WIDTH}px` : undefined}
        minHeight='100vh'
        display='flex'
        flexDirection='column'
        flexGrow={1}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ManageDrawer;

const Subheader: FC<{ label: string }> = ({ label }) => (
  <ListSubheader sx={{ color: 'grey.400', bgcolor: 'primary.main' }}>
    {label}
  </ListSubheader>
);
