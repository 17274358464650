import { useCallback, useState } from 'react';

import api from '../api';

interface Hook {
  onSubmit: (file: File | undefined, onSuccess?: () => void) => void;
  onRemove: (onSuccess?: () => void) => void;
  isLoading: boolean;
}

export default function useUpdateImage(
  eventId: string | undefined,
  type: 'cover' | 'logo',
): Hook {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(
    (file: File | undefined, onSuccess?: () => void) => {
      if (isLoading || !eventId || !file) {
        return;
      }
      setIsLoading(true);
      api.storage
        .upload({ eventId, type }, file)
        .then(onSuccess)
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    },
    [isLoading, eventId, type],
  );

  const onRemove = useCallback(
    (onSuccess?: () => void) => {
      if (isLoading || !eventId) {
        return;
      }
      setIsLoading(true);
      api.storage
        .remove({ eventId, type })
        .then(onSuccess)
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    },
    [isLoading, eventId, type],
  );

  return { onSubmit, onRemove, isLoading };
}
