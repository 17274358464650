import type { FC } from 'react';
import { useMemo } from 'react';

import type { Event } from '@gb/common';
import PrintIcon from '@mui/icons-material/Print';
import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';

interface Props {
  event: Event | undefined;
}

const FinancialHeader: FC<Props> = ({ event }) => {
  const { name, startsAt } = event || {};

  const formattedDate = useMemo(
    () => startsAt?.toDate().toLocaleString(undefined, { dateStyle: 'long' }),
    [startsAt],
  );

  return (
    <Card variant='outlined' sx={{ mb: 5 }}>
      <CardContent>
        <Stack
          direction='row'
          spacing={2}
          alignItems='center'
          justifyContent='space-between'
        >
          <Box>
            <Typography variant='body1' color='primary' fontWeight='600'>
              {name}
            </Typography>
            <Typography>{formattedDate}</Typography>
          </Box>
          <Box displayPrint='none'>
            <Button
              variant='outlined'
              startIcon={<PrintIcon fontSize='small' />}
              onClick={() => window.print()}
              size='small'
            >
              Print
            </Button>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default FinancialHeader;
