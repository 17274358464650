import { useCallback, useState } from 'react';

import api from '../../api';

interface Hook {
  removeAdmin: (onSuccess?: () => void) => void;
  isLoading: boolean;
}

export default function useRemoveAdmin(uid: string | undefined): Hook {
  const [isLoading, setIsLoading] = useState(false);

  const removeAdmin = useCallback(
    (onSuccess?: () => void) => {
      if (isLoading || !uid) {
        return;
      }
      setIsLoading(true);
      api.admin
        .remove(uid)
        .then(onSuccess)
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    },
    [isLoading, uid],
  );

  return { removeAdmin, isLoading };
}
