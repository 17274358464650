export function isIdValid(input: string): boolean {
  if (!input) {
    return false;
  }

  if (doesStartWithNumber(input)) {
    return false;
  }

  if (hasRepeated(input)) {
    return false;
  }

  if (hasInvalidChar(input)) {
    return false;
  }

  return true;
}

export function doesStartWithNumber(input: string): boolean {
  return Number.isInteger(Number(input[0]));
}

export function hasRepeated(input: string): boolean {
  for (let i = 0; i < input.length; i++) {
    if (i === 0) {
      continue;
    }
    if (input[i] === input[i - 1]) {
      return true;
    }
  }

  return false;
}

export function hasInvalidChar(
  input: string,
  /** potentially confusing characters to avoid */
  invalid = ['0', '1', 'l', 'a', 'e', 'i', 'o', 'u'],
): boolean {
  for (let i = 0; i < input.length; i++) {
    const letter = input[i]?.toLowerCase() || '';
    if (invalid.includes(letter)) {
      return true;
    }
  }

  return false;
}
