import type { FC } from 'react';

import type { AdminFinanceItem } from '@gb/common';
import { Money } from '@gb/common';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Stack,
  Typography,
} from '@mui/material';

import { AdminSection } from '.';
import LoadingIndicator from '../../base/LoadingIndicator';
import FinancialListItem from '../../components/manage/FinancialListItem';
import useAdminFinanceReport from '../../hooks/admin/useAdminFinanceReport';
import { getPlatformFeeCents } from '../../utils/getPlatformFeeCents';
const EVENT_LIMIT = 100;

const AdminAccountsPayable: FC = () => {
  const { isLoading, items, totals, updatedAt, onRefresh } =
    useAdminFinanceReport(EVENT_LIMIT);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <AdminSection title='Accounts Payable'>
      <Alert severity='info' sx={{ mb: 3 }}>
        View totals for active events that end between 1 week in the past and 4
        weeks in the future.
      </Alert>

      <Stack
        direction='row-reverse'
        justifyContent='space-between'
        alignItems='center'
        spacing={1}
        mb={3}
      >
        <Button
          size='small'
          onClick={onRefresh}
          endIcon={<RefreshIcon fontSize='inherit' />}
        >
          Refresh
        </Button>
        <Typography variant='caption' component='p'>
          Updated at: {updatedAt.toLocaleString()}
        </Typography>
      </Stack>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <FinancialListItem
            label='Number of donations'
            value={totals.donorCount.toString()}
          />
          <FinancialListItem label='Donations' cents={totals.totalCents} />
          <FinancialListItem
            label='Net platform cost'
            cents={totals.netPlatformCostCents}
            isNegative
          />
          <FinancialListItem
            label='Total accounts payable'
            cents={totals.grandTotalCents}
            isBold
          />
        </CardContent>
      </Card>
      <Alert severity='warning' sx={{ mb: 3 }}>
        Note that a maximum of {EVENT_LIMIT} events are included in the
        calculation.
      </Alert>
      <List
        disablePadding
        subheader={
          <ListSubheader>
            {items.length ? 'Active events' : 'There are no active events.'}
          </ListSubheader>
        }
        sx={{ mb: 3 }}
      >
        {items.map((item) => (
          <EventItem key={item.eventId} item={item} />
        ))}
      </List>
    </AdminSection>
  );
};

export default AdminAccountsPayable;

function EventItem({ item }: { item: AdminFinanceItem }) {
  const { eventName, totalCents, platformRate, platformCents } = item;
  const platformFeeCents = getPlatformFeeCents({ totalCents, platformRate });
  const accountsPayableCents = totalCents - platformFeeCents + platformCents;

  const formattedTotal = '$' + new Money(accountsPayableCents).toString();

  return (
    <ListItem divider>
      <ListItemText
        primary={eventName}
        secondary={`Accounts payable: ${formattedTotal}`}
      />
    </ListItem>
  );
}
