import type { Organizer } from '@gb/common';
import { getRefPath } from '@gb/common';
import type {
  CollectionReference,
  DocumentReference,
} from 'firebase/firestore';
import { collection, doc } from 'firebase/firestore';

import { db } from '../firebase';

export function getOrganizerCollectionRef(): CollectionReference<Organizer> {
  const ref = collection(db, getRefPath.organizersCollection());

  return ref as CollectionReference<Organizer>;
}

export function getOrganizerRef(
  organizerId: string,
): DocumentReference<Organizer> {
  return doc(
    db,
    getRefPath.organizer({ organizerId }),
  ) as DocumentReference<Organizer>;
}
