import type { FC } from 'react';

import {
  Box,
  CardActionArea,
  Container,
  Grid,
  Typography,
} from '@mui/material';

import ResponsiveImage from '../../base/ResponsiveImage';
import bbc from '../../images/sponsors/bbc.png';
import maineroot from '../../images/sponsors/maineroot.jpg';
import miller from '../../images/sponsors/miller.jpg';
import union from '../../images/sponsors/union.jpg';

const sponsors = [
  { src: miller, url: 'https://millerlite.com' },
  { src: maineroot, url: 'https://maineroot.com' },
  { src: union, url: 'https://unionpark.com' },
  { src: bbc, url: 'https://bbctavernandgrill.com' },
];

const Sponsors: FC = () => {
  return (
    <Box displayPrint='none'>
      <Box sx={{ py: 3, bgcolor: 'grey.50' }}>
        <Container maxWidth='md'>
          <Typography variant='h6' textAlign='center' fontWeight='normal'>
            Please Support the Guest Bartender Program Sponsors
          </Typography>
        </Container>
      </Box>
      <Container maxWidth='xl' sx={{ py: 3 }}>
        <Grid
          container
          columnSpacing={5}
          rowSpacing={2}
          alignItems='center'
          justifyContent='center'
        >
          {sponsors.map(({ src, url }, i) => (
            <Grid key={`sponsor-${i}`} item xs={5} sm={2}>
              <CardActionArea
                href={url}
                target='_blank'
                sx={{
                  aspectRatio: '1',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <ResponsiveImage src={src} bgcolor='primary.light' />
              </CardActionArea>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Sponsors;
