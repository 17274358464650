import type { FC } from 'react';
import { useMemo } from 'react';

import { Money } from '@gb/common';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleTwoTone';
import PrintIcon from '@mui/icons-material/Print';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Skeleton,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';

import LoadingIndicator from '../base/LoadingIndicator';
import MetaTags from '../base/MetaTags';
import Footer from '../components/common/Footer';
import MainAppBar from '../components/common/MainAppBar';
import ShareIcons from '../components/common/ShareIcons';
import Sponsors from '../components/common/Sponsors';
import useCompleteDonation from '../hooks/useCompleteDonation';
import useGetEvent from '../hooks/useGetEvent';
import { getCdnSrc } from '../utils/getCdnSrc';
import getPath from '../utils/getPath';

const ThankYou: FC = () => {
  const { eventId = '', sessionId } = useParams();

  const { event, isLoading: isEventLoading } = useGetEvent(eventId);
  const { donation, isLoading: isDonationLoading } =
    useCompleteDonation(sessionId);

  const { amountCents, bartenderId, createdAt } = donation || {};

  const createdAtDate = useMemo(
    () => (createdAt ? createdAt.toDate() : new Date()),
    [createdAt],
  );

  const eventPath = useMemo(() => {
    if (!bartenderId) {
      return getPath.event({ eventId });
    }

    return getPath.bartender({ bartenderId });
  }, [eventId, bartenderId]);

  if (isEventLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Box>
      <MetaTags
        title='Thank you for your donation!'
        ogTitle={`Donate to ${event?.name || 'fundraiser'}`}
        description={event?.tagline}
        image={event?.cover ? getCdnSrc({ src: event.cover }) : undefined}
      />
      <MainAppBar backTo={eventPath} />
      <Container maxWidth='xs' sx={{ pt: 3 }}>
        <Typography variant='h5' textAlign='center' paragraph>
          Thank you for your donation!
        </Typography>
        <Box
          display='flex'
          justifyContent='center'
          mb={3}
          sx={{ fontSize: 120 }}
        >
          <CheckCircleOutlineIcon fontSize='inherit' color='secondary' />
        </Box>
        <Card variant='outlined' sx={{ mb: 3 }}>
          <CardContent>
            <Typography variant='h6' gutterBottom>
              Donation summary
            </Typography>
            <LineItem label='Fundraiser' value={event?.name} />
            <LineItem label='Cause' value={event?.causeName} />
            <LineItem label='Tax Id' value={event?.causeTaxId} />
            <LineItem
              label='Date'
              value={createdAtDate.toLocaleString(undefined, {
                dateStyle: 'long',
              })}
              isLoading={isDonationLoading}
            />
            <LineItem
              label='Amount'
              cents={amountCents}
              isLoading={isDonationLoading}
              isLast
            />
          </CardContent>
        </Card>
        <Box display='flex' alignItems='center' mb={3} displayPrint='none'>
          <Typography variant='body2'>
            A copy has been sent to your email.
          </Typography>
          <Button
            variant='outlined'
            startIcon={<PrintIcon />}
            sx={{ ml: 'auto' }}
            onClick={() => window.print()}
          >
            Print
          </Button>
        </Box>
        <Box mb={3} displayPrint='none'>
          <ShareIcons
            url={window.location.origin + eventPath}
            eventName={event?.name || ''}
          />
        </Box>
      </Container>
      <Sponsors />
      <Footer />
    </Box>
  );
};

export default ThankYou;

const LineItem: FC<{
  label: string;
  value?: string | undefined;
  cents?: number | undefined;
  isLast?: boolean;
  isLoading?: boolean;
}> = ({ label, value, cents, isLast = false, isLoading = false }) => {
  if (!isLoading && value === undefined && cents === undefined) {
    return null;
  }
  let formattedValue = value;
  if (cents !== undefined) {
    formattedValue = '$' + new Money(cents).toString();
  }

  return (
    <>
      <Typography variant='button' color='grey.600'>
        {label}
      </Typography>
      <Typography variant='body2' paragraph={!isLast}>
        {isLoading ? <Skeleton /> : formattedValue}
      </Typography>
    </>
  );
};
