import type { FC, ReactNode } from 'react';

import { HelmetProvider } from 'react-helmet-async';

import { AnalyticsContextProvider } from './AnalyticsContext';
import { AuthContextProvider } from './AuthContext';

interface Props {
  children: ReactNode;
}

const ContextProviders: FC<Props> = ({ children }) => {
  return (
    <AuthContextProvider>
      <AnalyticsContextProvider>
        <HelmetProvider>{children}</HelmetProvider>
      </AnalyticsContextProvider>
    </AuthContextProvider>
  );
};

export default ContextProviders;
