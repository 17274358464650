import type { FC } from 'react';

import { Box } from '@mui/material';

import { useManageContext } from '.';
import ManageBartenders from '../../components/manage/ManageBartenders';
import ManageOrganizers from '../../components/manage/ManageOrganizers';
import ManageSection from '../../components/manage/ManageSection';

const ManagePeople: FC = () => {
  const { event } = useManageContext();
  if (!event) {
    return null;
  }

  const { eventId } = event;

  return (
    <ManageSection title='People'>
      <Box mb={8}>
        <ManageBartenders eventId={eventId} />
      </Box>
      <ManageOrganizers eventId={eventId} />
    </ManageSection>
  );
};

export default ManagePeople;
