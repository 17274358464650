import type { Event } from '@gb/common';
import { getRefPath } from '@gb/common';
import type {
  CollectionReference,
  DocumentReference,
} from 'firebase/firestore';
import { collection, doc } from 'firebase/firestore';

import { db } from '../firebase';

export function getEventRef(eventId: string): DocumentReference<Event> {
  const ref = doc(db, getRefPath.event({ eventId }));

  return ref as DocumentReference<Event>;
}

export function getEventCollectionRef(): CollectionReference<Event> {
  const ref = collection(db, getRefPath.eventsCollection());

  return ref as CollectionReference<Event>;
}
