import type { FC } from 'react';

import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

interface Props {
  donateUrl: string;
  text: string;
}

const DonatePrompt: FC<Props> = ({ donateUrl, text }) => {
  return (
    <Box py={3} display='flex' flexDirection='column' alignItems='center'>
      <Typography variant='h3'>
        <VolunteerActivismIcon fontSize='inherit' />
      </Typography>
      <Typography variant='h6' paragraph sx={{ py: 2 }} textAlign='center'>
        {text}
      </Typography>
      <Button
        component={Link}
        to={donateUrl}
        variant='contained'
        size='large'
        color='secondary'
      >
        Donate
      </Button>
    </Box>
  );
};

export default DonatePrompt;
