import type { FC } from 'react';
import { useState } from 'react';

import { Alert } from '@mui/material';

import { useManageContext } from '.';
import SnackAlert from '../../base/SnackAlert';
import TextField from '../../base/TextField';
import ManageSection from '../../components/manage/ManageSection';
import FormBase from '../../forms/FormBase';
import useMessageBartenders from '../../hooks/manage/useMessageBartenders';

const ManageMessages: FC = () => {
  const { event } = useManageContext();

  const {
    subject,
    setSubject,
    message,
    setMessage,
    onSubmit,
    onReset,
    isLoading,
    hasError,
  } = useMessageBartenders(event?.eventId);

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const closeSnackbar = () => setIsSnackbarOpen(false);

  function handleSubmit() {
    onSubmit(() => setIsSnackbarOpen(true));
  }

  return (
    <ManageSection title='Message Bartenders'>
      <Alert severity='info' sx={{ mb: 2 }}>
        Email bartenders and organizers.
      </Alert>
      <FormBase
        onSubmit={handleSubmit}
        submitLabel='Send message'
        submitLoadingLabel='Sending...'
        onCancel={onReset}
        cancelLabel='Clear message'
        isLoading={isLoading}
        hasError={hasError}
      >
        <TextField
          label='Subject'
          value={subject}
          onChangeText={setSubject}
          required
        />
        <TextField
          label='Message'
          value={message}
          onChangeText={setMessage}
          multiline
          minRows={5}
          maxRows={10}
          required
        />
      </FormBase>
      <SnackAlert isOpen={isSnackbarOpen} onClose={closeSnackbar}>
        Your message has been sent!
      </SnackAlert>
    </ManageSection>
  );
};

export default ManageMessages;
