import type { RefObject } from 'react';
import { useEffect, useState } from 'react';

const DEFAULT_THRESHOLD = 0.5;

/**
 * Observe if an element is visible
 * @param ref element to observe
 * @param threshold observer threshold between 0 and 1 (inclusive)
 * @returns true if the element is visible
 */
export default function useIsVisible(
  ref: RefObject<HTMLElement>,
  threshold = DEFAULT_THRESHOLD,
): boolean {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(!!entry?.isIntersecting);
      },
      { threshold },
    );

    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [ref, threshold]);

  return isVisible;
}
