import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';

import type { Event } from '@gb/common';
import { Timestamp } from 'firebase/firestore';

import api from '../api';

interface Hook {
  startsAt: Timestamp;
  setStartsAt: Dispatch<SetStateAction<Timestamp>>;
  endsAt: Timestamp;
  setEndsAt: Dispatch<SetStateAction<Timestamp>>;
  venue: string;
  setVenue: Dispatch<SetStateAction<string>>;
  addressStreet: string;
  setAddressStreet: Dispatch<SetStateAction<string>>;
  addressCity: string;
  setAddressCity: Dispatch<SetStateAction<string>>;
  addressState: string;
  setAddressState: Dispatch<SetStateAction<string>>;
  addressZip: string;
  setAddressZip: Dispatch<SetStateAction<string>>;
  isVirtual: boolean;
  setIsVirtual: Dispatch<SetStateAction<boolean>>;
  facebookUrl: string;
  setFacebookUrl: Dispatch<SetStateAction<string>>;
  onSubmit: (onSuccess?: () => void) => void;
  isLoading: boolean;
}

export default function useUpdateDetails(event: Event | undefined): Hook {
  const [startsAt, setStartsAt] = useState(event?.startsAt || Timestamp.now());
  const [endsAt, setEndsAt] = useState(event?.endsAt || Timestamp.now());
  const [venue, setVenue] = useState(event?.venue || '');
  const [addressStreet, setAddressStreet] = useState(
    event?.addressStreet || '',
  );
  const [addressCity, setAddressCity] = useState(event?.addressCity || '');
  const [addressState, setAddressState] = useState(event?.addressState || '');
  const [addressZip, setAddressZip] = useState(event?.addressZip || '');
  const [isVirtual, setIsVirtual] = useState(!!event?.isVirtual);
  const [facebookUrl, setFacebookUrl] = useState(event?.facebookUrl || '');
  const [isLoading, setIsLoading] = useState(false);

  const onReset = useCallback(() => {
    setStartsAt(event?.startsAt || Timestamp.now());
    setEndsAt(event?.endsAt || Timestamp.now());
    setVenue(event?.venue || '');
    setAddressStreet(event?.addressStreet || '');
    setAddressCity(event?.addressCity || '');
    setAddressState(event?.addressState || '');
    setAddressZip(event?.addressZip || '');
    setIsVirtual(!!event?.isVirtual);
    setFacebookUrl(event?.facebookUrl || '');
  }, [event]);

  useEffect(() => {
    onReset();
  }, [onReset]);

  const onSubmit = useCallback(
    (onSuccess?: () => void) => {
      if (isLoading || !event) {
        return;
      }
      setIsLoading(true);
      api.event
        .update(event.eventId, {
          startsAt,
          endsAt,
          venue,
          addressStreet,
          addressCity,
          addressState,
          addressZip,
          isVirtual,
          facebookUrl,
        })
        .then(onSuccess)
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    },
    [
      isLoading,
      event,
      startsAt,
      endsAt,
      venue,
      addressStreet,
      addressCity,
      addressState,
      addressZip,
      isVirtual,
      facebookUrl,
    ],
  );

  return {
    startsAt,
    setStartsAt,
    endsAt,
    setEndsAt,
    venue,
    setVenue,
    addressStreet,
    setAddressStreet,
    addressCity,
    setAddressCity,
    addressState,
    setAddressState,
    addressZip,
    setAddressZip,
    isVirtual,
    setIsVirtual,
    facebookUrl,
    setFacebookUrl,
    onSubmit,
    isLoading,
  };
}
