import { useMemo } from 'react';

import type { Event } from '@gb/common';

import { useAuthContext } from '../context/AuthContext';

export default function useCanManageEvent(event: Event | undefined): boolean {
  const { userId, isAdmin } = useAuthContext();

  const isOrganizer = useMemo(() => {
    if (!event || !userId) {
      return false;
    }

    return !!event.organizerIds?.includes(userId);
  }, [event, userId]);

  const canManage = useMemo(
    () => isOrganizer || !!isAdmin,
    [isOrganizer, isAdmin],
  );

  return canManage;
}
