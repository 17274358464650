import type { FC } from 'react';

import type { LinearProgressProps } from '@mui/material';
import { Box, LinearProgress } from '@mui/material';

type Props = LinearProgressProps & {
  isLoading?: boolean | undefined;
  height?: number;
};

const LoadingIndicator: FC<Props> = ({
  variant = 'indeterminate',
  color = 'secondary',
  sx,
  isLoading = true,
  height = 4,
  ...props
}) => {
  if (!isLoading) {
    return <Box width='100%' height={height} />;
  }

  return (
    <LinearProgress
      variant={variant}
      color={color}
      sx={{ height, ...sx }}
      {...props}
    />
  );
};

export default LoadingIndicator;
