import type { FC, ReactElement } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { Tooltip } from '@mui/material';

interface Props {
  message?: string;
  value: string;
  render: (onClick: () => void) => ReactElement;
}

const CopyElement: FC<Props> = ({ message = 'Copied!', value, render }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const onClick = useCallback(() => {
    navigator.clipboard.writeText(value).catch(console.error);
    setIsTooltipVisible(true);
  }, [value]);

  useEffect(() => {
    if (!isTooltipVisible) {
      return;
    }
    const timeout = setTimeout(() => setIsTooltipVisible(false), 2000);

    return () => clearTimeout(timeout);
  }, [isTooltipVisible]);

  return (
    <Tooltip
      title={message}
      disableTouchListener
      disableFocusListener
      disableHoverListener
      open={isTooltipVisible}
    >
      {render(onClick)}
    </Tooltip>
  );
};

export default CopyElement;
