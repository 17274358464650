import type { FC } from 'react';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import {
  Box,
  Container,
  IconButton,
  Link as MuiLink,
  Stack,
  Typography,
} from '@mui/material';
import type { To } from 'react-router-dom';
import { Link } from 'react-router-dom';

import CopyrightNotice from '../../base/CopyrightNotice';
import { useAuthContext } from '../../context/AuthContext';
import { ReactComponent as LogoSvg } from '../../images/logo-full.svg';
import getPath, { FACEBOOK_URL, INSTAGRAM_URL } from '../../utils/getPath';

const Footer: FC = () => {
  const { isSignedIn, onSignOut } = useAuthContext();

  return (
    <Box pt={4} pb={2} sx={{ bgcolor: 'primary.main' }} displayPrint='none'>
      <Container maxWidth='lg'>
        <Stack spacing={1} alignItems='center' mb={6}>
          <Box px={{ xs: 4, sm: 0 }} maxWidth={320}>
            <LogoSvg width='100%' />
          </Box>
          <Stack spacing={1} direction='row' justifyContent='center'>
            <IconButton href={FACEBOOK_URL} target='_blank' sx={{ pt: 0 }}>
              <FacebookIcon fontSize='large' sx={{ color: 'white' }} />
            </IconButton>
            <IconButton href={INSTAGRAM_URL} target='_blank' sx={{ pt: 0 }}>
              <InstagramIcon fontSize='large' sx={{ color: 'white' }} />
            </IconButton>
          </Stack>
        </Stack>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 2, sm: 10 }}
          justifyContent='center'
          mb={6}
        >
          <Box>
            <TextHeader label='Account' />
            {isSignedIn ? (
              <>
                <TextLink label='My events' to={getPath.manageList()} />
                <TextLink label='Sign out' onClick={() => onSignOut()} />
              </>
            ) : (
              <TextLink label='Sign in' to={getPath.signIn()} />
            )}
          </Box>
          <Box>
            <TextHeader label='Links' />
            <TextLink label='Events Calendar' to={getPath.eventList()} />
            <TextLink
              label='About the Program'
              href={getPath.marketing('about')}
            />
            <TextLink label='Contact Us' href={getPath.marketing('contact')} />
          </Box>
          <Box>
            <TextHeader label='Social' />
            <TextLink label='Facebook' href={FACEBOOK_URL} target='_blank' />
            <TextLink label='Instagram' href={INSTAGRAM_URL} target='_blank' />
          </Box>
        </Stack>
        <CopyrightNotice />
      </Container>
    </Box>
  );
};

export default Footer;

const TextHeader: FC<{ label: string }> = ({ label }) => {
  return (
    <Typography
      variant='button'
      color='white'
      component='h6'
      gutterBottom
      textAlign='center'
      fontWeight='bold'
    >
      {label}
    </Typography>
  );
};

const TextLink: FC<{
  label: string;
  onClick?: () => void;
  to?: To;
  href?: string;
  target?: string;
}> = ({ label, onClick, to, href, target }) => {
  let component: 'a' | 'button' | typeof Link = 'button';
  if (to) {
    component = Link;
  }
  if (href) {
    component = 'a';
  }

  return (
    <Typography variant='body1' color='white' gutterBottom textAlign='center'>
      <MuiLink
        variant='body1'
        underline='hover'
        color='inherit'
        to={to}
        href={href}
        target={target}
        onClick={onClick}
        component={component}
      >
        {label}
      </MuiLink>
    </Typography>
  );
};
