import { useCallback } from 'react';

import type { Donation } from '@gb/common';

import api from '../api';
import useLoadList from './useLoadList';

interface Params {
  eventId: string | undefined;
  bartenderId?: string | undefined;
}

interface Hook {
  donations: Donation[];
  loadMore: () => void;
  hasMore: boolean;
  isLoading: boolean;
}

export default function useListDonations({
  eventId,
  bartenderId,
}: Params): Hook {
  const getItems = useCallback(
    async (limit: number, startAfter?: Donation): Promise<Donation[]> => {
      if (!eventId) {
        return [];
      }

      return api.donation.list({ eventId, bartenderId }, limit, startAfter);
    },
    [eventId, bartenderId],
  );

  const {
    items: donations,
    loadMore,
    hasMore,
    isLoading,
  } = useLoadList(getItems);

  return {
    donations,
    loadMore,
    hasMore,
    isLoading,
  };
}
