import { useEffect, useState } from 'react';

import type { Totals } from '@gb/common';

import api from '../api';

interface Hook {
  isLoading: boolean;
  totals: Totals | undefined;
}

export default function useSubscribeTotals(eventId: string | undefined): Hook {
  const [isLoading, setIsLoading] = useState(true);
  const [totals, setTotals] = useState<Totals | undefined>(undefined);

  useEffect(() => {
    if (!eventId) {
      setIsLoading(false);

      return;
    }
    setIsLoading(true);
    api.donation.subscribeTotals(
      eventId,
      (value) => {
        setTotals(value);
        setIsLoading(false);
      },
      (err) => {
        console.error(err);
        setTotals(undefined);
        setIsLoading(false);
      },
    );
  }, [eventId]);

  return { isLoading, totals };
}
