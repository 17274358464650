import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';

import type { Organizer } from '@gb/common';

import api from '../api';

interface Hook {
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  updateOrganizer: (onSuccess?: () => void) => void;
  removeOrganizer: (onSuccess?: () => void) => void;
  isLoading: boolean;
}

export default function useUpdateOrganizer(organizer: Organizer): Hook {
  const [name, setName] = useState(organizer.name);
  const [email, setEmail] = useState(organizer.email);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setName(organizer.name);
    setEmail(organizer.email);
  }, [organizer]);

  const updateOrganizer = useCallback(
    (onSuccess?: () => void) => {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      api.organizer
        .update(organizer.organizerId, { name: name.trim(), email })
        .then(onSuccess)
        .catch(console.error)
        .finally(() => {
          setIsLoading(true);
        });
    },
    [isLoading, organizer, name, email],
  );

  const removeOrganizer = useCallback(
    (onSuccess?: () => void) => {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      api.organizer
        .remove(organizer.organizerId)
        .then(onSuccess)
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    },
    [isLoading, organizer],
  );

  return {
    name,
    setName,
    email,
    setEmail,
    updateOrganizer,
    removeOrganizer,
    isLoading,
  };
}
