import type { FC } from 'react';

import { Dialog } from '@mui/material';

import PersonForm from '../../forms/PersonForm';
import useCreateOrganizer from '../../hooks/useCreateOrganizer';
import useIsDesktop from '../../hooks/useIsDesktop';

interface Props {
  eventId: string;
  isOpen: boolean;
  onClose: () => void;
}

const AddOrganizerDialog: FC<Props> = ({ eventId, isOpen, onClose }) => {
  const isMobile = !useIsDesktop();
  const { name, setName, email, setEmail, createOrganizer, isLoading } =
    useCreateOrganizer(eventId);

  function onSubmit() {
    createOrganizer(onClose);
  }

  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen={isMobile}>
      <PersonForm
        title='Add event organizer'
        name={name}
        setName={setName}
        email={email}
        setEmail={setEmail}
        onSubmit={onSubmit}
        submitLabel='Add organizer'
        onCancel={onClose}
        isLoading={isLoading}
      />
    </Dialog>
  );
};

export default AddOrganizerDialog;
